<template>
  <v-card flat>
    <v-container grid-list-lg class="customform editor-container">
      <v-card-text>
        <v-layout wrap>
          <v-flex md3>
            <SelectBox title="Kampagnenart" :prop="editorData.value.campaignType" />
          </v-flex>
          <v-flex md2>
            <SelectBox
              :allowNull="true"
              title="Hauptkampagne (Segmentierung)"
              :prop="editorData.value.parentCampaign"
            />
          </v-flex>
          <v-flex md3>
            <InputBox
              :title="editorData.value.parentCampaign.value ? 'Segment Name' :'URL-Pfad'"
              :readonly="true"
              :prop="editorData.value.id"
            />
          </v-flex>
          <v-flex md3>
            <SelectBox title="Status" :prop="editorData.value.status" />
          </v-flex>
          <v-flex md1>
            <CheckBox title="Weiterleitung" :prop="editorData.value.forwarding" />
          </v-flex>
          <v-flex md4>
            <InputBox
              :title="campaign.headline.schema.title"
              :prop="campaign.headline.value[language]"
            />
          </v-flex>
          <v-flex md4>
            <InputBox
              :title="campaign.subHeadline.schema.title"
              :prop="campaign.subHeadline.value[language]"
            />
          </v-flex>
          <v-flex md4>
            <InputBox
              :title="campaign.expinet.value.title.schema.title"
              :prop="campaign.expinet.value.title.value[language]"
            />
          </v-flex>
          <v-flex md6>
            <TextBox
              :title="campaign.description.schema.title"
              :prop="campaign.description.value[language]"
            />
          </v-flex>
          <v-flex md6>
            <InputBox
              v-if="['premium_segmented', 'premium'].indexOf(editorData.value.campaignType.value) !== -1"
              :prop="campaign.videoLink"
            />
            <FileBox
              :hq="true"
              :title="campaign.headerImage.schema.title"
              fileType="image"
              :prop="campaign.headerImage.value.file"
            >
              <InputBox
                title="alternative Bildbeschreibung"
                :prop="campaign.headerImage.value.alt.value[language]"
              />
              <CheckBox
                title="Teaser Bild - subject in focus"
                :prop="campaign.headerImageSubjectInFocus"
              />
            </FileBox>
          </v-flex>
          <v-flex md12>
            <Wrapper
              :wrapWithCard="true"
              title="Teaser"
              v-if="['bf'].indexOf(editorData.value.campaignType.value) !== -1"
            >
              <ContentSection
                :campaignTypeList="['bf']"
                :campaign="campaign"
                :editorData="editorData"
                :language="language"
              >
              </ContentSection>
            </Wrapper>
          </v-flex>
          <v-flex
            md12
            v-if="['premium_segmented', 'premium'].indexOf(editorData.value.campaignType.value) !== -1"
          >
            <Wrapper :wrapWithCard="true" title="Einstellungen">
              <v-layout wrap>
                <v-flex md4>
                  <DateTimeBox
                    :title="campaign.campaignEnd.schema.title"
                    :prop="campaign.campaignEnd"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
          </v-flex>
          <v-flex md12 v-if="editorData.value.campaignType.value == 'bf'">
            <Wrapper :wrapWithCard="true" title="Einstellungen">
              <v-layout wrap>
                <v-flex md12>
                  <Slider
                    :step="1"
                    :min="0"
                    :max="100"
                    :color="(campaign.trafficFallback.value < 33) ? 'green' : (campaign.trafficFallback.value < 66) ? 'orange' : 'red'"
                    :thumbColor="(campaign.trafficFallback.value < 33) ? 'green' : (campaign.trafficFallback.value < 66) ? 'orange' : 'red'"
                    :title="campaign.trafficFallback.schema.title"
                    :prop="campaign.trafficFallback"
                  />
                  <v-alert
                    :value="true"
                    type="info"
                    outlined
                  >Legt die Anzahl der Nutzer in Prozent fest, die die AKC Telefonnummer zu sehen bekommen, statt den "Buchen"-Button</v-alert>
                </v-flex>
                <v-flex md4>
                  <DateTimeBox
                    :title="campaign.campaignStart.schema.title"
                    :prop="campaign.campaignStart"
                  />
                </v-flex>
                <v-flex md4>
                  <DateTimeBox
                    :title="campaign.campaignEnd.schema.title"
                    :prop="campaign.campaignEnd"
                  />
                </v-flex>
                <v-flex md4>
                  <DateTimeBox
                    :title="campaign.campaignTeaserPricesStart.schema.title"
                    :prop="campaign.campaignTeaserPricesStart"
                  />
                </v-flex>
                <v-flex md12>
                  <v-alert
                    :value="true"
                    type="info"
                    outlined
                  >Erfordert Datum und Zeit im Format 30.09.2018 14:00:00</v-alert>
                </v-flex>
                <v-flex md3>
                  <Color :title="campaign.primaryColor.schema.title" :prop="campaign.primaryColor" />
                </v-flex>
                <v-flex md3>
                  <Color
                    :title="campaign.secondaryColor.schema.title"
                    :prop="campaign.secondaryColor"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
          </v-flex>
          <v-flex
            md12
            v-if="['bf', 'vario', 'lm', 'premium_segmented', 'premium'].indexOf(editorData.value.campaignType.value) !== -1"
          >
            <Array
              :title="editorData.value.campaignType.value == 'bf' ? 'Routen' : 'Specials'"
              :prop="campaign.specialOffers"
            >
              <template slot-scope="slotProps">
                <div slot="header">{{ slotProps.prop.value.name.value[language].value }}</div>
                <v-layout wrap>
                  <v-flex md6>
                    <InputBox
                      :title="slotProps.prop.value.name.schema.title"
                      :prop="slotProps.prop.value.name.value[language]"
                    />
                  </v-flex>
                  <v-flex md6 v-if="editorData.value.campaignType.value != 'bf'">
                    <InputBox
                      :title="slotProps.prop.value.campaignId.schema.title"
                      :prop="slotProps.prop.value.campaignId"
                    />
                  </v-flex>
                  <v-flex md6 v-if="editorData.value.campaignType.value == 'bf'">
                    <ComboBox
                      :customList="routeNamesBlackFriday"
                      :title="slotProps.prop.value.routeName.schema.title"
                      :prop="slotProps.prop.value.routeName"
                    />
                  </v-flex>
                  <v-flex md6 v-if="editorData.value.campaignType.value == 'bf'">
                    <InputBox
                      :title="slotProps.prop.value.price.schema.title"
                      :prop="slotProps.prop.value.price"
                    />
                  </v-flex>
                  <v-flex md6 v-if="editorData.value.campaignType.value == 'bf'">
                    <CheckBox
                      :title="slotProps.prop.value.inclusiveFlight.schema.title"
                      :prop="slotProps.prop.value.inclusiveFlight"
                    />
                  </v-flex>
                  <v-flex md6 v-if="editorData.value.campaignType.value != 'bf'">
                    <InputBox
                      :title="slotProps.prop.value.headline.schema.title"
                      :prop="slotProps.prop.value.headline.value[language]"
                    />
                  </v-flex>
                  <v-flex md6 v-if="editorData.value.campaignType.value != 'bf'">
                    <InputBox
                      :title="slotProps.prop.value.subHeadline.schema.title"
                      :prop="slotProps.prop.value.subHeadline.value[language]"
                    />
                  </v-flex>
                  <v-flex md12 v-if="editorData.value.campaignType.value != 'bf'">
                    <TextBox
                      :title="slotProps.prop.value.specialDescription.schema.title"
                      :prop="slotProps.prop.value.specialDescription.value[language]"
                    />
                  </v-flex>
                  <v-flex
                    md12
                    v-if="['premium_segmented', 'premium'].indexOf(editorData.value.campaignType.value) !== -1"
                  >
                    <InputBox :prop="slotProps.prop.value.videoLink" />
                  </v-flex>
                  <v-flex md4>
                    <FileBox
                      :hq="true"
                      :title="slotProps.prop.value.headerImage.schema.title"
                      fileType="image"
                      :prop="slotProps.prop.value.headerImage.value.file"
                    >
                      <InputBox
                        title="alternative Bildbeschreibung"
                        :prop="slotProps.prop.value.headerImage.value.alt.value[language]"
                      />
                    </FileBox>
                  </v-flex>
                  <v-flex md4>
                    <FileBox
                      :hq="true"
                      :title="slotProps.prop.value.teaserImage.schema.title"
                      fileType="image"
                      :prop="slotProps.prop.value.teaserImage.value.file"
                    >
                      <InputBox
                        title="alternative Bildbeschreibung"
                        :prop="slotProps.prop.value.teaserImage.value.alt.value[language]"
                      />
                    </FileBox>
                  </v-flex>
                  <v-flex md4 v-if="editorData.value.campaignType.value != 'bf'">
                    <FileBox
                      :hq="true"
                      :title="slotProps.prop.value.amenityIcon.schema.title"
                      fileType="image"
                      :prop="slotProps.prop.value.amenityIcon.value.file"
                    >
                      <InputBox
                        title="alternative Bildbeschreibung"
                        :prop="slotProps.prop.value.amenityIcon.value.alt.value[language]"
                      />
                    </FileBox>
                  </v-flex>
                  <v-flex
                    md12
                    v-if="['vario', 'lm', 'premium', 'premium_segmented'].indexOf(editorData.value.campaignType.value) !== -1"
                  >
                    <Array title="Zielgebietsfilter" :prop="slotProps.prop.value.virtualRegions">
                      <template slot-scope="slotProps">
                        <div slot="header">{{ slotProps.prop.value.name.value }}</div>
                        <v-layout wrap>
                          <v-flex md6>
                            <InputBox
                              :title="slotProps.prop.value.name.schema.title"
                              :prop="slotProps.prop.value.name"
                            />
                            <MultiComboBox
                              :multiSelect="true"
                              :customList="regions"
                              :prop="slotProps.prop.value.regions"
                            />
                            <InputBox
                              :title="slotProps.prop.value.headline.schema.title"
                              :prop="slotProps.prop.value.headline.value[language]"
                            />
                            <InputBox
                              :title="slotProps.prop.value.subHeadline.schema.title"
                              :prop="slotProps.prop.value.subHeadline.value[language]"
                            />
                          </v-flex>
                          <v-flex md6>
                            <FileBox
                              :hq="true"
                              :title="slotProps.prop.value.teaserImage.schema.title"
                              fileType="image"
                              :prop="slotProps.prop.value.teaserImage.value.file"
                            >
                              <InputBox
                                title="alternative Bildbeschreibung"
                                :prop="slotProps.prop.value.teaserImage.value.alt.value[language]"
                              />
                            </FileBox>
                          </v-flex>
                        </v-layout>
                      </template>
                    </Array>
                  </v-flex>
                  <v-flex md12 v-if="editorData.value.campaignType.value != 'bf'">
                    <InputBox
                      :title="slotProps.prop.value.tariffInformationHeadline.schema.title"
                      :prop="slotProps.prop.value.tariffInformationHeadline.value[language]"
                    />
                  </v-flex>
                  <v-flex md12 v-if="editorData.value.campaignType.value != 'bf'">
                    <TextBox
                      :title="slotProps.prop.value.tariffInformationDescription.schema.title"
                      :prop="slotProps.prop.value.tariffInformationDescription.value[language]"
                    />
                  </v-flex>
                  <v-flex
                    md12
                    v-if="['vario', 'lm'].indexOf(editorData.value.campaignType.value) !== -1"
                  >
                    <Wrapper
                      :wrapWithCard="true"
                      :title="slotProps.prop.value.crossSelling.schema.title"
                    >
                      <v-layout wrap>
                        <v-flex md6>
                          <CheckBox
                            title="Aktiv"
                            :prop="slotProps.prop.value.crossSelling.value.active"
                          />
                          <InputBox
                            :title="slotProps.prop.value.crossSelling.value.ctaText.schema.title"
                            :prop="slotProps.prop.value.crossSelling.value.ctaText.value[language]"
                          />
                          <SelectBox
                            :title="slotProps.prop.value.crossSelling.value.ctaLink.schema.title"
                            :prop="slotProps.prop.value.crossSelling.value.ctaLink"
                          />
                          <TextBox
                            :title="slotProps.prop.value.crossSelling.value.crossSellingDescription.schema.title"
                            :prop="slotProps.prop.value.crossSelling.value.crossSellingDescription.value[language]"
                          />
                        </v-flex>
                        <v-flex md6>
                          <FileBox
                            :hq="true"
                            :title="slotProps.prop.value.crossSelling.value.teaserImage.schema.title"
                            fileType="image"
                            :prop="slotProps.prop.value.crossSelling.value.teaserImage.value.file"
                          >
                            <InputBox
                              title="alternative Bildbeschreibung"
                              :prop="slotProps.prop.value.crossSelling.value.teaserImage.value.alt.value[language]"
                            />
                          </FileBox>
                        </v-flex>
                      </v-layout>
                    </Wrapper>
                  </v-flex>
                </v-layout>
              </template>
            </Array>
          </v-flex>
          <v-flex md12>
            <Wrapper :wrapWithCard="true" title="Tarifinformationen">
              <v-flex md12>
                <InputBox
                  :title="campaign.tariffInformationHeadline.schema.title"
                  :prop="campaign.tariffInformationHeadline.value[language]"
                />
              </v-flex>
              <v-flex md12>
                <TextBox
                  :title="campaign.tariffInformationDescription.schema.title"
                  :prop="campaign.tariffInformationDescription.value[language]"
                />
              </v-flex>
            </Wrapper>
          </v-flex>
          <v-flex md12>
            <Wrapper
              :wrapWithCard="true"
              title="Textmedia"
              v-if="['pauschal', 'vario', 'lm', 'premium', 'premium_segmented'].indexOf(editorData.value.campaignType.value) !== -1"
            >
              <ContentSection
                :campaignTypeList="['pauschal', 'vario', 'lm', 'premium', 'premium_segmented']"
                :campaign="campaign"
                :editorData="editorData"
                :language="language"
              >
              </ContentSection>
            </Wrapper>
          </v-flex>
          <v-flex md12>
            <Wrapper :wrapWithCard="true" :title="campaign.appContent.schema.title">
              <v-layout wrap>
                <v-flex md6>
                  <InputBox
                    :title="campaign.appContent.value.headline.schema.title"
                    :prop="campaign.appContent.value.headline.value[language]"
                  />
                </v-flex>
                <v-flex md6>
                  <InputBox
                    :title="campaign.appContent.value.subHeadline.schema.title"
                    :prop="campaign.appContent.value.subHeadline.value[language]"
                  />
                </v-flex>
                <v-flex md6>
                  <InputBox
                    :title="campaign.appContent.value.buttonText.schema.title"
                    :prop="campaign.appContent.value.buttonText.value[language]"
                  />
                </v-flex>
                <v-flex md6>
                  <InputBox
                    :title="campaign.appContent.value.campaignUrl.schema.title"
                    :prop="campaign.appContent.value.campaignUrl.value[language]"
                  />
                </v-flex>
                <v-flex md12>
                  <div>{{ campaign.appContent.value.appTeaserImage.schema.description }}</div>
                  <FileBox
                    :hq="true"
                    :title="campaign.appContent.value.appTeaserImage.schema.title"
                    fileType="image"
                    :prop="campaign.appContent.value.appTeaserImage.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="campaign.appContent.value.appTeaserImage.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
              </v-layout>
            </Wrapper>
          </v-flex>
          <v-flex
            md12
            v-if="['premium', 'premium_segmented'].indexOf(editorData.value.campaignType.value) === -1"
          >
            <Wrapper :wrapWithCard="true" :title="campaign.crossSelling.schema.title">
              <v-layout wrap>
                <v-flex md6>
                  <CheckBox title="Aktiv" :prop="campaign.crossSelling.value.active" />
                  <InputBox
                    :title="campaign.crossSelling.value.ctaText.schema.title"
                    :prop="campaign.crossSelling.value.ctaText.value[language]"
                  />
                  <SelectBox
                    :title="campaign.crossSelling.value.ctaLink.schema.title"
                    :prop="campaign.crossSelling.value.ctaLink"
                  />
                  <TextBox
                    :title="campaign.crossSelling.value.crossSellingDescription.schema.title"
                    :prop="campaign.crossSelling.value.crossSellingDescription.value[language]"
                  />
                </v-flex>
                <v-flex md6>
                  <FileBox
                    :hq="true"
                    :title="campaign.crossSelling.value.teaserImage.schema.title"
                    fileType="image"
                    :prop="campaign.crossSelling.value.teaserImage.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="campaign.crossSelling.value.teaserImage.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
              </v-layout>
            </Wrapper>
          </v-flex>
          <v-flex md12>
            <Wrapper :wrapWithCard="true" title="Metadaten">
              <v-layout wrap>
                <v-flex md12>
                  <InputBox :prop="editorData.value.metaTitle" />
                </v-flex>
                <v-flex md12>
                  <TextAreaBox :textOnly="true" :prop="editorData.value.metaDescription" />
                </v-flex>
              </v-layout>
            </Wrapper>
            <Wrapper :wrapWithCard="true" title="FAQ">
              <Array title :prop="editorData.value.faqItems">
                <template slot-scope="slotProps">
                  <div slot="header">{{ slotProps.prop.value.question.value }}</div>
                  <v-layout wrap>
                    <v-flex md12>
                      <InputBox
                        :title="slotProps.prop.value.question.schema.title"
                        :prop="slotProps.prop.value.question"
                      />
                    </v-flex>

                    <v-flex md12>
                      <InputBox
                        :title="slotProps.prop.value.answer.schema.title"
                        :prop="slotProps.prop.value.answer"
                      />
                    </v-flex>
                  </v-layout>
                </template>
              </Array>
            </Wrapper>
            <Wrapper :wrapWithCard="true" :title="campaign.loungeConfiguration.schema.title">
              <v-layout wrap>
                <v-flex md2>
                  <CheckBox :prop="campaign.loungeConfiguration.value.active" />
                </v-flex>
                <v-flex md10>
                  <InputBox
                    :prop="campaign.loungeConfiguration.value.limit"
                    :title="campaign.loungeConfiguration.value.limit.schema.title"
                  />
                </v-flex>

                <v-flex md12>
                  <SelectBox
                    :prop="campaign.loungeConfiguration.value.ship"
                    :title="campaign.loungeConfiguration.value.ship.schema.title"
                  />
                </v-flex>

                <v-flex md12>
                  <InputBox
                    :prop="campaign.loungeConfiguration.value.locationName"
                    :title="campaign.loungeConfiguration.value.locationName.schema.title"
                  />
                </v-flex>

                <v-flex md12>
                  <InputBox
                    :prop="campaign.loungeConfiguration.value.maxAgeDays"
                    :title="campaign.loungeConfiguration.value.maxAgeDays.schema.title"
                  />
                </v-flex>

                <v-flex md12>
                  <MultiComboBox
                    :multiSelect="false"
                    :customList="userContentCategories"
                    :prop="campaign.loungeConfiguration.value.categories"
                  />
                </v-flex>

                <v-flex md6>
                  <InputBox :prop="campaign.loungeConfiguration.value.position.value.latitude" />
                </v-flex>

                <v-flex md6>
                  <InputBox :prop="campaign.loungeConfiguration.value.position.value.longitude" />
                </v-flex>

                <v-flex
                  md12
                  v-if="campaign.loungeConfiguration.value.position.value.longitude.value > 0 || campaign.loungeConfiguration.value.position.value.latitude.value > 0"
                >
                  <InputBox
                    :prop="campaign.loungeConfiguration.value.maxDistance"
                    :title="campaign.loungeConfiguration.value.maxDistance.schema.title"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-container>
  </v-card>
</template>
<script>
import { client as aprilClient } from '@gecko-akh/april'
import Default from './Default.vue'
import ContentSection from './widgets/ContentSection.vue'
import {
  find,
  concat,
  map,
  forEach,
} from 'lodash-es'
import { get as getList } from '../../helper/ajax'

export default {
  extends: Default,
  components: {
    ContentSection
  },
  data() {
    return {
      regions: [],
      routeNamesBlackFriday: [],
      userContentCategories: [],
    }
  },
  computed: {
    campaign() {
      return this.editorData.value
    },
  },
  methods: {
    getRegionName(id) {
      const region = find(this.regions, o => o.value == id)
      return region ? region.text : id
    },
    async loadRegions() {
      const regions = await getList(`regions?select=id,name`)
      this.regions = concat(
        [{ text: 'Alle', value: 'all' }],
        map(regions, o => {
          return { text: o.name.de, value: o.id }
        })
      )
    },
    async loadBlackFriday() {
      const routeNames = []
      const cruises = await aprilClient.campaignCruisesByName(
        'Aktion Black Friday'
      )
      if (!cruises) return
      forEach(cruises, cruise => {
        if (!cruise.itinerary.length) return true
        const name = cruise.itinerary[0].name
        routeNames.push(name)
      })
      this.routeNamesBlackFriday = routeNames
    },
    async loadCategories() {
      this.userContentCategories = [
        { text: 'Nature', value: 'nature' },
        { text: 'Sight', value: 'sight' },
        { text: 'Shopping', value: 'shopping' },
        { text: 'Tour', value: 'tour' },
        { text: 'Food', value: 'food' },
        { text: 'Ship', value: 'ship' },
        { text: 'City', value: 'city' },
        { text: 'Viewpoint', value: 'viewpoint' },
      ]
    },
  },
  mounted() {
    this.loadBlackFriday()
    this.loadRegions()
    this.loadCategories()
  },
}
</script>
<style lang="scss" scoped>
.tab-wrapper {
    :deep(.tabs__bar) {
      position: fixed;
      top: 60px;
      z-index: 1000000;
      width: 100%;
      margin-left: -16px;
    }
}
@media (min-width: 1921px) {
  .tab-wrapper {
      :deep(.tabs__bar) {
        position: fixed;
        top: 120px;
        z-index: 1000000;
        width: calc(100% - 730px);
      }
    }
}
</style>
