<template>
<div>
    <v-tabs
      dark
      color="cyan"
      show-arrows
    ><slot/>
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab v-for="(tab, i) in tabs" :key="i" :href="'#tab-' + i">
        {{tab.header}}
      </v-tab>
        
  </v-tabs>
</div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({
        useUrlFragment: true,
      }),
    },
  },
  data: () => ({
    tabs: [],
    activeTabHash: '',
  }),
  created() {
    this.tabs = this.$children
  },
  mounted() {
    window.addEventListener('hashchange', () =>
      this.selectTab(window.location.hash)
    )
    if (this.findTab(window.location.hash)) {
      this.selectTab(window.location.hash)
      return
    } else {
      this.selectTab(this.tabs[0].hash)
    }
  },
  methods: {
    findTab(hash) {
      return this.tabs.find(tab => tab.hash === hash)
    },
    selectTab(selectedTabHash, event) {
      if (event && !this.options.useUrlFragment) {
        event.preventDefault()
      }
      const selectedTab = this.findTab(selectedTabHash)
      if (!selectedTab) {
        return
      }
      this.tabs.forEach(tab => {
        tab.isActive = tab.hash === selectedTab.hash
      })
      this.$emit('changed', { tab: selectedTab })
      this.activeTabHash = selectedTab.hash
    },
  },
}
</script>
<style lang="scss" scoped>
.tabs-list {
  width: 100%;
  // background: #6baccd;
  // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  background: #3399cc;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  list-style: none;
  height: 48px;
  padding: 0;
  margin: 0;

  .tab {
    display: inline-block;
    height: 48px;
    line-height: 48px;

    .tab-link {
      display: inline-block;
      height: 47px;
      line-height: 48px;
      padding: 0 30px;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0.5px;
      text-align: center;
      text-transform: uppercase;
      font-weight: normal;
      text-decoration: none;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid #ffff8c;
      }
    }

    &.is-active .tab-link {
      border-bottom: 1px solid #ffff8c;
    }
  }
}

.tabs-panels {
}
</style>
