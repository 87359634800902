<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex md2>
        <!-- <v-layout justify-left>
          <v-flex md12>
            <v-card>
            <v-card-text>Alarme</v-card-text>
                
            </v-card>
          </v-flex>
        </v-layout> -->
      </v-flex>
      <v-flex md8>
        <v-layout justify-center text-center>
          <v-flex>
            <v-icon style="font-size: 200px; color:rgba(0,0,0,.27);">devices_other</v-icon>
            <p class="headline">Willkommen in der Zoe</p>
            <p class="">Asset-Management für AIDA Cruises</p>
            <router-link tag="v-btn" class="primary" :to="{ name: 'resources' }">
              Assets
            </router-link>
            <div class="changelog">
              <span class="headline">Was gibts neues?</span>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>17.07.2024</v-list-item-title>
                    <v-list-item-subtitle>Entfernen Tarife Bereich</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>14.06.2024</v-list-item-title>
                    <v-list-item-subtitle>Entfernen Tracking Bereich</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>14.06.2024</v-list-item-title>
                    <v-list-item-subtitle>Entfernen Karriere Bereich</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>06.2022</v-list-item-title>
                    <v-list-item-subtitle>Fix Typo-Import Länderkennung</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>06.2022</v-list-item-title>
                    <v-list-item-subtitle>Hafen Pflichtfelder geändert</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>23.11.2018</v-list-item-title>
                    <v-list-item-subtitle>Tracking Bereich</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>20.08.2018</v-list-item-title>
                    <v-list-item-subtitle>Karriere Portal Content</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>20.07.2018</v-list-item-title>
                    <v-list-item-subtitle>Entwurfsmodus (beta)</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>18.06.2018</v-list-item-title>
                    <v-list-item-subtitle>Neues Layout der Zoe</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>15.05.2018</v-list-item-title>
                    <v-list-item-subtitle>neue Eingabemaske für Schiffe</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>27.04.2018</v-list-item-title>
                    <v-list-item-subtitle>Passwort ändern Funktion</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex md2>
        
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.list {
  max-width: 100%;
  width: 290px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid rgba(#000, 0.12);
}

.changelog {
  margin: 0 auto;
  width: 320px;
  margin-top: 30px;
  .headline {
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }
}
</style>