<template>
  <v-card
    flat
    :class="{
    'list-wrapper': true,
    'update-open': showDialogUpdate && item
    }"
  >
    <v-card-title class="list-title">
      <span class="display-1">{{ currentSchema.title }}</span>
    </v-card-title>
    <v-card-title>
      <v-text-field
        :value="value"
        @input="updateQuery"
        append-icon="search"
        label="Suche..."
        single-line
        hide-details
        ref="searchInput"
      ></v-text-field>
      <v-spacer></v-spacer>
      <router-link v-if="!isReadOnly" tag="v-btn" class="primary" :to="{ name: 'resourceCreate'}">
        <v-icon>add</v-icon>Erstellen
      </router-link>
      <v-btn @click="refreshList">
        <v-icon>refresh</v-icon>Refresh
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="renderResources"
      :must-sort="true"
      class="table"
      v-show="renderResources.length"
      :footer-props="footerProps"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td
            class="clickable"
            @click="attribute !== 'teaserImage' ? openItem(item.id): null"
            v-for="attribute in attributes"
            :key="$route.params.resource+attribute"
            v-html="formatAfterSorting(item, attribute)"
          ></td>
          <td class="text-md-center justify-center px-0">
            <PreviewIcon :item="item"></PreviewIcon>
            <v-btn v-show="isDeletable" icon color="error" text @click="showDelete(item.id)">
              <v-icon>delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-layout justify-center text-md-center v-show="!renderResources.length">
      <v-flex xs10 sm6 md2>
        <v-icon style="font-size: 200px;">search</v-icon>
        <p class="headline">Keine Einträge gefunden</p>
        <p class>{{ description }}</p>
        <router-link tag="v-btn" class="primary raised" :to="{ name: 'resourceCreate'}">Erstellen</router-link>
      </v-flex>
    </v-layout>
    <!-- <div v-if="item && showDialogUpdate" class="curtain"></div-->
    <ResourceUpdate
      v-if="item"
      :id="id"
      ref="resourceUpdate"
      :resource="resource"
      :draftId="draftId"
      v-model="showDialogUpdate"
    />
    <v-dialog v-model="deleteDialog" max-width="330">
      <v-card>
        <v-card-title class="headline">Löschen</v-card-title>
        <v-card-text>
          Möchtest du
          <strong>{{ deleteId }}</strong> wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click.native="deleteItem()">Löschen</v-btn>
          <v-btn color="primary" @click.native="deleteId=null; deleteDialog = false;">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import store from "../stores";
import {
  cloneDeep,
  map,
  keys,
  truncate,
  forEach,
  get
} from 'lodash-es';
import { processValueByDefinition, applyFilterRules } from "../helper/value";
import PreviewIcon from "./subComponents/previewIcon.vue";
import ResourceUpdate from "./ResourceUpdate.vue";
import moment from "moment";

export default {
  props: {
    id: String | Number,
    resource: String,
    draftId: String | undefined
  },
  data() {
    return {
      currentQuery: "",
      searchResults: [],
      activeSearchWithNoResults: false,
      deleteDialog: false,
      deleteId: null,
      sortBy: "createdAt",
      sortDesc: true,
      footerProps: {
        'items-per-page-text': 'Zeilen pro Seite',
        'items-per-page-options':[20,40, {'text':'Alle','value':-1}]
      }
    };
  },
  computed: {
    showDialogUpdate() {
      return this.$router.app._route.name != "resourceList";
    },
    headers() {
      const headers = [];
      forEach(this.attributes, attribute => {
        headers.push({
          text: this.currentSchema.properties[attribute].title,
          value: attribute
        });
      });
      headers.push({
        text: "Aktionen",
        value: "actions",
        sort: false
      });
      return headers;
    },
    description() {
      return this.value
        ? `Keine Einträge gefunden für '${this.value}'. Versuche einen anderen Suchbegriff oder erstelle ein Asset!`
        : "Erstelle das erste Asset!";
    },
    value() {
      return this.$store.getters["search/query"];
    },
    renderResources() {
      return this.resourceList;
    },
    currentSchema() {
      return this.$store.getters["crud/resourceSchema"];
    },
    isReadOnly() {
      return this.$store.getters["crud/resourceSchema"].permissions.readOnly;
    },
    searchResult() {
      return this.$store.getters["search/matches"];
    },
    resourceList() {
      const results = cloneDeep(this.$store.getters["search/matches"]);
      let resourceList = cloneDeep(
        this.$store.getters["crud/currentResourceList"]
      );
      resourceList = results.length === 0 ? resourceList : results;
      const schema = this.$store.getters["crud/resourceSchema"];
      if (!schema) {
        return [];
      }

      if (this.activeSearchWithNoResults) {
        return [];
      }

      resourceList = applyFilterRules(resourceList, schema.id);

      const properties = schema.properties;

      resourceList = map(resourceList, resourceItem => {
        for (let key of keys(resourceItem)) {
          if (!properties[key]) continue;
          resourceItem[key] = this.processValue(
            resourceItem[key],
            properties[key],
            key
          );
        }
        return resourceItem;
      });

      return resourceList;
    },
    allResults() {
      let resourceList = cloneDeep(
        this.$store.getters["crud/currentResourceList"]
      );
      const schema = this.$store.getters["crud/resourceSchema"];
      if (!schema) {
        return [];
      }
      resourceList = applyFilterRules(resourceList, schema.id);

      const properties = schema.properties;

      resourceList = map(resourceList, resourceItem => {
        for (let key of keys(resourceItem)) {
          if (!properties[key]) continue;
          resourceItem[key] = this.processValue(
            resourceItem[key],
            properties[key],
            key
          );
        }
        return resourceItem;
      });

      return resourceList;
    },
    attributes() {
      return this.$store.getters["crud/visibleProperties"];
    },
    isDeletable() {
      const schema = this.currentSchema;
      return !get(schema, "dataFromVendorApi");
    }
  },
  methods: {
    formatAfterSorting(item, attribute) {
      if (
        attribute === "createdAt" ||
        attribute === "updatedAt" ||
        attribute === "lastImport"
      ) {
        return moment(item[attribute]).format("DD.MM.YYYY - HH:mm");
      }
      return item[attribute];
    },
    openItem(id) {
      const resource = this.isReadOnly ? "resourceView" : "resourceUpdate";
      this.$router.push({ name: resource, params: { id } });
    },
    item() {
      return this.$store.getters["crud/resourceItem"];
    },
    showDelete(id) {
      this.deleteDialog = true;
      this.deleteId = id;
    },
    async deleteItem() {
      await this.$store.dispatch("crud/deleteItem", {
        id: this.deleteId,
        type: this.$route.params.resource
      });
      this.deleteDialog = false;
      this.deleteId = null;
      this.updateQuery(this.currentQuery);
    },
    processValue(value, definition, key) {
      return processValueByDefinition(value, definition, key);
    },
    updateQuery(query) {
      this.currentQuery = query;
      if (query.length > 2) {
        this.$store.dispatch("search/search", {
          query: query,
          resourceList: this.allResults
        });
        this.activeSearchWithNoResults = false;
      }

      if (query.length <= 2 && this.searchResult.length !== 0) {
        this.$store.dispatch("search/reset");
      }

      if (query.length > 2 && this.searchResult.length == 0) {
        this.activeSearchWithNoResults = true;
      }

      if (
        query.length <= 2 &&
        this.searchResult.length == 0 &&
        this.activeSearchWithNoResults == true
      ) {
        this.activeSearchWithNoResults = false;
        this.$store.dispatch("search/reset");
      }
    },
    async refreshList() {
      const type = this.$store.getters["crud/currentResourceType"];
      if (await this.$store.dispatch("crud/refreshList", { type })) {
        this.$store.dispatch("messages/setSuccess", "Liste aktualisiert");
      }
    },
    truncate(val) {
      return truncate(val, {
        length: 42
      });
    },
    async importData(to, from) {
      this.$store.dispatch("messages/setPreloader", true);
      if (this.resource != to.params.resource) {
        await store.dispatch("crud/importResourceData", to.params.resource);
      }
      if (!to.params.id) {
        await store.dispatch("crud/removeCurrentItem", to.params);
      }
      this.$store.dispatch("messages/setPreloader", false);
    },
    async beforeRouteChange(to, from, next) {
      const resourceUpdate = this.$refs.resourceUpdate;
      if (this.id && resourceUpdate) {
        resourceUpdate.cancel(async () => {
          await this.importData(to, from);
          next();
        }, resourceUpdate.draftMode);
        return;
      }
      await this.importData(to, from);
      next();
    }
  },
  watch: {
    searchResult(newVal, oldVal) {
      if (!newVal) return;
      this.searchResults = newVal;
    },
    currentSchema(newVal, oldVal) {
      if (!newVal || !oldVal) return;
      this.$nextTick(function() {
        if (newVal.id != oldVal.id) {
          this.updateQuery("");
          this.$store.dispatch("search/reset");
        }
      });
    },
    item(value) {
      this.showUpdate = !!item;
    }
  },
  created() {
    this.$store.dispatch("messages/setPreloader", false);
  },
  async beforeRouteLeave(to, from, next) {
    await this.beforeRouteChange(to, from, next);
  },
  async beforeRouteUpdate(to, from, next) {
    await this.beforeRouteChange(to, from, next);
  },
  components: { PreviewIcon, ResourceUpdate }
};
</script>
<style lang="scss" scoped>
.card {
  width: 100%;
}

.table {
  width: 100%;

    :deep(.clickable) {
      cursor: pointer;
    }
}

.list-title {
  padding-bottom: 0;
}

.table {
    :deep(.btn) {
      margin: 4px 2px;
    }
}

.curtain {
  width: 100%;
  height: 100%;
  position: fixed;
  left: -200px;
  top: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  pointer-events: none;
}
</style>
