<template>
  <v-text-field
    autocomplete="off"
    :readonly="readonly"
    :error-messages="errors"
    :label="internalTitle"
    :type="type"
    :value="value"
    ref="formField"
    @input="updateValue"
  ></v-text-field>
</template>
<script>
import Base from './Base.vue'
export default {
  props: {
    format: {
      type: String,
      default: null,
    },
  },
  computed: {
    type() {
      let format =
        this.format !== null
          ? this.format
          : this.prop.type
          ? this.prop.type
          : 'text'
      switch (format) {
        case 'number':
        case 'integer':
          return 'number'
        default:
          return format
      }
    },
  },
  methods: {
    updateValue(value) {
      let type =
        this.format !== null
          ? this.format
          : this.prop.type
          ? this.prop.type
          : 'text'
      if (!this.prop.required && value === '') return this.update(null)
      switch (type) {
        case 'integer':
          value = parseInt(value, 10)
          break
        case 'number':
          value = parseFloat(value)
          break
      }
      this.update(value)
    },
  },
  extends: Base,
}
</script>
