<script>
import { cloneDeep, isString, get, set } from 'lodash-es'
import { propertySchema } from '../../../helper/schema'

export default {
  components: {},
  props: ['prop', 'readonly', 'title', 'noTitle', 'headlineClass'],
  data() {
    return {
      required: false,
      origin: this.prop.origin,
      error: this.prop.error,
    }
  },
  computed: {
    language() {
      return this.$store.getters['settings/formLanguage']
    },
    input() {
      return this.$refs.formField
    },
    hasError() {
      return false
    },
    value() {
      return this.prop.value
    },
    internalTitle() {
      return this.noTitle
        ? null
        : isString(this.title)
          ? this.title
          : get(this.prop, 'schema.title') || this.prop.key
    },
    errors() {
      return this.prop.error ? [this.prop.error] : []
    },
  },
  watch: {
    prop() {
      if (this.error !== this.prop.error) {
        this.error = this.prop.error
      }
      if (this.origin !== this.prop.origin) {
        this.origin = this.prop.origin
        this.refresh()
      }
    },
  },
  methods: {
    refresh() {},
    checkRequired() {},
    update(value) {
      this.prop.value = value
    },
  },
  created() {},
}
</script>
