// handler for external APIs
import { vendor } from '@gecko-akh/carol'
import store from '../stores'
import { find, get, map, pick } from 'lodash-es'

export async function getVisibleApiProps(type) {
  let data
  const schemas = store.getters['crud/schemas']
  const schema = find(schemas, schema => {
    return schema.id === type
  })

  if (get(schema, 'dataFromVendorApi')) {
    if (type === 'aida-lounge') {
      data = await getLoungeData()
      data = map(data, item => {
        return pick(item, schema.visibleProperties)
      })
    }
  }

  return data
}

async function getLoungeData() {
  const data = await vendor.getLoungePosts({ sort: 'relevance' })
  return data
}
