<template>
    <v-layout wrap>
      <v-flex md12>
        <InputBox v-if="!campaignTypeBF"
          :title="campaign.contentSectionsHeadline.schema.title"
          :prop="campaign.contentSectionsHeadline.value[language]"
        />

        <Array
          :title="campaign.contentSections.schema.title"
          :prop="campaign.contentSections"
        >
          <template slot-scope="slotProps">
            <v-layout wrap>
              <v-flex :md6="!campaignTypeBF" :md12="campaignTypeBF">
                <TextBox
                  :title="slotProps.prop.value.contentSectionDescription.schema.title"
                  :prop="slotProps.prop.value.contentSectionDescription.value[language]"
                />
              </v-flex>
              <v-flex md6 v-if="!campaignTypeBF">
                <VideoLinkBox
                  :title="slotProps.prop.value.videoLink.schema.title"
                  :prop="slotProps.prop.value.videoLink"
                />
                <SelectBox
                  v-if="['premium', 'premium_segmented'].indexOf(editorData.value.campaignType.value) !== -1"
                  :prop="slotProps.prop.value.category"
                  />
              </v-flex>
              <v-flex md12>
                <Array
                  :title="slotProps.prop.value.contentSectionImage.schema.title"
                  :prop="slotProps.prop.value.contentSectionImage"
                >
                  <template slot-scope="slotProps">
                    <v-layout wrap>
                      <v-flex md12>
                        <FileBox
                          :hq="true"
                          :title="slotProps.prop.schema.title"
                          fileType="image"
                          :prop="slotProps.prop.value.file"
                        >
                          <InputBox
                            title="alternative Bildbeschreibung"
                            :prop="slotProps.prop.value.alt.value[language]"
                          />
                        </FileBox>
                      </v-flex>
                    </v-layout>
                  </template>
                </Array>
              </v-flex>
            </v-layout>
          </template>
        </Array>
      </v-flex>
    </v-layout>
</template>
<script>
import Array from '../formComponents/Array.vue'
import FileBox from '../formComponents/FileBox.vue'
import InputBox from '../formComponents/InputBox.vue'
import SelectBox from '../formComponents/SelectBox.vue'
import TextBox from '../formComponents/TextBox.vue'
import VideoLinkBox from '../formComponents/VideoLinkBox.vue'

export default {
  components: {
    Array,
    FileBox,
    InputBox,
    SelectBox,
    TextBox,
    VideoLinkBox
  },
  props: {
    campaignTypeList: {
    },
    campaign: {
      type: Object,
      default: {},
    },
    editorData: {
      type: Object,
      default: {},
    },
    language: {
      type: String,
      default: 'de'
    }
  },
  computed: {
    campaignTypeBF() {
      return this.campaignTypeList.indexOf('bf') !== -1
    }
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
</style>