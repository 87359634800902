// import { get, del, put, post } from 'pkg-frontend-ajax'
import Fuse from 'fuse.js'
import { includes } from 'lodash-es'
import { threshold } from '../config/threshold'

// configuration/tweaking for the fuzzy text search
const fuseOpts = {
  shouldSort: true,
  // findAllMatches: true,
  tokenize: false,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: [],
}

const state = {
  query: '',
  matches: [],
  order: { attribute: 'updatedAt', direction: 'desc' },
}

const getters = {
  query(state) {
    return state.query
  },
  matches(state) {
    return state.matches
  },
  order(state) {
    return state.order
  },
  keys() {
    const keys = []
    const currentSchema = $store.getters['crud/resourceSchema']
    if (currentSchema && currentSchema.hasOwnProperty('visibleProperties')) {
      for (let item of currentSchema.visibleProperties) {
        if (isI18n(currentSchema, item)) {
          keys.push(item)
        }

        if (includes(currentSchema.properties[item].type, 'string')) {
          keys.push(item)
        }
      }
      return keys
    }
  },
}

const actions = {
  search(store, { query, resourceList }) {
    store.commit('SET_QUERY_PARAMS', query)
    store.dispatch('processQuery', resourceList)
  },
  processQuery(store, list) {
    fuseOpts.keys = store.getters['keys']
    fuseOpts.threshold = getThreshold()
    const query = store.getters['query']
    const fuse = new Fuse(list, fuseOpts)
    const matches = fuse.search(query).map(items => items.item)

    store.commit('SET_MATCHES', matches)
  },
  reset(store) {
    store.commit('SET_MATCHES', [])
    store.commit('SET_QUERY_PARAMS', '')
  },
  order(store, { attribute, direction }) {
    store.commit('SET_ORDER', { attribute, direction })
  },
}

const mutations = {
  SET_QUERY_PARAMS(state, query) {
    state.query = query
  },
  SET_MATCHES(state, list) {
    state.matches = list
  },
  SET_ORDER(state, orderOptions) {
    state.order.attribute = orderOptions.attribute
    state.order.direction = orderOptions.direction
  },
}

function isI18n(currentSchema, item) {
  return (
    currentSchema.properties[item]['$ref'] == '#/definitions/i18n' ||
    (currentSchema.properties[item].properties &&
      currentSchema.properties[item].properties.hasOwnProperty('de'))
  )
}

function getThreshold() {
  const currentSchema = $store.getters['crud/resourceSchema'].id
  return threshold[currentSchema] || 0
}

export default { state, getters, actions, mutations, namespaced: true }
