import {
  cloneDeep,
  concat,
  forEach,
  keys,
  isString,
  last,
  merge,
  isArray,
  isObject,
  includes,
  get as _get,
} from 'lodash-es'
import store from '../stores'

const schemaAssetReadingConfigs = [
  {
    definitionKey: 'sketch',
    bucketDomainKey: 'bea-sketches',
    bucketSigningIdentifier: 'sketches',
    assetType: 'svg',
    definitionPaths: [['file']],
  },
  {
    definitionKey: 'image',
    bucketDomainKey: 'bea-images',
    bucketSigningIdentifier: 'images',
    assetType: 'png',
    definitionPaths: [['file']],
  },
  {
    definitionKey: 'imageWithDescription',
    bucketDomainKey: 'bea-images',
    bucketSigningIdentifier: 'images',
    assetType: 'png',
    definitionPaths: [['file']],
  },
  {
    definitionKey: 'pdf',
    bucketDomainKey: 'bea-pdfs',
    bucketSigningIdentifier: 'pdfs',
    assetType: 'pdf',
    definitionPaths: [['file']],
  },
  {
    definitionKey: 'font',
    bucketDomainKey: 'bea-fonts',
    bucketSigningIdentifier: 'fonts',
    assetType: 'woff',
    definitionPaths: [
      'regular.woff'.split('.'),
      'w300.woff'.split('.'),
      'w700.woff'.split('.'),
    ],
  },
  {
    definitionKey: 'video',
    bucketDomainKey: 'bea-videos',
    bucketSigningIdentifier: 'videos',
    assetType: 'mp4',
    definitionPaths: [['file']],
  },
  {
    definitionKey: 'audio',
    bucketDomainKey: 'bea-audio',
    bucketSigningIdentifier: 'audio',
    assetType: 'mp3',
    definitionPaths: [['file']],
  }
]

const collapsableResources = ['ships']

// search for $ref sketch/image in schema, parse schema to get a list of paths we need to check
// -> sth like root.cafees.[].images.[] ; 'sketch' will search for $ref : #/definitions/sketch
function getSchemaPathsForDefinition(definitionSchema, schema) {
  const iterateOnPropertiesOfSchema = function(
    currentPathElements,
    schema,
    searchedDefinition,
    currentTypeIsArray
  ) {
    let paths = []
    const propertyKeys = !currentTypeIsArray ? keys(schema) : ['items']
    const pathElements = cloneDeep(currentPathElements)
    for (const key of propertyKeys) {
      if (schema[key]) {
        if (
          schema[key].$ref &&
          schema[key].$ref == `#/definitions/${searchedDefinition}`
        ) {
          if (!currentTypeIsArray) {
            forEach(definitionSchema.definitionPaths, subdefinitionKeys => {
              paths.push(
                concat(cloneDeep(pathElements), key, subdefinitionKeys)
              )
            })
          } else {
            forEach(definitionSchema.definitionPaths, subdefinitionKeys => {
              paths.push(concat(cloneDeep(pathElements), subdefinitionKeys))
            })
          }
          continue
        }
        const schemaPart = schema[key]
        const pe = cloneDeep(pathElements)

        switch (true) {
          case includes(schemaPart.type, 'object'): {
            if (!currentTypeIsArray) {
              pe.push(key)
            }

            const newPaths = iterateOnPropertiesOfSchema(
              pe,
              schemaPart.properties,
              searchedDefinition,
              false
            )
            paths = concat(paths, newPaths)
            break
          }
          case includes(schemaPart.type, 'array'): {
            if (!currentTypeIsArray) {
              pe.push(key)
            }
            pe.push('[]')
            const newPaths = iterateOnPropertiesOfSchema(
              pe,
              schemaPart,
              searchedDefinition,
              true
            )
            paths = concat(paths, newPaths)
            break
          }
        }
      }
    }

    return paths
  }

  return iterateOnPropertiesOfSchema(
    [],
    schema.properties,
    definitionSchema.definitionKey
  )
}

function resolveReference(definitionObject) {
  if (
    typeof definitionObject === 'object' &&
    definitionObject.hasOwnProperty('$ref')
  ) {
    const refs = store.getters['crud/referencedDefinitions']
    const definition = last(definitionObject.$ref.split('/'))
    const ref = refs[definition]
    delete definitionObject.$ref
    definitionObject = merge(definitionObject, ref)
  }

  return definitionObject
}

function propertySchema(propertyPath, schema, returnParent) {
  let properties = propertyPath
    .replace(/(\.(?![0-9]+))+/g, '.properties.')
    .replace(/(\.[0-9]+\.)+/g, '.items.')
    .replace(/\.[0-9]+$/, '')
  return _get(schema, 'properties.' + properties)
}

function derefSchema(schema, definitions) {
  if (!schema) {
    return {
      permissions: {
        readOnly: true
      },
      properties: []
    }
  }

  if (!definitions && schema.definitions) definitions = schema.definitions
  if (schema.$ref && schema.$ref.startsWith('#/definitions/i18n')) {
    return derefFormLanguage(schema, definitions)
  }
  if (schema.$ref) schema = deref(schema, definitions)
  if (schema.properties) {
    forEach(schema.properties, function(value, key) {
      schema.properties[key] = derefSchema(schema.properties[key], definitions)
    })
  } else if (schema.type && includes(schema.type, 'array') && schema.items) {
    schema.items = derefSchema(schema.items, definitions)
  }
  return schema
}

/**
 * Replaces $ref definitions with definition from generic cindy schema files.
 */
function deref(schema, definitions) {
  let definition = schema.$ref.replace('#/definitions/', '')
  if (definitions[definition]) {
    schema = merge(schema,cloneDeep(definitions[definition]))
  }
  return schema
}

/**
 * Rebuilds the language definition of a schema using the language independent
 * definition (otherwise the i18n definition will override it) for the current
 * form language. This is needed to enable validation for translated fields.
 */
function derefFormLanguage(schema, definitions) {
  const language = store.getters['settings/formLanguage']

  schema.properties = schema.properties || {}

  // prepare form language schema
  const formLanguageSchema = cloneDeep(schema)
  delete formLanguageSchema.$ref
  if (formLanguageSchema.properties[language]) {
    delete formLanguageSchema.properties[language].properties
  }  

  deref(schema, definitions)

  // add form language schema to language schemas
  schema.properties[language] = formLanguageSchema
  schema.type = 'object'
  delete schema.$ref

  return schema
}

function schemaToItem(schema) {
  const item = {}
  forEach(schema.properties, function(value, key) {
    let prop
    if (value.type && includes(value.type, 'object')) {
      prop = schemaToItem(value)
    } else if (value.type && includes(value.type, 'array')) {
      prop = []
    } else {
      prop = getDefaultValue(value)
    }
    item[key] = prop
  })
  return item
}

function itemsAreEqual(left, right, schema) {}

function getDefaultValue(value) {
  if (value.hasOwnProperty('default')) return value.default
  if (value.type && includes(value.type, 'boolean')) return false
  if (value.type && includes(value.type, 'null')) return null
  if (value.type && includes(value.type, 'string')) return ''
  return null
}

function updateItemFromSchema(item, schema) {
  item = cloneDeep(item)
  const dummy = schemaToItem(schema)
  const left = dummy
  const right = item
  if (schema.properties) {
    forEach(schema.properties, function(schemaPart, key) {
      if (typeof left[key] == 'undefined') left[key] = schemaToItem(schemaPart)
      if (right && typeof right[key] != 'undefined') {
        if (schemaPart.type && includes(schemaPart.type, 'object')) {
          left[key] = updateItemFromSchema(right[key], schemaPart)
        } else if (schemaPart.type && includes(schemaPart.type, 'array')) {
          if (right[key].length) {
            left[key] = []
            for (let i = 0; i < right[key].length; i++) {
              left[key][i] = updateItemFromSchema(
                right[key][i],
                schemaPart.items
              )
            }
          } else {
            left[key] = right[key]
          }
        } else {
          left[key] = isObject(right[key]) ? null : right[key]
        }
      }
    })
  }

  return left
}

function connectItemAndSchema(data, schema, path, parent) {
  let joint
  const jointSchema = path ? propertySchema(path.join('.'), schema) : schema
  const currentProp = path ? path[path.length - 1] : ''
  if (isArray(data)) {
    joint = {
      value: [],
      type: 'array',
      i18n: isI18n(jointSchema),
      schema: jointSchema,
      origin: data,
      path: path ? path.join('.') : '',
      key: currentProp,
      open: null,
      parent() {
        return parent
      },
    }
  } else {
    joint = {
      value: {},
      type: 'object',
      i18n: isI18n(jointSchema),
      schema: jointSchema.items || jointSchema,
      origin: data,
      path: path ? path.join('.') : '',
      key: currentProp,
      parent() {
        return parent
      },
    }
  }
  if (isArray(data)) addArrayFunctions(joint)
  if (!path) path = []
  forEach(data, (value, key) => {
    if (isArray(data)) {
      joint.value[key] = connectItemAndSchema(
        value,
        schema,
        concat(path, key),
        joint
      )
      joint.value[key].origin = value
    } else if (isObject(value)) {
      joint.value[key] = connectItemAndSchema(
        value,
        schema,
        concat(path, key),
        joint
      )
      joint.value[key].origin = value
    } else {
      const concatPath = concat(path, key).join('.')
      const propSchema = propertySchema(concatPath, schema)
      joint.value[key] = {
        value,
        origin: value,
        preview: null,
        type: getType(propSchema),
        required: getRequired(propSchema),
        schema: propSchema,
        path: concatPath,
        key,
        error: '',
        parent() {
          return joint
        },
      }
    }
  })
  return joint
}

function isI18n(propSchema) {
  const ref = propSchema.$ref
  const refItems = _get(propSchema, 'items.$ref') // for i18n lists with a single input field -> e.g. lines in ships schema
  if (
    (isString(ref) && ref.indexOf('#/definitions/i18n') !== -1) ||
    propSchema.i18n ||
    (isString(refItems) && refItems.indexOf('#/definitions/i18n') !== -1)
  ) {
    return true
  }
  return false
}

function getRequired(propSchema){
  return !isArray(propSchema.type) || !propSchema.type.indexOf("null")
}

function getType(propSchema) {
  if (propSchema.media) {
    return propSchema.media.type && propSchema.media.type.startsWith('image')
      ? 'image'
      : 'octet-stream'
  }
  if (propSchema.enum) {
    return 'select'
  }

  if (propSchema.format) {
    switch (propSchema.format) {
      case 'textarea':
      case 'markdown':
      case 'date':
      case 'datetime-local':
      case 'videoUrl':
        return propSchema.format
        break
    }
  }

  return propSchema.type
    ? isArray(propSchema.type)
      ? propSchema.type[0]
      : propSchema.type
    : 'string'
}

function addArrayFunctions(joint, data) {
  joint.cacheItem = null
  joint.cutIndex = null

  joint.add = after => {
    joint.value.splice(
      after + 1,
      0,
      connectItemAndSchema(schemaToItem(joint.schema.items), joint.schema.items)
    )
    joint.open = after + 1
  }

  joint.addEnd = () => {
    joint.value.push(
      connectItemAndSchema(schemaToItem(joint.schema.items), joint.schema.items)
    )
    joint.open = joint.value.length - 1
  }

  joint.update = values => {
    joint.value.length = 0
    forEach(values, value => {
      joint.value.push(connectItemAndSchema(value, joint.schema.items))
    })
  }

  joint.insertAfter = after => {
    joint.value.splice(after + 1, 0, joint.getCacheItem())
    joint.cacheItem = null
    joint.open = after + 1
  }

  joint.copy = index => {
    joint.setCacheItem(joint.value[index])
  }

  joint.cut = index => {
    joint.setCacheItem(joint.value[index])

    joint.cutIndex = index
  }

  joint.move = (oldIndex, newIndex) => {
    joint.value.splice(newIndex, 0, joint.value.splice(oldIndex, 1)[0])
    joint.open = newIndex
  }

  joint.remove = index => {
    joint.value.splice(index, 1)
  }

  joint.removeAll = item => {
    joint.value = []
  }

  joint.getCacheItem = () => {
    return joint.cacheItem
  }

  joint.setCacheItem = item => {
    joint.cacheItem = connectItemAndSchema(
      disconnectItemAndSchema(item),
      joint.schema.items
    )
  }
}

function disconnectItemAndSchema(joint) {
  const disjoint = joint.type == 'array' ? [] : {}
  forEach(joint.value, (value, key) => {
    disjoint[key] =
      value.type == 'array' || value.type == 'object'
        ? disconnectItemAndSchema(value)
        : value.value
  })
  return disjoint
}

export {
  getSchemaPathsForDefinition,
  schemaAssetReadingConfigs,
  resolveReference,
  schemaToItem,
  derefSchema,
  propertySchema,
  updateItemFromSchema,
  connectItemAndSchema,
  disconnectItemAndSchema,
  itemsAreEqual,
}
