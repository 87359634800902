<template>
  <div class="videolinkbox">
    <div
      v-if="src"
      class="embed-responsive embed-responsive-16by9"
    >
      <iframe
        class="embed-responsive-item"
        :src="src"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
    <v-text-field
      autocomplete="off"
      :readonly="readonly"
      :error-messages="errors"
      :label="internalTitle"
      :value="value"
      ref="formField"
      @input="update"
    ></v-text-field>
  </div>
</template>
<script>
import Base from './Base.vue'
export default {
  extends: Base,
  computed: {
    src() {
      return this.value
        ? 'https://www.youtube-nocookie.com/embed/' +
            this.getId(this.value) +
            '?rel=0&amp;controls=0&amp;showinfo=0'
        : null
    },
  },
  methods: {
    getId(str) {
      // shortcode
      const shortcode = /youtube:\/\/|https?:\/\/youtu\.be\//g
      if (shortcode.test(str)) {
        const shortcodeid = str.split(shortcode)[1]
        return this.stripParameters(shortcodeid)
      }

      // /v/ or /vi/
      const inlinev = /\/v\/|\/vi\//g
      if (inlinev.test(str)) {
        const inlineid = str.split(inlinev)[1]
        return this.stripParameters(inlineid)
      }

      // v= or vi=
      const parameterv = /v=|vi=/g
      if (parameterv.test(str)) {
        const arr = str.split(parameterv)
        return arr[1].split('&')[0]
      }

      // v= or vi=
      const parameterwebp = /\/an_webp\//g
      if (parameterwebp.test(str)) {
        const webp = str.split(parameterwebp)[1]
        return this.stripParameters(webp)
      }

      // embed
      const embedreg = /\/embed\//g
      if (embedreg.test(str)) {
        const embedid = str.split(embedreg)[1]
        return this.stripParameters(embedid)
      }

      // user
      const userreg = /\/user\//g
      if (userreg.test(str)) {
        const elements = str.split('/')
        return this.stripParameters(elements.pop())
      }

      // attribution_link
      const attrreg = /\/attribution_link\?.*v%3D([^%&]*)(%26|&|$)/
      if (attrreg.test(str)) {
        return str.match(attrreg)[1]
      }
    },
    stripParameters(str) {
      // Split parameters or split folder separator
      if (str.indexOf('?') > -1) {
        return str.split('?')[0]
      } else if (str.indexOf('/') > -1) {
        return str.split('/')[0]
      }
      return str
    },
  },
}
</script>
<style lang="scss" scoped>
.embed-responsive {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.embed-responsive-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style> 
