<template>
  <v-combobox :items="selectList" v-model="valueBuffer" :label="internalTitle" :error-messages="errors"></v-combobox>
</template>
<script>
import { cloneDeep, map, get } from 'lodash-es'
import { getItemName } from '../../../helper/value'
import Base from './Base.vue'
export default {
  components: {},
  extends: Base,
  data() {
    return {
      selectList: [],
      valueBuffer: null,
    }
  },
  props: {
    customList: Array,
  },
  methods: {
    updateValue() {
      this.update()
    },
    refresh() {
      this.valueBuffer = this.value
      this.setSelectedList()
    },
    async setSelectedList() {
      if (get(this.prop, 'schema.enum')) {
        this.selectList = map(get(this.prop, 'schema.enum'), (value, index) => {
          return this.titles[index] || value
        })
      } else if (get(this.prop, 'schema.options.relation')) {
        const data = await this.$store.dispatch('crud/importResourceData', {
          type: get(this.prop, 'schema.options.relation'),
          returnData: true,
        })
        if (data) {
          this.selectList = data.map(item => {
            return getItemName(item)
          })
        }
      } else if (this.customList) {
        this.selectList = await cloneDeep(this.customList)
      }
    },
  },
  mounted() {
    this.refresh()
  },
  watch: {
    customList: {
      handler: function(val, oldVal) {
        this.refresh()
      },
      deep: true,
    },
    valueBuffer(value) {
      this.update(value)
    },
  },
}
</script>

