<template>
  <v-card class="array">
    <v-card-title primary-title>
      <div class="headline">{{ internalTitle }}</div>
    </v-card-title>
      <v-expansion-panels v-model="prop.open" variant="inset">
        <ArrayItem :interactive="interactive" :noCopy="noCopy" :noTitle="noTitleItem" :title="titleItem || getTitleFromItem(item)" :defaultOpen="false" :prop="prop" v-for="(item, index) in value" :key="index">
          <slot :prop="item" :index="index"></slot>
        </ArrayItem>
      </v-expansion-panels>
    <v-card-text v-if="errors.length > 0">
      <v-alert :value="true" outlined color="error" icon="warning">{{ errors[0] }}</v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon @click="add">
        <v-icon>add_box</v-icon>
      </v-btn>
      <!--v-btn icon @click="removeAll">
          <v-icon>delete_forever</v-icon>
        </v-btn-->
    </v-card-actions>
  </v-card>
</template>
<script>
import Base from './Base.vue'
import draggable from 'vuedraggable'
import ArrayItem from './ArrayItem.vue'

export default {
  components: {
    draggable,
    ArrayItem,
  },
  extends: Base,
  props: {
    interactive: {
      type: String,
      default: 'none',
    },
    noCopy: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    noTitleItem: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    titleItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: true,
    }
  },
  methods: {
    add() {
      this.prop.addEnd()
    },
    removeAll() {
      this.prop.removeAll()
    },
    refresh() {},
    getTitleFromItem(item) {
      if(!!item.value && item.value.id) {
        return item.value.id.value
      }
    }
  },
  watch: {
    value() {
      this.$nextTick(() => {
        const open = this.prop.open
        this.prop.open = null
        this.$nextTick(() => {
          this.prop.open = open
        })
      })
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.array {
  margin: 16px 0;
    :deep(.expansion-panel--inset .expansion-panel__container,
    .expansion-panel--popout .expansion-panel__container) {
      max-width: 98%;
    }
    :deep(.expansion-panel--inset .expansion-panel__container--active) {
      max-width: 94%;
    }
}
</style>
