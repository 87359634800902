<template>
  <div class="imagelinkbox">
    <div
      v-if="value"
      class="preview"
    >
      <img
        class="preview-image"
        :src="value"
      />
    </div>
    <v-text-field
      autocomplete="off"
      :readonly="readonly"
      :error-messages="errors"
      :label="internalTitle"
      :value="value"
      ref="formField"
      @input="update"
    ></v-text-field>
  </div>
</template>
<script>
import Base from './Base.vue'
export default {
  extends: Base,
}
</script>
<style lang="scss" scoped>
.preview {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;

  .preview-image {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style> 
