import store from '../stores'
import { getStage } from './http'

const domain = getStage() === 'production'
  ? 'https://aida.de/'
  : 'https://stage.aida.de/'

function getPreviewLink(resource, item, draftId) {

  const idToken = store.getters['auth/idToken']
  const randomHash = `cacheBuster=${Date.now()}`
  const draft = draftId ? `draft=${draftId}` : ''
  const previewParameter = `preview=${idToken}&${draft}&${randomHash}`

  if (draftId) {
    if (!item.draft) return false
    return getPath(resource, item.draft, previewParameter)
  }

  if (item.data)
    return getPath(resource, item.data, previewParameter)

  return getPath(resource, item, previewParameter)
}

function getPath(resource, item, previewParameter) {
  let path = null
  switch (resource) {
    case 'vessels':
    case 'ships':
      if (!item.name) return false
      path = `kreuzfahrt/schiffe/${item.name.toLowerCase()}?${previewParameter}`
      break
    case 'content-hub-articles':
      path = `entdecken/a/${item.id}?${previewParameter}`
      break
    case 'ports':
      path = `hafen/${item.slug}?${previewParameter}`
      break
    case 'cruises':
      path = `finden/${item.id}/PREMIUM?${previewParameter}`
      break
    case 'campaign-lists':
      path = `kreuzfahrt/angebote/${item.id}?${previewParameter}`
      break
    case 'news':
      path = `kreuzfahrt/schiffe/${item.category}/news/${item.id}?${previewParameter}`
      break
    default:
      break
  }

  if (path) {
    return domain + path
  } else {
    return false
  }
}

export { getPreviewLink }
