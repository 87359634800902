<template>
  <v-slider
    :error-messages="errors"
    :label="internalTitle" 
    v-model="valueBuffer" 
    :thumb-size="24"
    thumb-label="always"
    :min="min"
    :thumb-color="thumbColor"
    :color="color"
    :max="max"
    :step="step"
  ></v-slider>
</template>
<script>
import Base from './Base.vue'
export default {
  data() {
    return {
      valueBuffer: null,
    }
  },
  props: {
    format: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: 1,
    },
    thumbColor: {
      type: String,
      default: 'blue',
    },
    color: {
      type: String,
      default: 'blue',
    },
  },
  watch: {
    valueBuffer(value) {
      this.update(value)
    },
  },
  methods: {
    refresh() {
      this.valueBuffer = this.value
    },
  },
  created() {
    this.refresh()
  },
  extends: Base,
}
</script>
