<template>
  <v-autocomplete
    :placeholder="placeholder"
    no-data-text="Keine Einträge gefunden"
    :items="selectList"
    v-model="selectedItem"
    :label="internalTitle"
    item-value="value"
    item-text="text"
    @input="updateValue"
    :error-messages="errors"
  ></v-autocomplete>
</template>
<script>
import { map, get } from 'lodash-es'
import { getItemName } from '../../../helper/value'
import Base from './Base.vue'
export default {
  components: {},
  extends: Base,
  data() {
    return {
      selectedItem: null,
      selectList: [],
    }
  },
  props: {
    placeholder: {
      type: String,
      default: 'Auswahl',
    },
    customList: Array,
    allowNull: false,
  },
  computed: {
    titles() {
      return get(this.prop, 'schema.options.enum_titles') || []
    },
  },
  methods: {
    updateValue() {
      this.update(this.selectedItem)
    },
    async refresh() {
      await this.setSelectedList()
      if (this.allowNull) {
        this.selectList.unshift({
          value: '',
          text: 'Keine Auswahl',
        })
      }
      this.selectedItem = this.allowNull && !this.value ? '' : this.value
    },
    async setSelectedList() {
      if (get(this.prop, 'schema.enum')) {
        this.selectList = map(get(this.prop, 'schema.enum'), (value, index) => {
          return {
            value: value,
            text: this.titles[index] || value,
          }
        })
      } else if (get(this.prop, 'schema.options.relation')) {
        const data = await this.$store.dispatch('crud/importResourceData', {
          type: get(this.prop, 'schema.options.relation'),
          returnData: true,
        })
        if (data) {
          this.selectList = data.map(item => {
            return {
              value: item.id,
              text: getItemName(item),
            }
          })
        }
      } else if (this.customList) {
        const list = await this.customList
        this.selectList = list.map(item => {
          return {
            value: item.value,
            text: item.text,
          }
        })
      }
    },
  },
  mounted() {
    this.refresh()
  },
  watch: {
    customList: {
      handler: function(val, oldVal) {
        this.refresh()
      },
      deep: true,
    },
  },
}
</script>

