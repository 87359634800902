<template>
      <v-card>
        <v-card-title class="headline mb-0">{{ title }}</v-card-title>
        <v-card-text class="grey lighten-4"><slot></slot></v-card-text>
      </v-card>
</template>
<script>
export default {
  data() {
    return {
      active: false,
    }
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  created() {
    this._isCollapseItem = true
    this.active = this.selected
    if (!this.collapsible) this.active = true
  },
  ready() {
    if (this.active) {
      this.$emit('collapse-open', this.index)
    }
  },
  methods: {
    toggle() {
      if (!this.collapsible) return (this.active = true)
      this.active = !this.active
      if (this.active) {
        this.$emit('collapse-open', this.index)
      }
    },
  },
}
</script>
<style scoped lang="scss">
.collapse-component {
  clear: both;
  margin: 30px 0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  padding: 15px;

  .collapse-header {
    font-size: 24px;
    text-align: left;
    line-height: 32px;
    cursor: pointer;
    position: relative;
    margin-top: -15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
    padding: 15px;

    &:after {
      content: '';
      position: absolute;
      height: 62px;
      width: 62px;
      right: 0;
      top: 0;
      background-image: url('/icons/CarretDownIcon.svg');
      background-size: 35%;
      background-repeat: no-repeat;
      background-position: center center;
      transition: all 0.5s;
    }
  }

  .collapse-content {
    transition: max-height 0.25s linear;
    overflow: hidden;
    height: 0;
  }

  &.is-active {
    .collapse-header {
      &:after {
        transform: rotate(180deg);
      }
    }

    .collapse-content {
      height: auto;
    }
  }

  &.not-collapsible {
    .collapse-header {
      cursor: default;
      &:after {
        background-image: none;
      }
    }
  }
}
</style>
