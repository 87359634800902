<template>
  <div class="textbox input-group input-group--dirty input-group--text-field">
    <label :class="{'has-error': errors.length > 0}">{{ internalTitle }}</label>
    <textarea
      :name="_uid"
      ref="formField"
      :value="markdown"
    ></textarea>
    <div
      v-if="errors.length > 0"
      class="input-group__details"
    >
      <div class="input-group__messages input-group__error">{{ errors[0] }}</div>
    </div>
  </div>
</template>
<script>
import MarkdownIt from 'markdown-it'
import TurndownService from 'turndown'
import Base from './Base.vue'
import { get } from 'lodash-es'

export default {
  props: ['textOnly'], // trigger - to reset value in ckeditor - if textbox is in array - trigger could be the array
  data() {
    return {
      writeTimeout: null,
      propHtml: null,
    }
  },
  extends: Base,
  computed: {
    markdown() {
      const md = new MarkdownIt()
      this.getPropHtml()

      if (this.propHtml) {
        return this.propHtml.value
          ? this.propHtml.value
          : this.value
          ? md.render(this.value)
          : ''
      }

      return this.value
        ? md.render(this.value)
        : ''
    },
  },
  methods: {
    updateMarkdown(value) {
      this.updateHtml(value)
      const turndownService = new TurndownService()
      value = turndownService.turndown(value)
      this.update(value)
    },
    updateHtml(value) {
      if (this.propHtml) this.propHtml.value = value
    },
    refresh() {
      const name = this.$refs.formField.name
      if (CKEDITOR.instances[name]) {
        CKEDITOR.instances[name].setData(this.markdown)
      }
      this.getPropHtml()
    },
    getPropHtml() {
      const parent = this.prop.parent()
      if (parent && get(parent, 'schema.options.exportHtmlTo')) {
        this.propHtml = parent.parent().value[
          get(parent, 'schema.options.exportHtmlTo')
        ].value[this.prop.key]
      } else if (this.prop && get(this.prop, 'schema.options.exportHtmlTo')) {
        this.propHtml = this.prop.parent().value[
          get(this.prop, 'schema.options.exportHtmlTo')
        ]
      } else {
        this.propHtml = null
      }
    },
    destroyEditor() {
      if (this.$refs.formField) {
        const name = this.$refs.formField.name
        if (CKEDITOR.instances[name]) {
          CKEDITOR.instances[name].destroy()
        }
      }
    },
  },
  mounted() {
    const that = this
    const name = this.$refs.formField.name
    this.getPropHtml()
    this.destroyEditor()
    CKEDITOR.plugins.addExternal('divarea', '/', 'ckeditorDivarea.js')
    const ckeditor = CKEDITOR.replace(name, {
      customConfig: '/ckeditorConfig.js',
      extraPlugins: 'divarea',
      removePlugins: 'contextmenu,tabletools,tableselection',
    })
    ckeditor.on('change', function() {
      if (that.writeTimeout) clearTimeout(that.writeTimeout)
      that.writeTimeout = setTimeout(() => {
        that.updateMarkdown(ckeditor.getData())
      }, 100)
    })
  },
  beforeDestroy() {
    this.destroyEditor()
  },
}
</script>
<style lang="scss" scoped>
.input-group__details {
  .input-group__messages.input-group__error {
    color: #ff5252 !important;
  }
  &::before {
    height: 0;
  }
}

label.has-error {
  color: #ff5252 !important;
}

.textbox {
  padding: 15px;
  width: 100%;
  background-color: #fafaff;
  border-radius: 10px;
    :deep(.cke_chrome) {
      border-color: transparent;
      width: 100%;
    }

    :deep(.cke_inner,
    .cke_wysiwyg_frame,
    .cke_wysiwyg_div) {
      background: transparent;
    }

    :deep(.cke_top) {
      border-color: transparent;
      background: transparent;
      padding: 0 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.47);
      margin-top: -1px;
    }

    :deep(.cke_wysiwyg_div.cke_editor) {
      padding: 15px 0 0;
      font-size: 14px;
      line-height: 20px;
    }

    :deep(.cke_contents) {
      height: auto !important;
      min-height: 50px;
      max-height: 400px;
      padding-bottom: 1px;
      overflow: scroll;
    }

    :deep(.cke_editor.cke_editable) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.47);

      &:hover {
        border-bottom: 2px solid rgba(0, 0, 0, 0.6);
        margin-bottom: -1px;
      }

      &:focus,
      &:focus:hover {
        border-bottom: 2px solid #3399cc;
        margin-bottom: -1px;
        outline: none;
      }

      &[readonly] {
        color: rgba(0, 0, 0, 0.38);
        border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
      }
    }

    :deep(a.cke_button) {
      cursor: pointer;
    }

    :deep(a.cke_button_off:hover,
    a.cke_button_off:focus,
    a.cke_button_off:active) {
      background: transparent;
      border-color: transparent;
      border-bottom: 1px #3399cc solid;
      padding: 3px 5px;
    }

    :deep(.cke_combo_on a.cke_combo_button,
    .cke_combo_off a.cke_combo_button:hover,
    .cke_combo_off a.cke_combo_button:focus,
    .cke_combo_off a.cke_combo_button:active) {
      background: transparent;
      border: 1px solid transparent;
      padding: 0 0 0 1px;
      margin-left: -1px;
    }

    :deep(.cke_bottom) {
      display: none;
    }
}
</style>
