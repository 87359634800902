// imports
import * as promise from 'es6-promise'
promise.polyfill()
import {
  buildDomain,
  buildHeaders,
  objToQuery,
} from './http'
import { trimStart, endsWith } from 'lodash-es'

/**
 * HTTP-GET data from the given bea-backend path, without a leading `/`
 */
export async function get(path, params) {
  const domain = buildDomain()
  const headers = await buildHeaders()
  const url = domain + createUrl(path, params)
  const result = await fetch(url, { headers })
  const json = await result.json()
  if (json.data !== null && json.data !== undefined) {
    return json.data
  } else {
    return json
  }
}

export async function getVisibleProps(path) {
  const domain = buildDomain()
  const headers = await buildHeaders()
  const url = domain + createUrl(path + '/visible-props')
  const result = await fetch(url, { headers })
  const json = await result.json()
  if (json.data !== null && json.data !== undefined) {
    return json.data
  } else {
    return json
  }
}

/**
 * HTTP-GET json data from an absolute url with optional GET-parameters
 */
export async function getExternal(path, params) {
  const url = createUrl(path, params)
  const result = await fetch(url)
  return await result.json()
}

/**
 * similar to `get()`, but also sends JSON data as HTTP-POST body
 */
export async function post(path, data, absUrl = false) {
  const domain = !absUrl ? buildDomain() : ''
  const headers = await buildHeaders()
  const result = await fetch(domain + path, {
    body: JSON.stringify(data),
    headers,
    method: 'POST',
  })
  const json = await result.json()
  if (json.data !== null && json.data !== undefined) {
    return json.data
  } else {
    return json
  }
}

/**
 * similar to `get()`, but also sends JSON data as HTTP-POST body
 */
export async function postFile(
  bucket,
  { resource, itemId, sortingStablePath },
  data
) {
  const signedUrlParams = {
    bucket: bucket,
    filePath: data.url,
    resource: resource,
    resourceItemId: itemId,
    resourceItemPath: sortingStablePath.join('.'),
  }
  if (data.file.type) {
    signedUrlParams.contentType = data.file.type
  } else {
    if (endsWith(data.url, 'woff2')) {
      signedUrlParams.contentType = 'font/woff2'
    }
  }
  const signedUrl = await post(
    buildDomain() + 'assets/tokens/upload',
    signedUrlParams,
    true
  )

  var request = new XMLHttpRequest()
  request.open('PUT', signedUrl.url)
  request.send(data.file)
}

export async function copyFile(bucket, oldKey, newKey) {
  const response = await post(
    buildDomain() + 'assets/copy',
    {
      bucket: bucket,
      source: oldKey,
      destination: newKey,
    },
    true
  )
}

export async function deleteFile(bucket, fileUrl) {
  let filePath
  try {
    const url = new URL(fileUrl)
    filePath = trimStart(url.pathname, '/')
  } catch (error) {
    console.error('URL is not a URL (deleteFile)', fileUrl, error)
    filePath = fileUrl
  }
  const signedUrl = await post(
    buildDomain() + 'assets/tokens/delete',
    {
      Bucket: bucket,
      Key: filePath,
    },
    true
  )
  var request = new XMLHttpRequest()
  request.open('DELETE', signedUrl.url)
  request.send()
}

/*
 * similar to `post()`, but with PUT
 */
export async function put(path, newItem, isDraft = false) {
  const oldItem = $store.getters['crud/resourceItem'].data
  const domain = buildDomain()
  const headers = await buildHeaders()
  const result = await fetch(domain + path, {
    body: JSON.stringify({
      newItem,
      oldItem,
      isDraft,
    }),
    headers,
    method: 'PUT',
  })

  const json = await result.json()
  if (json.data !== null && json.data !== undefined) {
    return json.data
  } else {
    return json
  }
}

/*
 * similar to `get()`, but with DELETE oepration and no params
 */
export async function del(path, params) {
  const domain = buildDomain()
  const headers = await buildHeaders()
  const url = domain + createUrl(path, params)
  const result = await fetch(url, { headers, method: 'DELETE' })
  const json = await result.json()
  if (json.data !== null && json.data !== undefined) {
    return json.data
  } else {
    return json
  }
}

/**
 * just build an fully qualified URL with GET-Parameters
 */
export function createUrl(url, params) {
  const query = params !== undefined ? objToQuery(params) : ''
  return url + query
}
