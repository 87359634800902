<template>
  <div class="color">
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="palette"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="svg-inline--fa fa-palette fa-w-16 fa-3x"
    >
      <path
        :fill="color"
        d="M204.3 5C104.9 24.4 24.8 104.3 5.2 203.4c-37 187 131.7 326.4 258.8 306.7 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.5 97.1 368.1-26.9 204.3 5zM96 320c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128-64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"
        class
      />
    </svg>
    <v-text-field
      autocomplete="off"
      :readonly="readonly"
      :error-messages="errors"
      :label="internalTitle"
      type="text"
      :value="value"
      ref="formField"
      @input="updateValue"
    ></v-text-field>
  </div>
</template>
<script>
import Base from './Base.vue'
export default {
  props: {
    format: {
      type: String,
      default: null,
    },
  },
  computed: {
    color() {
      return this.value
    },
  },
  methods: {
    updateValue(value) {
      this.update(value)
    },
  },
  extends: Base,
}
</script>
<style>
.color {
  padding-left: 35px;
  position: relative;
}
.svg-inline--fa {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
  top: 5px;
}
</style>
