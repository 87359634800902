<template>
  <div class="editor-container">
    <v-container grid-list-md>
      <AutoForm wrapClass="xs12" :language="language" :data="editorData"></AutoForm>
    </v-container>
  </div>
</template>

<script>
import {
  cloneDeep,
  forEach,
  get,
  find,
  isObject,
  concat,
  isInteger,
  isEqual,
} from 'lodash-es'
import { validate } from 'jsonschema'

import {
  connectItemAndSchema,
  disconnectItemAndSchema,
} from '../../helper/schema'

import AutoForm from './AutoForm.vue'
import CheckBox from './formComponents/CheckBox.vue'
import CheckBoxList from './formComponents/CheckBoxList.vue'
import Color from './formComponents/Color.vue'
import ComboBox from './formComponents/ComboBox.vue'
import Wrapper from './formComponents/Wrapper.vue'
import SelectBox from './formComponents/SelectBox.vue'
import ImageLinkBox from './formComponents/ImageLinkBox.vue'
import MultiComboBox from './formComponents/MultiComboBox.vue'
import VideoLinkBox from './formComponents/VideoLinkBox.vue'
import DateBox from './formComponents/DateBox.vue'
import DateTimeBox from './formComponents/DateTimeBox.vue'
import FileBox from './formComponents/FileBox.vue'
import Collapse from './formComponents/Collapse.vue'
import Slider from './formComponents/Slider.vue'
import Tabs from './formComponents/Tabs.vue'
import Tab from './formComponents/Tab.vue'
import TextBox from './formComponents/TextBox.vue'
import TextAreaBox from './formComponents/TextAreaBox.vue'
import InputBox from './formComponents/InputBox.vue'
import Array from './formComponents/Array.vue'
import { schemaToItem, updateItemFromSchema } from '../../helper/schema'

export default {
  components: {
    AutoForm,
    CheckBox,
    CheckBoxList,
    Color,
    ComboBox,
    SelectBox,
    ImageLinkBox,
    VideoLinkBox,
    DateBox,
    DateTimeBox,
    FileBox,
    Collapse,
    MultiComboBox,
    Slider,
    Tabs,
    Tab,
    TextBox,
    TextAreaBox,
    InputBox,
    Array,
    Wrapper,
  },
  props: {
    name: String,
    id: String | Number,
    resource: String,
    draftId: String | undefined,
    draftMode: Boolean,
  },

  data() {
    return {
      isSaving: false,
      internalLanguage: null,
      isForm: true,
      editorData: null,
      snackbar: false,
      errors: [],
      originData: null,
    }
  },
  computed: {
    resourceSchema() {
      return this.$store.getters['crud/resourceSchema']
    },
    resolvedSchema() {
      return this.$store.getters['crud/resolvedSchema']
    },
    item() {
      return this.$store.getters['crud/resourceItem'].data
    },
    draft() {
      return this.$store.getters['crud/resourceItem'].draft
    },
    isCreatingMode() {
      return !this.id
    },
    language() {
      return this.internalLanguage
        ? this.internalLanguage
        : this.$store.getters['settings/formLanguage']
    },
  },
  watch: {
    id() {
      this.setEditorData()
    },
    draftId() {
      this.setEditorData(true)
    },
  },
  async created() {
    await this.setEditorData()
  },
  async mounted() {},
  methods: {
    async save(isDraft = false) {
      if (this.isSaving) return
      this.$store.dispatch('messages/setPreloader', true)
      this.isSaving = true
      const response = await this.$store.dispatch('crud/saveItem', {
        item: this.getEditorData(),
        type: this.resource,
        isNew: this.isCreatingMode,
        isDraft,
      })
      if (response && response.success) {
        const entity = isDraft ? 'Entwurf' : 'Item'
        this.$store.dispatch(
          'messages/setSuccess',
          `${entity} erfolgreich gespeichert!`
        )
        let params = { resource: this.resource, id: response.data.id }
        let name = 'resourceUpdate'
        if (isDraft) {
          params = {
            resource: this.resource,
            id: response.data.id,
            draftId: response.data.draftId,
          }
          name = 'draftUpdate'
        }

        if (!isEqual(this.$route.params, params))
          this.$router.push({ name, params })

        await this.setEditorData()
      } else {
        this.$store.dispatch('messages/setError', response.error)
      }
      this.$store.dispatch('messages/setPreloader', false)
      this.isSaving = false
    },
    cancel() {
      if (this.isSaving) return
      this.$store.dispatch('crud/removeCurrentItem')
      return true
    },
    itemHasChanges() {
      return !isEqual(this.getEditorData(), this.originData)
    },
    translate(value) {
      return value && typeof value[this.language] != 'undefined'
        ? value[this.language]
        : value
    },
    validateEditorData() {
      const editorData = this.editorData
      const validation = validate(this.getEditorData(), this.resourceSchema)
      this.setErrors(editorData.value, validation.errors)

      return validation
    },
    setErrors(data, errors, path, verbosePath) {
      if (!path) verbosePath = []
      if (!path) path = []
      if (!isObject(data)) return
      forEach(data, (o, key) => {
        const currentPath = concat(path, key)
        const currentVerbosePath = concat(
          verbosePath,
          isInteger(key) ? 'Element ' + (key + 1) : o.schema.title || key
        )
        const error = find(errors, error => {
          return (
            error.property.replace(/\[/g, '.').replace(/\]/g, '') ===
            'instance.' + currentPath.join('.')
          )
        })
        o.error = error ? error.message : ''
        if (error) error.verbosePath = currentVerbosePath.join(' > ')
        this.setErrors(o.value, errors, currentPath, currentVerbosePath)
      })
    },
    getEditorData() {
      return disconnectItemAndSchema(this.editorData)
    },
    async setEditorData(loadFromDb = false) {
      if (loadFromDb) {
        await this.$store.dispatch('crud/importItem', {
          resource: this.resource,
          id: this.id,
        })
      }
      const item = this.draftId && this.draft ? this.draft.item : this.item
      this.editorData = connectItemAndSchema(
        this.isCreatingMode
          ? schemaToItem(this.resourceSchema)
          : updateItemFromSchema(item, this.resourceSchema),
        this.resourceSchema
      )
      this.originData = cloneDeep(this.getEditorData())
    },
    enumTitle(item) {
      const enums = get(item, 'schema.enum')
      const enumTitles = get(item, 'schema.options.enum_titles')
      const value = get(item, 'value')

      if (!(enums && enumTitles && value)) {
        return
      } else {
        let index = enums.indexOf(value)
        return enumTitles[index]
      }
    },
  },
}
</script>