import {
  filter,
  get,
  concat,
  map,
  isString,
  toString,
  first,
  set,
  tail,
  forEach,
} from 'lodash-es'
import {
  getSchemaPathsForDefinition,
  schemaAssetReadingConfigs,
} from './schema'
import { chain } from './chain'

// generates a random alphanumeric case sensitive string
const randomId = function(length, caseSensitive) {
  caseSensitive = caseSensitive || true
  let id = ''
  let range = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  if (caseSensitive) {
    range = 'abcdefghijklmnopqrstuvwxyz0123456789'
  }
  for (var i = 0; i < length; i++) {
    id += range.charAt(Math.floor(Math.random() * range.length))
  }
  return id
}

// iterate on path, account for arrays, create random uids if not existing for array items along the way
// at end of path iteration create a list of tuples (path (cafees.1.images.3.file), a stable access path for the later image url cafees.23fD.images.DF42.file)
function expandSchemaPathIntoConcretePaths(path, element) {
  const openPathPart = function(
    remainingPath,
    currentElem,
    sortStablePath,
    currentValidAccessPath
  ) {
    if (!remainingPath.length) {
      return [
        {
          accessPath: currentValidAccessPath,
          sortingStablePath: sortStablePath,
        },
      ]
    }
    const pathPart = first(remainingPath)
    const pathTail = tail(remainingPath)
    if (pathPart == '[]') {
      let paths = []
      forEach(currentElem, (item, index) => {
        if (!item.uid) {
          item.uid = randomId(4)
        }
        paths = concat(
          paths,
          openPathPart(
            pathTail,
            item,
            concat(sortStablePath, [item.uid]),
            concat(currentValidAccessPath, [toString(index)])
          )
        )
      })
      return paths
    } else {
      return openPathPart(
        pathTail,
        currentElem[pathPart],
        concat(sortStablePath, [pathPart]),
        concat(currentValidAccessPath, [pathPart])
      )
    }
  }
  return openPathPart(path, element, [], [])
}

// returns null if item empty or already an url (string) there
// returns a the in any other case
function fileForPath(path, item) {
  const file = rawFileFieldValue(path, item)
  return isString(file) ? null : file
}

function fileWillBeDeleted(path, item) {
  const file = rawFileFieldValue(path, item)
  return isString(file) && file.startsWith('DELETED')
}

function rawFileFieldValue(path, item) {
  return get(item, path)
}

function uniqueFilePath(type, path, resource, item) {
  const url =
    `${resource}` +
    `/${item.id}/` +
    `${randomId(16, false)}` +
    `-original.${type}`
  return url.toLowerCase()
}

function gatherItemFilePathInformation(item, definitionSchema, schema) {
  let itemUploadPaths = []
  const uploadProperties = getSchemaPathsForDefinition(definitionSchema, schema)
  forEach(uploadProperties, path => {
    itemUploadPaths = concat(
      itemUploadPaths,
      expandSchemaPathIntoConcretePaths(path, item)
    )
  })
  return itemUploadPaths
}

// get images to upload while filtering paths
function processFilePathInformation(item, uploadPathsInfo) {
  return filter(
    map(uploadPathsInfo, fileInfo => {
      fileInfo.file = fileForPath(fileInfo.accessPath, item)
      if (fileWillBeDeleted(fileInfo.accessPath, item)) {
        fileInfo.url = rawFileFieldValue(fileInfo.accessPath, item)
        fileInfo.file = 'DELETE'
      }
      return fileInfo
    }),
    'file'
  )
}

function processItemAndUrlsWithPathInformation(
  item,
  itemUploadPaths,
  schema,
  bucketDomainKey,
  bucketAssetType
) {
  return chain(itemUploadPaths)
    .map(fileInfo => {
      if (fileInfo.file === 'DELETE') {
        return fileInfo
      } else {
        fileInfo.url = uniqueFilePath(
          bucketAssetType,
          fileInfo.accessPath,
          schema.id,
          item
        )
        set(item, fileInfo.accessPath, fileInfo.url)
        return fileInfo
      }
    })
    .filter()
    .value()
}

function setItemUploadPaths(item, currentSchema) {
  const itemUploadPaths = {}

  forEach(schemaAssetReadingConfigs, definitionSchema => {
    const {
      definitionKey,
      bucketDomainKey,
      bucketSigningIdentifier,
      assetType,
    } = definitionSchema
    let currentItemUploadPaths = gatherItemFilePathInformation(
      item,
      definitionSchema,
      currentSchema
    )
    currentItemUploadPaths = processFilePathInformation(
      item,
      currentItemUploadPaths
    )
    currentItemUploadPaths = processItemAndUrlsWithPathInformation(
      item,
      currentItemUploadPaths,
      currentSchema,
      bucketDomainKey,
      assetType
    )
    if (!itemUploadPaths[definitionKey]) {
      itemUploadPaths[definitionKey] = []
    }
    itemUploadPaths[definitionKey] = concat(
      itemUploadPaths[definitionKey],
      currentItemUploadPaths
    )
  })

  return itemUploadPaths
}

export {
  expandSchemaPathIntoConcretePaths,
  fileForPath,
  uniqueFilePath,
  fileWillBeDeleted,
  rawFileFieldValue,
  gatherItemFilePathInformation,
  processFilePathInformation,
  processItemAndUrlsWithPathInformation,
  randomId,
  setItemUploadPaths,
}
