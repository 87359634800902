
<template>
  <v-dialog v-model="value" fullscreen hide-overlay transition="dialog-right-transition" scrollable persistent no-click-animation :retain-focus="false" content-class="update-dialog">
    <v-card tile>
      <v-app-bar flat dark :color="draftMode ? 'success' : 'primary'" max-height="64">
        <v-app-bar-title>{{ resourceSchema.title }} - {{ itemName }}</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-app-bar-title v-if="draftMode">ENTWURFSMODUS</v-app-bar-title>
        <v-app-bar-title v-else>LIVEMODUS</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="draftMode && draft && !draftId" color="blue-grey" class="white--text" @click.native="loadDraft()">
          bestehenden Entwurf laden
          <v-icon right dark>save_alt</v-icon>
        </v-btn>
        <v-btn v-if="draftMode && draftId" color="warning" class="black--text" @click.native="publishDraft()">
          Entwurf veröffentlichen
          <v-icon right dark>publish</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="validation && !validation.valid" color="error" @click="errorDialog = true" small>
          {{ validation.errors.length }} Fehler
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" dark icon @click.native="switchMode()">
              <v-icon>style</v-icon>
            </v-btn>
          </template>
          <span v-if="draftMode">Entwurfsmodus beenden</span>
          <span v-else>Entwurfsmodus starten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" dark icon @click.native="save(draftMode)">
              <v-icon>save</v-icon>
            </v-btn>
          </template>
          <span>{{draftMode ? "Entwurf Speichern": "Speichern"}}</span>
        </v-tooltip>
        <v-tooltip v-if="!(draftMode && !draftId)" bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <previewLinkIcon class="btn btn--icon theme--dark" :item="item" :draftId="draftId"></previewLinkIcon>
            </div>
          </template>
          <span>Vorschau</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon dark @click.native="backToResource">
            <v-icon>close</v-icon>
          </v-btn>
          </template>
          <span>Schließen</span>
        </v-tooltip>
      </v-app-bar>
      <v-card-text class="update-content">
        <h1>
          <router-link v-if="!isCreatingMode && item" :to="{ name: 'resourceView', params: { id: item.id }}">
            <i title="Item anschauen" class="fa fa-list-ul fa-lg" aria-hidden="true"></i>
          </router-link>
        </h1>
        <form autocomplete="off">
          <component v-if="resource" ref="resourceForm" :name="name" :id="id" :resource="resource" :is="type" :draftId="draftId" :draftMode="draftMode" :isLeaving="leaving"></component>
        </form>
      </v-card-text>
      <div style="flex: 1 1 auto;"></div>
      <v-dialog v-model="abortDialog" persistent max-width="330">
        <v-card>
          <v-card-title class="headline">Schließen</v-card-title>
          <v-card-text>Du hast ungespeicherte Änderungen. Wirklich schließen?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click.native="leave()">Schließen</v-btn>
            <v-btn color="primary" @click.native="abortDialog = false;">Weiter bearbeiten</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="validation && !validation.valid" v-model="errorDialog" max-width="330" scrollable content-class="error-dialog">
        <v-card color="error">
          <v-card-title>
            <span class="headline">Fehler</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="errorDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text style="padding:0;">
            <v-list two-line>
              <template v-for="(error, key) in validation.errors">
                <v-list-item color="error" :key="key">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{ error.verbosePath }}</span>
                        </template>
                        <span>{{ error.verbosePath }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <span v-on="on" flat>{{ error.message }}</span>
                        </template>
                        <span>{{ error.message }}</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="key" inset></v-divider>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  isEmpty,
  isFunction,
  isUndefined,
} from 'lodash-es'
import { getItemName } from '../helper/value'
import previewLinkIcon from './subComponents/previewIcon.vue'

import Default from './forms/Default.vue'
import Ships from './forms/Ships.vue'
import Vessels from './forms/Vessels.vue'
import Ports from './forms/Ports.vue'
import Media from './forms/Media.vue'
import CampaignList from './forms/CampaignList.vue'

export default {
  props: {
    value: Boolean,
    name: String,
    id: String | Number,
    resource: String,
    isLeaving: Boolean,
    draftId: String | undefined,
    existingDraft: Object,
  },
  data() {
    return {
      previewLinkIcon: 'previewLinkIcon',
      type: 'Default',
      leaving: null,
      abortDialog: null,
      validation: null,
      errorDialog: false,
      itemName: '',
      leaveFunction: null,
      draftMode: isUndefined(this.draftId),
    }
  },
  computed: {
    resourceSchema() {
      return this.$store.getters['crud/resourceSchema']
    },
    item() {
      return this.$store.getters['crud/resourceItem']
    },
    draft() {
      const draft = this.$store.getters['crud/resourceItem'].draft
      return isEmpty(draft) ? undefined : draft
    },
    itemSchema() {
      return this.$store.getters['crud/resourceSchema']
    },
    isCreatingMode() {
      return !this.id
    },
  },
  methods: {
    backToResource() {
      this.$router.push({
        name: 'resourceList',
        params: { resource: this.resource },
      })
    },
    save(isDraft = false) {
      this.validation = this.$refs.resourceForm.validateEditorData()
      if (this.validation.valid) return this.$refs.resourceForm.save(isDraft)
    },
    cancel(leaveFunction, skipCheck = false) {
      this.leaveFunction = leaveFunction || null
      if (
        this.$refs.resourceForm &&
        this.$refs.resourceForm.itemHasChanges() &&
        !skipCheck
      ) {
        this.abortDialog = true
      } else {
        this.leave()
      }
    },
    leave() {
      this.abortDialog = false
      if (this.$refs.resourceForm) this.$refs.resourceForm.cancel()
      if (isFunction(this.leaveFunction)) return this.leaveFunction()
    },
    backToResource() {
      this.$router.push({
        name: 'resourceList',
        params: { resource: this.resource },
      })
    },
    setType() {
      switch (this.resource) {
        default:
          this.type = 'Default'
          break
        case 'ships':
          this.type = 'Ships'
          break
        case 'ports':
          this.type = 'Ports'
          break
        case 'campaign-lists':
          this.type = 'CampaignList'
          break
        case 'i18n-bea-icons-controls-and-info':
        case 'i18n-bea-icons-booking-and-travel':
        case 'i18n-bea-icons-content-hub':
        case 'i18n-bea-icons-planning':
        case 'i18n-bea-icons-transport':
        case 'i18n-bea-icons-misc':
        case 'i18n-bea-booking':
        case 'i18n-bea-planning':
          this.type = 'Media'
          break
        case 'vessels':
          this.type = 'Vessels'
          break
      }
    },
    setItemName() {
      this.itemName = getItemName(this.item)
    },
    switchMode() {
      this.draftMode = !this.draftMode
      if (!this.draftMode) {
        const params = { resource: this.resource, id: this.item.data.id }

        const name = 'resourceUpdate'
        this.$router.push({ name, params })
      }
    },
    setMode(val) {
      this.draftMode = !!val
    },
    loadDraft() {
      const params = {
        resource: this.resource,
        id: this.item.data.id,
        draftId: this.draft.id,
      }

      this.$router.push({ name: 'draftUpdate', params })
    },
    publishDraft() {
      this.save()
      this.setMode(false)
    },
  },
  watch: {
    resource() {
      this.setItemName()
      this.validation = null
      this.setType()
    },
    item() {},
    id(value) {
      if (!value) this.$store.dispatch('crud/removeCurrentItem')
      this.setItemName()
      this.validation = null
    },
  },
  created() {
    this.setType()
    this.setItemName()
    this.setMode(!isUndefined(this.draftId))
  },
  mounted() {
    this.$nextTick(function() {})
  },
  destroyed() {},
  components: {
    previewLinkIcon,
    Default,
    Ships,
    Media,
    Ports,
    CampaignList,
    Vessels
  },
}
</script>


<style lang="scss" scoped>
.v-card__text.update-content {
  padding: 0;
}

.error-dialog {
    :deep(.card__text) {
      padding: 0;
    }
}
</style>
