<template>
  <div class="datetime">
    <v-text-field @focus="showDateTimePicker" slot="activator" v-model="computedDateTimeFormatted" :label="internalTitle" prepend-icon="event" readonly :error-messages="errors"></v-text-field>
    <datetime :inputId="_uid + 'datetime'" ref="datetime" input-class="hide" type="datetime" v-model="dateTimeBuffer"></datetime>
  </div>
</template>
<script>
import Base from './Base.vue'
import moment from 'moment'
import Vue from 'vue'
import { cloneDeep } from 'lodash-es'
export default {
  extends: Base,
  computed: {
    computedDateTimeFormatted() {
      return this.formatDateTime(this.dateTimeBuffer)
    },
  },
  data() {
    return {
      dateTimeBuffer: null,
      popup: false,
    }
  },
  watch: {
    dateTimeBuffer(value) {
      if (typeof value === 'object') {
        value = moment(value).format()
      }
      this.update(value)
    },
  },
  methods: {
    refresh() {
      const d = new Date(this.value)
      this.dateTimeBuffer =
        Object.prototype.toString.call(d) === '[object Date]' &&
        !isNaN(d.getTime())
          ? this.value
          : null
    },
    formatDateTime(dateTime) {
      if (!dateTime) return null
      return moment(dateTime).format('DD.MM.YYYY HH:mm:ss')
    },
    showDateTimePicker(target) {
      this.$refs.datetime.open(target)
    },
  },
  mounted() {
    this.refresh()
  },
}
</script>

<style lang="scss" scoped>
.menuable__content__active {
  padding: 0 20px;
  background-color: white;
}

.datetime {
    :deep(.hide) {
      display: none;
    }
}
</style>