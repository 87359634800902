<template>
  <v-tabs class="tab-wrapper" dark :background-color="draftMode ? 'success' : 'primary'" show-arrows centered>
    <v-tabs-slider color="orange"></v-tabs-slider>
    <v-tab :href="'#tab-general'">Allgemein</v-tab>
    <v-tab :href="'#tab-content-sections'">Content-Bereiche</v-tab>
    <v-tab :href="'#tab-news-articles'">News-Artikel</v-tab>
    <v-tab-item id="tab-general">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Wrapper :wrapWithCard="true" title="Allgemein">
              <v-layout wrap>
                <v-flex md2>
                  <CheckBox
                    :title="editorData.value.isOnline.schema.title"
                    :prop="editorData.value.isOnline"
                  />
                </v-flex>

                <v-flex md10>
                  <InputBox
                    :title="editorData.value.name.schema.title"
                    :prop="editorData.value.name"
                  />
                </v-flex>

                <v-flex md12>
                  <InputBox
                    :title="editorData.value.code.schema.title"
                    :prop="editorData.value.code"
                  />
                </v-flex>
                <v-flex md12>
                  <CheckBox
                    :title="general.introData.value.bugcam.schema.title"
                    :prop="general.introData.value.bugcam"
                  />
                </v-flex>

                <v-flex md12>
                  <InputBox
                    :title="general.introData.value.subline.schema.title"
                    :prop="general.introData.value.subline"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>

            <Wrapper :wrapWithCard="true" title="Intro">
              <v-layout wrap>
                <v-flex md12>
                  <FileBox
                    :title="general.introData.value.heroMedia.value.image.schema.title"
                    fileType="image"
                    :prop="general.introData.value.heroMedia.value.image.value.file"
                  />
                </v-flex>

                <v-flex md12>
                  <InputBox
                    :title="general.introData.value.heroMedia.value.alt.schema.title"
                    :prop="general.introData.value.heroMedia.value.alt.value[language]"
                  />
                </v-flex>

                <v-flex md12>
                  <FileBox
                    :title="general.introData.value.heroMedia.value.video.value.mp4_mobile.schema.title"
                    :prop="general.introData.value.heroMedia.value.video.value.mp4_mobile.value.file"
                  />
                </v-flex>

                <v-flex md12>
                  <FileBox
                    :title="general.introData.value.heroMedia.value.video.value.mp4_desktop.schema.title"
                    :prop="general.introData.value.heroMedia.value.video.value.mp4_desktop.value.file"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>

            <Wrapper :wrapWithCard="true" title="Technische Daten">
              <v-layout wrap>
                <v-flex md12>
                  <InputBox :prop="general.ship.value.headlineData" />
                </v-flex>
                <v-flex md12>
                  <FileBox
                    title="SVG Schiffsquerschnitt"
                    fileType="image"
                    :prop="general.ship.value.shipSVG.value.file"
                  ></FileBox>
                </v-flex>
                <v-flex md12>
                  <InputBox
                    :title="general.ship.value.shipSVG.value.alt.schema.title"
                    :prop="general.ship.value.shipSVG.value.alt.value[language]"
                  />
                </v-flex>
                <v-flex md12>
                  <Array title :prop="general.ship.value.technicalData">
                    <template slot-scope="slotProps">
                      <div slot="header">{{ slotProps.prop.value.label.value }}</div>
                      <v-layout wrap>
                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.label.schema.title"
                            :prop="slotProps.prop.value.label"
                          />
                        </v-flex>
                        <v-flex md12>
                          <SelectBox
                            :title="slotProps.prop.value.icon.schema.title"
                            :prop="slotProps.prop.value.icon"
                          />
                        </v-flex>
                      </v-layout>
                    </template>
                  </Array>
                </v-flex>
              </v-layout>
            </Wrapper>

            <Wrapper :wrapWithCard="true" title="Highlights">
              <v-layout wrap>
                <v-flex md12>
                  <InputBox :prop="general.ship.value.headlineHighlights" />
                </v-flex>
                <v-flex md12>
                  <Array title :prop="general.ship.value.keyFigures">
                    <template slot-scope="slotProps">
                      <div slot="header">{{ slotProps.prop.value.label.value }}</div>
                      <v-layout wrap>
                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.label.schema.title"
                            :prop="slotProps.prop.value.label"
                          />
                        </v-flex>
                        <v-flex md12>
                          <InputBox :prop="slotProps.prop.value.quantity" />
                        </v-flex>

                        <v-flex md12>
                          <SelectBox
                            :title="slotProps.prop.value.icon.schema.title"
                            :prop="slotProps.prop.value.icon"
                          />
                        </v-flex>
                      </v-layout>
                    </template>
                  </Array>
                </v-flex>
              </v-layout>
            </Wrapper>

            <Wrapper :wrapWithCard="true" title="Mediathek">
              <v-layout wrap>
                <v-flex md12>
                  <Array title :prop="general.ship.value.links">
                    <template slot-scope="slotProps">
                      <div slot="header">{{ slotProps.prop.value.label.value }}</div>
                      <v-layout wrap>
                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.label.schema.title"
                            :prop="slotProps.prop.value.label"
                          />
                        </v-flex>
                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.subLabel.schema.title"
                            :prop="slotProps.prop.value.subLabel"
                          />
                        </v-flex>

                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.link.schema.title"
                            :prop="slotProps.prop.value.link"
                          />
                        </v-flex>

                        <v-flex md12>
                          <FileBox
                            :hq="true"
                            :title="slotProps.prop.value.document.schema.title"
                            fileType="pdf"
                            :prop="slotProps.prop.value.document.value.file"
                          />
                        </v-flex>

                        <v-flex md12>
                          <SelectBox
                            :title="slotProps.prop.value.icon.schema.title"
                            :prop="slotProps.prop.value.icon"
                          />
                        </v-flex>
                      </v-layout>
                    </template>
                  </Array>
                </v-flex>
              </v-layout>
            </Wrapper>
            <Wrapper :wrapWithCard="true" title="Zielgebiete">
              <v-layout wrap>
                <v-flex md12>
                  <InputBox
                    :title="general.targetRegions.value.headline.schema.title"
                    :prop="general.targetRegions.value.headline"
                  />
                </v-flex>

                <v-flex md12>
                  <InputBox
                    :title="general.targetRegions.value.subHeadline.schema.title"
                    :prop="general.targetRegions.value.subHeadline"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
            <Wrapper :wrapWithCard="true" title="SEO-Daten">
              <v-layout wrap>
                <v-flex md12>
                  <InputBox
                    :title="general.seoData.value.metaTitle.schema.title"
                    :prop="general.seoData.value.metaTitle"
                  />
                </v-flex>

                <v-flex md12>
                  <InputBox
                    :title="general.seoData.value.metaDescription.schema.title"
                    :prop="general.seoData.value.metaDescription"
                  />
                </v-flex>

                <v-flex md12>
                  <SelectBox
                    :title="general.seoData.value.robotsTag.schema.title"
                    :prop="general.seoData.value.robotsTag"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
            <Wrapper :wrapWithCard="true" :title="general.loungeConfiguration.schema.title">
              <v-layout wrap>
                <v-flex md2>
                  <CheckBox :prop="general.loungeConfiguration.value.active" />
                </v-flex>
                <v-flex md10>
                  <InputBox
                    :prop="general.loungeConfiguration.value.limit"
                    :title="general.loungeConfiguration.value.limit.schema.title"
                  />
                </v-flex>

                <v-flex md12>
                  <SelectBox
                    :prop="general.loungeConfiguration.value.ship"
                    :title="general.loungeConfiguration.value.ship.schema.title"
                  />
                </v-flex>

                <v-flex md12>
                  <InputBox
                    :prop="general.loungeConfiguration.value.locationName"
                    :title="general.loungeConfiguration.value.locationName.schema.title"
                  />
                </v-flex>

                <v-flex md12>
                  <InputBox
                    :prop="general.loungeConfiguration.value.maxAgeDays"
                    :title="general.loungeConfiguration.value.maxAgeDays.schema.title"
                  />
                </v-flex>

                <v-flex md12>
                  <MultiComboBox
                    :multiSelect="false"
                    :customList="userContentCategories"
                    :prop="general.loungeConfiguration.value.categories"
                  />
                </v-flex>

                <v-flex md6>
                  <InputBox :prop="general.loungeConfiguration.value.position.value.latitude" />
                </v-flex>

                <v-flex md6>
                  <InputBox :prop="general.loungeConfiguration.value.position.value.longitude" />
                </v-flex>

                <v-flex
                  md12
                  v-if="general.loungeConfiguration.value.position.value.longitude.value > 0 || general.loungeConfiguration.value.position.value.latitude.value > 0"
                >
                  <InputBox
                    :prop="general.loungeConfiguration.value.maxDistance"
                    :title="general.loungeConfiguration.value.maxDistance.schema.title"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>

    <v-tab-item id="tab-content-sections">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Wrapper :wrapWithCard="true" title="Content-Bereiche">
              <v-layout>
                <v-flex md12>
                  <Array title :prop="editorData.value.contentSections">
                    <template slot-scope="slotProps">
                      <div slot="header">{{ slotProps.prop.value.headline.value }}</div>
                      <v-layout wrap>
                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.headline.schema.title"
                            :prop="slotProps.prop.value.headline"
                          />
                        </v-flex>

                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.link.schema.title"
                            :prop="slotProps.prop.value.link"
                          />
                        </v-flex>
                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.hash.schema.title"
                            :prop="slotProps.prop.value.hash"
                          />
                        </v-flex>
                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.linkText.schema.title"
                            :prop="slotProps.prop.value.linkText"
                          />
                        </v-flex>

                        <v-flex md12>
                          <SelectBox
                            :title="slotProps.prop.value.navIcon.schema.title"
                            :prop="slotProps.prop.value.navIcon"
                          />
                        </v-flex>

                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.navLabel.schema.title"
                            :prop="slotProps.prop.value.navLabel"
                          />
                        </v-flex>

                        <v-flex md12>
                          <SelectBox
                            :title="slotProps.prop.value.layout.schema.title"
                            :prop="slotProps.prop.value.layout"
                          />
                        </v-flex>
                        <v-layout>
                          <v-flex md12>
                            <Array
                              title="Content-Beiträge"
                              :prop="slotProps.prop.value.contentElements"
                            >
                              <template slot-scope="contentElements">
                                <div slot="header">{{ contentElements.prop.value.headline.value }}</div>
                                <v-layout wrap>
                                  <v-flex md12>
                                    <InputBox
                                      :title="contentElements.prop.value.headline.schema.title"
                                      :prop="contentElements.prop.value.headline"
                                    />
                                  </v-flex>
                                  <v-flex md12>
                                    <TextBox
                                      :title="contentElements.prop.value.description.schema.title"
                                      :prop="contentElements.prop.value.description.value[language]"
                                    />
                                  </v-flex>
                                  <v-flex md12>
                                    <FileBox
                                      :title="contentElements.prop.value.media.value.image.schema.title"
                                      fileType="image"
                                      :prop="contentElements.prop.value.media.value.image.value.file"
                                    />
                                  </v-flex>
                                  <v-flex md12>
                                    <InputBox
                                      :title="contentElements.prop.value.media.value.image.value.alt.schema.title"
                                      :prop="contentElements.prop.value.media.value.image.value.alt.value[language]"
                                    />
                                  </v-flex>
                                  <v-flex md12>
                                    <FileBox
                                      :title="contentElements.prop.value.media.value.video.value.mp4_mobile.schema.title"
                                      :prop="contentElements.prop.value.media.value.video.value.mp4_mobile.value.file"
                                    />
                                  </v-flex>

                                  <v-flex md12>
                                    <FileBox
                                      :title="contentElements.prop.value.media.value.video.value.mp4_desktop.schema.title"
                                      :prop="contentElements.prop.value.media.value.video.value.mp4_desktop.value.file"
                                    />
                                  </v-flex>

                                  <v-flex md12>
                                    <InputBox
                                      :title="contentElements.prop.value.duration.schema.title"
                                      :prop="contentElements.prop.value.duration"
                                    />
                                  </v-flex>
                                </v-layout>
                              </template>
                            </Array>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </template>
                  </Array>
                </v-flex>
              </v-layout>
            </Wrapper>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>

    <v-tab-item id="tab-news-articles">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Wrapper :wrapWithCard="true" title="News-Artikel">
              <v-layout>
                <v-flex md12>
                  <Array title :prop="editorData.value.newsElements">
                    <template slot-scope="slotProps">
                      <div slot="header">{{ slotProps.prop.value.headline.value }}</div>
                      <v-layout wrap>
                        <v-flex md2>
                          <CheckBox
                            :title="slotProps.prop.value.isOnline.schema.title"
                            :prop="slotProps.prop.value.isOnline"
                          />
                        </v-flex>

                        <v-flex md10>
                          <DateBox
                            :title="slotProps.prop.value.publishingDate.schema.title"
                            :prop="slotProps.prop.value.publishingDate"
                          />
                        </v-flex>

                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.headline.schema.title"
                            :prop="slotProps.prop.value.headline"
                          />
                        </v-flex>

                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.subline.schema.title"
                            :prop="slotProps.prop.value.subline"
                          />
                        </v-flex>

                        <v-flex md12>
                          <FileBox
                            :title="slotProps.prop.value.headerImage.schema.title"
                            :prop="slotProps.prop.value.headerImage.value.file"
                          />
                        </v-flex>

                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.headerImage.value.alt.schema.title"
                            :prop="slotProps.prop.value.headerImage.value.alt.value[language]"
                          />
                        </v-flex>

                        <v-flex md12>
                          <TextBox
                            :title="slotProps.prop.value.text.schema.title"
                            :prop="slotProps.prop.value.text.value[language]"
                          />
                        </v-flex>

                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.seoTitle.schema.title"
                            :prop="slotProps.prop.value.seoTitle"
                          />
                        </v-flex>

                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.seoDescription.schema.title"
                            :prop="slotProps.prop.value.seoDescription"
                          />
                        </v-flex>

                        <v-flex md12>
                          <SelectBox
                            :title="slotProps.prop.value.robotsTag.schema.title"
                            :prop="slotProps.prop.value.robotsTag"
                          />
                        </v-flex>

                        <v-flex md12>
                          <InputBox
                            :title="slotProps.prop.value.youtubeLink.schema.title"
                            :prop="slotProps.prop.value.youtubeLink"
                          />
                        </v-flex>

                        <v-flex md12>
                          <SelectBox
                            :title="slotProps.prop.value.contentOrder.schema.title"
                            :prop="slotProps.prop.value.contentOrder"
                          />
                        </v-flex>
                      </v-layout>
                    </template>
                  </Array>
                </v-flex>
              </v-layout>
            </Wrapper>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>
<script>
import Default from './Default.vue'
import {
  concat,
  map,
} from 'lodash-es'

import { get as getList } from '../../helper/ajax'

export default {
  extends: Default,
  data() {
    return {
      regions: [],
      userContentCategories: [],
    }
  },
  computed: {
    general() {
      return this.editorData.value.general.value
    },
    contentSections() {
      return this.editorData.value.contentSections.value
    },
    ship() {
      return this.editorData.value.general.value.ship.value
    },
  },
  methods: {
    async loadRegions() {
      const regions = await getList(`regions?select=id,name`)
      this.regions = concat(
        [{ text: 'Alle', value: 'all' }],
        map(regions, o => {
          return { text: o.name.de, value: o.id }
        })
      )
    },
    async loadCategories() {
      this.userContentCategories = [
        { text: 'Nature', value: 'nature' },
        { text: 'Sight', value: 'sight' },
        { text: 'Shopping', value: 'shopping' },
        { text: 'Tour', value: 'tour' },
        { text: 'Food', value: 'food' },
        { text: 'Ship', value: 'ship' },
        { text: 'City', value: 'city' },
        { text: 'Viewpoint', value: 'viewpoint' },
      ]
    },
  },
  mounted() {
    this.loadRegions()
    this.loadCategories()
  },
}
</script>
<style lang="scss" scoped>
.v-tab {
  letter-spacing: 0em;
  padding: 0 12px;
  min-width: 40px;
}

.tab-wrapper {
    :deep(.tabs__bar) {
      position: fixed;
      top: 60px;
      z-index: 1000000;
      width: 100%;
      margin-left: -16px;
    }
}
@media (min-width: 1921px) {
  .tab-wrapper {
      :deep(.tabs__bar) {
        position: fixed;
        top: 120px;
        z-index: 1000000;
        width: calc(100% - 730px);
      }
    }
}
</style>
