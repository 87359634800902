<template>
  <div class="date">
    <v-text-field
      autocomplete="off"
      @focus="showDatePicker"
      slot="activator"
      v-model="computedDateFormatted"
      :label="internalTitle"
      prepend-icon="event"
      readonly
      :error-messages="errors"
    ></v-text-field>
    <datetime
      :inputId="_uid + 'date'"
      ref="date"
      input-class="hide"
      type="date"
      v-model="dateBuffer"
    ></datetime>
  </div>
</template>
<script>
import Base from './Base.vue'
import moment from 'moment'

export default {
  extends: Base,
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.dateBuffer)
    },
  },
  data() {
    return {
      dateBuffer: null,
      popup: false,
    }
  },
  watch: {
    dateBuffer(value) {
      this.update(moment(value).format('YYYY-MM-DD'))
    },
  },
  methods: {
    refresh() {
      const d = new Date(this.value)
      this.dateBuffer =
        Object.prototype.toString.call(d) === '[object Date]' &&
        !isNaN(d.getTime())
          ? d.toUTCString()
          : null
    },
    formatDate(date) {
      if (!date) return null
      return moment(date).format('DD.MM.YYYY')
    },
    showDatePicker(target) {
      this.$refs.date.open(target)
    },
  },
  mounted() {
    this.refresh()
  },
}
</script>
<style lang="scss" scoped>
.date {
    :deep(.hide) {
      display: none;
    }
}
</style>