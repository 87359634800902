export const sections = {
  data: 'Daten',
  'content-hub': 'Content Hub',
  offer: 'Kampagnen',
  seo: 'SEO',
  assets: 'Icons',
  i18n: 'Texte & Bilder',
  admin: 'Admin',
}

export const iconMapping = {
  data: 'storage',
  'content-hub': 'landscape',
  offer: 'local_offer',
  seo: 'format_list_numbered',
  assets: 'wb_sunny',
  i18n: 'art_track',
  admin: 'lock',
}

export const directEntries = {
  'i18n-bea-icons-booking-and-travel': 'de',
  'i18n-bea-icons-content-hub': 'de',
  'i18n-bea-icons-planning': 'de',
  'i18n-bea-icons-controls-and-info': 'de',
  'i18n-bea-icons-transport': 'de',
  'i18n-bea-icons-misc': 'de',
  'i18n-bea-booking': 'de',
  'i18n-bea-campaigns': 'de',
  'i18n-bea-planning': 'de',
  'i18n-bea-searching': 'de',
  'i18n-bea': 'de',
}

// export const nonDbSchemas = [
//   {
//     id: 'aida-lounge',
//     section: 'content-hub',
//     subSection: undefined,
//     title: 'AIDA Lounge',
//   },
// ]
