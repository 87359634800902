<template>
  <div>
    <router-link :to="'/resources/' + resourceSchema.id">
      <span class="breadcrumb-item">{{ resourceSchema.title }}</span>
    </router-link>

    <div class="overview">
      <div>
        <table v-once class="table table-striped">
          <thead>

          </thead>
          <tbody>
            <tr v-for="(definition, key) in nonHiddenProperties" :key="key">
              <td>{{ (definition.title) ? `${definition.title}:` : null }}</td>
              <td v-html="processValue(item[key], definition, key)"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { processValueByDefinition, getItemName } from '../../helper/value'
import previewLinkIcon from '../subComponents/previewIcon.vue'

export default {
  props: {
    name: String,
    id: String | Number,
    resource: String,
  },
  data() {
    return {
      showOverview: false,
      previewLinkIcon: 'previewLinkIcon',
    }
  },
  async mounted() {},
  computed: {
    resources() {
      return this.$store.getters['crud/resourceNames']
    },
    resourceSchema() {
      return this.$store.getters['crud/resourceSchema']
    },
    nonHiddenProperties() {
      const props = this.resourceSchema.properties
      let newProps = {}
      for (let key in props) {
        if (
          !(
            props[key].hasOwnProperty('options') &&
            props[key].options.hasOwnProperty('hidden') &&
            props[key].options.hidden == true
          )
        ) {
          newProps[key] = props[key]
        }
      }
      return newProps
    },
    item() {
      return this.$store.getters['crud/resourceItem'].data
    },
    itemSchema() {
      return this.$store.getters['crud/resourceSchema']
    },
    isCreatingMode() {
      return !this.id
    },
    itemName() {
      return getItemName(this.item)
    },
  },
  methods: {
    processValue(value, definition, key) {
      return processValueByDefinition(value, definition, key)
    },
  },
  components: { previewLinkIcon },
}
</script>

<style lang="sass" scoped>
table
  margin: 20px
td
  height: 2.5em
td:first-child
  text-align: right
  padding-right: 20px
.breadcrumb-item
  color: lightgrey
  font-size: larger
  text-decoration: none
</style>
