<template>
  <div class="wrapper">
    <v-card class="wrapper-card" v-if="wrapWithCard" raised>
      <v-card-title primary-title>
        <div class="headline">{{ internalTitle }}</div>
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card>
    <div v-if="!wrapWithCard">
        <slot></slot>
    </div>
  </div>
</template>
<script>
import Collapse from './Collapse.vue'
export default {
  components: {
    Collapse,
  },
  computed: {
    internalTitle() {
      return this.title || this.prop.schema.title || this.prop.key
    },
  },
  props: ['prop', 'title', 'wrapWithCard'],
}
</script>
<style lang="scss" scoped>
.wrapper-card {
  margin: 16px 0;
}
</style>
