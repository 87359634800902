
<template>
  <component v-bind:is="currentView">
  </component>
</template>

<script>
import defaultView from './views/defaultView.vue'
import shipsView from './views/shipsView.vue'

const views = {
  default: defaultView,
  ships: shipsView,
}

export default {
  props: {
    name: String,
    id: String | Number,
    resource: String,
  },
  data() {
    const resource = this.$store.getters['crud/resourceItem'].type
    return {
      currentView: views.hasOwnProperty(resource) ? resource : 'default',
      showOverview: false,
    }
  },
  beforeDestroy() {
    this.$store.dispatch('crud/removeCurrentItem')
  },
  components: views,
}
</script>
