<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <span v-html="internalTitle"></span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card>
        <v-card-text class="grey lighten-4">
          <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="actions">
            <v-btn v-if="!noCopy" icon @click.stop="copy" title="Kopieren">
              <v-icon>file_copy</v-icon>
            </v-btn>
            <v-btn v-if="!noCopy && canCut" icon @click.stop="cut" title="Ausschneiden">
              <v-icon>flip_to_back</v-icon>
            </v-btn>
            <v-btn v-if="canInsert" icon @click.stop="insertAfter" title="Danach einfügen">
              <v-icon>save_alt</v-icon>
            </v-btn>
            <v-btn icon @click.stop="addAfter" title="Danach erstellen">
              <v-icon>add_box</v-icon>
            </v-btn>
            <v-btn v-if="canUp" icon @click.stop="up" title="Nach oben verschieben">
              <v-icon>arrow_drop_up</v-icon>
            </v-btn>
            <v-btn v-if="canDown" icon @click.stop="down" title="Nach unten verschieben">
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>
            <v-btn icon @click.stop="remove" title="Löschen">
              <v-icon>delete</v-icon>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { get, forEach } from 'lodash-es'
import Base from './Base.vue'

export default {
  extends: Base,
  props: ['noCopy', 'noTitle', 'interactive', 'defaultOpen', 'layout'],
  data() {
    return {
      canCopyPaste: true,
      cacheItemBuffer: this.cacheItem,
      titleFromSlot: null,
      item: null,
      open: false,
      searchedTitle: null,
    }
  },
  computed: {
    key() {
      return this.$vnode.key
    },
    hasKey() {
      return !isNaN(this.key)
    },
    canUp() {
      return this.key > 0
    },
    canDown() {
      return this.key < this.value.length - 1
    },
    canCut() {
      return this.value.length > 0
    },
    canInsert() {
      return this.prop.getCacheItem()
    },
    internalTitle() {
      return this.searchedTitle
        ? this.searchedTitle
        : this.title || 'Element' + ' ' + (parseInt(this.key, 10) + 1)
    },
  },
  watch: {
    prop: {
      handler: function(_prop) {
        if (this.item !== this.value[this.key].origin) {
          this.refresh()
        }
      },
      deep: true,
    },
  },
  methods: {
    toggleCollapse() {
      this.open = !this.open
    },
    up() {
      this.prop.move(this.key, this.key - 1)
    },
    down() {
      this.prop.move(this.key, this.key + 1)
    },
    remove() {
      this.prop.remove(this.key)
    },
    cut() {
      this.prop.cut(this.key)
    },
    copy() {
      this.prop.copy(this.key)
    },
    insertAfter() {
      this.prop.insertAfter(this.key)
    },
    addAfter() {
      this.prop.add(this.key)
    },
    refresh() {
      this.item = this.value[this.$vnode.key]
      forEach(this.$slots.default, o => {
        if (get(o, 'data.slot') == 'header') {
          this.searchedTitle = o.children[0].text
          this.$nextTick(() => {
            if (!o.elm) return
            this.searchedTitle = o.elm.innerHTML
            o.elm.style.display = 'none'
          })
        }
      })
    },
  },
  created() {
    this.refresh()
  },
}
</script>
<style lang="scss" scoped>
.actions2 {
  position: absolute;
  top: 0px;
  right: 50px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 4%
  );
    :deep(button) {
      font-size: 12px;
      margin: 6px 0;
    }
}
</style>
