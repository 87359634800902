import localforage from 'localforage'

var LF = null
if (typeof window !== 'undefined') {
  LF = localforage.createInstance({
    name: 'zoe',
  })
}

const state = {
  formLanguage: null,
  folds: [],
}

const getters = {
  formLanguage(state) {
    return state.formLanguage
  },
  folds(state) {
    return state.folds
  },
}

const actions = {
  async import(store) {
    const lang = await LF.getItem('formLanguage')

    store.commit('IMPORT_LANGUAGE', lang || 'de')

    const folds = await LF.getItem('folds')
    if (folds) store.commit('SAVE_FOLDS', folds)
  },
  saveFormLanguage(context, language) {
    context.commit('IMPORT_LANGUAGE', language)
    LF.setItem('formLanguage', language)
  },
  saveFolds(context, folds) {
    context.commit('SAVE_FOLDS', folds)
    LF.setItem('folds', folds)
  },
}

const mutations = {
  IMPORT_LANGUAGE(state, language) {
    state.formLanguage = language
  },
  SAVE_FOLDS(state, folds) {
    state.folds = folds
  },
}

export default { state, getters, actions, mutations, namespaced: true }
