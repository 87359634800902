<template>

  <v-container fluid fill-height>
    <v-layout justify-center text-xs-center v-if="showInfo">
      <v-flex xs10 sm6 md2>
        <v-icon style="font-size: 200px; color:rgba(0,0,0,.27);">collections</v-icon>
        <p class="headline">Assets</p>
        <p class="">Wähle eine Ressource zum Bearbeiten aus</p>
      </v-flex>
    </v-layout>
    <v-layout class="content-wrapper" v-if="!showInfo">
      <router-view></router-view>
    </v-layout>
  </v-container>

</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    name: String,
  },
  mounted() {},
  computed: {
    showInfo() {
      return this.$router.app._route.name == 'resources'
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: block;
  margin: -16px;
  margin-top: -42px;
}
</style>
