<template>
  <div class="input-group input-group--dirty input-group--text-field primary--text">
    <label class="checkbox-title">{{ internalTitle }}</label>
    <v-checkbox class="checkbox" v-model="valueBuffer" :error-messages="errors">
    </v-checkbox>
  </div>
</template>
<script>
import Base from './Base.vue'
export default {
  props: {},
  data() {
    return {
      valueBuffer: null,
    }
  },
  watch: {
    valueBuffer(value) {
      this.update(value)
    },
  },
  methods: {
    refresh() {
      this.valueBuffer = !!this.value
    },
  },
  created() {
    this.refresh()
  },
  extends: Base,
}
</script>
<style lang="scss" scoped>
.checkbox {
  margin-top: 3px;
}

.checkbox-title {
  white-space: nowrap;
}
</style> 
