import root from 'window-or-global'
import Vue from 'vue'
import AppWrapper from './components/AppWrapper.vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify'
import Datetime from 'vue-datetime'
import 'vuetify/dist/vuetify.min.css'
import 'vue-datetime/dist/vue-datetime.css'
Vue.use(Vuetify)
Vue.use(Datetime)

Vue.use(VueRouter)

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md'
  }
})

import Index from './components/Index.vue'
import ResourcesIndex from './components/ResourcesIndex.vue'
import ResourcesList from './components/ResourceList.vue'
import ResourceView from './components/ResourceView.vue'
import Settings from './components/Settings.vue'
import Login from './components/Login.vue'
import moment from 'moment'

import { sync } from 'vuex-router-sync'
import { userIsAllowed } from './helper/user'
import { cloneDeep } from 'lodash-es'
import { fetchAuthSession } from 'aws-amplify/auth'
import store from './stores'

let cognitoSession = null

const routes = [
  {
    path: '/',
    component: Index,
    name: 'index',
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
  },
  {
    path: '/settings',
    component: Settings,
    name: 'settings',
  },
  {
    path: '/resources',
    component: ResourcesIndex,
    name: 'resources',
    children: [
      {
        path: ':resource',
        component: ResourcesList,
        name: 'resourceList',
        async beforeEnter(to, from, next) {
          await store.dispatch('crud/importResourceData', to.params.resource)

          next()
        },
      },
      {
        path: ':resource/new',
        component: ResourcesList,
        name: 'resourceCreate',
        props: true,
        async beforeEnter(to, from, next) {
          await store.dispatch('crud/importResourceData', to.params.resource)
          next()
        },
      },
      {
        path: ':resource/:id',
        component: ResourcesList,
        name: 'resourceUpdate',
        props: true,
        async beforeEnter(to, from, next) {
          store.dispatch('messages/setPreloader', true)
          await store.dispatch('crud/importResourceData', to.params.resource)
          await store.dispatch('crud/importItem', to.params)
          store.dispatch('messages/setPreloader', false)
          next()
        },
      },
      {
        path: ':resource/:id/draft/:draftId',
        component: ResourcesList,
        name: 'draftUpdate',
        props: true,
        async beforeEnter(to, from, next) {
          await store.dispatch('crud/importResourceData', to.params.resource)
          await store.dispatch('crud/importItem', to.params)

          next()
        },
      },
      {
        path: ':resource/:id/view',
        component: ResourceView,
        name: 'resourceView',
        props: true,
        async beforeEnter(to, from, next) {
          await store.dispatch('crud/importResourceData', to.params.resource)
          await store.dispatch('crud/importItem', to.params)
          next()
        },
      },
    ],
  },
]

const router = new VueRouter({ routes, mode: 'history' })

async function accessTokenIsExpired() {
  if (moment().unix() > cognitoSession.tokens.idToken.payload.exp) {
    return true
  }

  return false
}

function userHasPermission(to) {
  const role = store.getters['auth/role']
  const requestedResourceSchema = cloneDeep(
    store.getters['crud/schemas'].find(item => {
      return item.id === to
    })
  )

  if (!requestedResourceSchema || !role) {
    return true
  }

  return (
    role &&
    requestedResourceSchema &&
    userIsAllowed(role, requestedResourceSchema)
  )
}

async function refresh() {
  if (store.getters['auth/role'] == '') {
    await store.dispatch('auth/refresh')
  }
}

router.beforeEach(async (to, from, next) => {
  try {
    if (to.name != 'login') {
      refresh()
      cognitoSession = await fetchAuthSession()

      if (!cognitoSession.tokens) {
        window.location.href = '/login'
      }

      if (await accessTokenIsExpired()) {
        window.location.href = '/login'
      }

      setInterval(refresh, 60000)
    }
  } catch(error) {
    if (error.name == 'UserUnAuthenticatedException')
      window.location.href = '/login'
  }
  next()
})

router.afterEach((to, from) => {
  if (to.name != 'index') {
    if (!userHasPermission(to.params.resource)) {
      router.push({ name: 'index' })
      this.$store.dispatch('messages/setError', 'Fehlende Berechtigung')
    }
  }
})

sync(store, router)

root['$store'] = store
root['$router'] = router

export default new Vue({
  store,
  router,
  vuetify,
  ...AppWrapper,
})
