<template>
  <v-layout row wrap>
    <v-flex v-if="(!data.i18n && isActive(item)) || (data.i18n && item.key == language)" :class="getWrapClass()" v-for="(item, key) in values" :key="key">
      <Array interactive="collapse" v-if="getType(item) == 'Array'" :prop="item" :title="item.schema.title">
        <template slot-scope="slotProps">
          <AutoForm :wrapClasses="isObject(wrapClasses) ? wrapClasses : {}" :language="language" :parentLevel="level + 1" :data="slotProps.prop" />
        </template>
      </Array>
      <Wrapper :wrapWithCard="!item.i18n" v-if="getType(item) == 'Wrapper'" :prop="item">
        <AutoForm :wrapClasses="isObject(wrapClasses) ? wrapClasses : {}" :language="language" :parentLevel="level + 1" :data="item" />
      </Wrapper>
      <InputBox :title="data.i18n ? data.schema.title : null" :headlineClass="level == 0 ? 'headline' : ''" v-if="getType(item) == 'InputBox'" :prop="item" />
      <FileBox hq :title="data.i18n ? data.schema.title : null" v-if="getType(item) == 'FileBox'" :prop="item">
        <AutoForm :wrapClasses="isObject(wrapClasses) ? wrapClasses : {}" :language="language" :parentLevel="level + 1" :data="data" :inFileWrapper="true" />
      </FileBox>
      <TextBox :title="data.i18n ? data.schema.title : null" v-if=" getType(item) == 'TextBox'" :prop="item" />
      <TextAreaBox :title="data.i18n ? data.schema.title : null" v-if=" getType(item) == 'TextAreaBox'" :prop="item" />
      <DateBox :title="data.i18n ? data.schema.title : null" v-if="getType(item) == 'DateBox'" :prop="item" />
      <DateTimeBox :title="data.i18n ? data.schema.title : null" v-if="getType(item) == 'DateTimeBox'" :prop="item" />
      <SelectBox :title="data.i18n ? data.schema.title : null" v-if="getType(item) == 'SelectBox'" :prop="item" />
      <CheckBox :title="data.i18n ? data.schema.title : null" v-if="getType(item) == 'CheckBox'" :prop="item" />
      <VideoLinkBox :title="data.i18n ? data.schema.title : null" v-if="getType(item) == 'VideoLinkBox'" :prop="item" />
    </v-flex>
  </v-layout>
</template>
<script>
import AutoForm from './AutoForm.vue'
import SelectBox from './formComponents/SelectBox.vue'
import CheckBox from './formComponents/CheckBox.vue'
import CheckBoxList from './formComponents/CheckBoxList.vue'
import ImageLinkBox from './formComponents/ImageLinkBox.vue'
import VideoLinkBox from './formComponents/VideoLinkBox.vue'
import DateBox from './formComponents/DateBox.vue'
import DateTimeBox from './formComponents/DateTimeBox.vue'
import FileBox from './formComponents/FileBox.vue'
import Collapse from './formComponents/Collapse.vue'
import Tabs from './formComponents/Tabs.vue'
import Tab from './formComponents/Tab.vue'
import TextBox from './formComponents/TextBox.vue'
import TextAreaBox from './formComponents/TextAreaBox.vue'
import InputBox from './formComponents/InputBox.vue'
import Array from './formComponents/Array.vue'
import Wrapper from './formComponents/Wrapper.vue'

import {
  get,
  sortBy,
  findIndex,
  filter,
  isString,
  isObject,
} from 'lodash-es'

export default {
  components: {
    SelectBox,
    CheckBox,
    CheckBoxList,
    ImageLinkBox,
    VideoLinkBox,
    DateBox,
    DateTimeBox,
    FileBox,
    Collapse,
    Tabs,
    Tab,
    TextBox,
    TextAreaBox,
    InputBox,
    Array,
    Wrapper,
  },
  name: 'AutoForm',
  props: ['data', 'parentLevel', 'language', 'inFileWrapper', 'wrapClasses'],
  data() {
    return {
      level: 0,
    }
  },
  computed: {
    values() {
      const sorted = sortBy(this.data.value, o => {
        return o.schema.propertyOrder
      })
      if (this.inFileWrapper) {
        return filter(sorted, o => {
          return this.getType(o) != 'FileBox'
        })
      } else {
        const fileBoxIndex = findIndex(sorted, o => {
          return this.getType(o) == 'FileBox'
        })
        if (fileBoxIndex != -1) {
          return [sorted[fileBoxIndex]]
        }
      }
      return sorted
    },
  },
  methods: {
    getType(item) {
      const relation = get(item, 'schema.options.relation', false)
      item.type = relation ? 'select' : item.type

      switch (item.type) {
        case 'array':
          return 'Array'
          break
        case 'object':
          return 'Wrapper'
          break
        case 'octet-stream':
        case 'image':
          return 'FileBox'
          break
        case 'textarea':
          return 'TextAreaBox'
          break
        case 'markdown':
          return 'TextBox'
          break
        case 'datetime-local':
          return 'DateTimeBox'
          break
        case 'date':
          return 'DateBox'
          break
        case 'select':
          return 'SelectBox'
          break
        case 'boolean':
          return 'CheckBox'
          break
        case 'videoUrl':
          return 'VideoLinkBox'
          break
      }
      return 'InputBox'
    },
    isActive(item) {
      if (get(item.schema, 'options.hidden') == true) return false

      return true
    },
    getWrapClass() {
      return this.wrapClasses && isString(this.wrapClasses)
        ? this.wrapClasses
        : this.wrapClasses && this.wrapClasses[this.data.key]
          ? this.wrapClasses[this.data.key]
          : this.wrapClasses && this.wrapClasses['wrapperlevel' + this.level]
            ? this.wrapClasses['wrapperlevel' + this.level]
            : 'xs12'
    },
    isObject(val) {
      return isObject(val)
    },
  },
  created() {
    if (this.parentLevel) this.level = this.parentLevel
  },
}
</script>
<style lang="scss" scoped>
.container.grid-list-md .layout .flex {
  padding: 4px 4px;
}
</style>