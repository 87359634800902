<template>
    <div>
        <div class="headline checkboxHeadline">{{title}}</div>
        <CheckBox class="checkbox" :title="getName(item.value.id.value)" :prop="item.value.activated"  v-for="(item, index) in prop.value" :key="index" />
    </div>
</template>
<script>
import Base from './Base.vue'
import CheckBox from './CheckBox.vue'
import { get, find, forEach, indexOf, map } from 'lodash-es'
import { getItemName } from '../../../helper/value'
export default {
  props: {},
  data() {
    return {
      data: [],
      names: [],
    }
  },
  methods: {
    getName(id) {
      const item = find(this.data, o => {
        return o.id == id
      })

      return getItemName(item)
    },
    updateValue() {},
    refresh() {
      this.updateArray()
    },
    async updateArray() {
      if (!get(this.prop, 'schema.options.fillWith')) return
      this.data = await this.$store.dispatch('crud/importResourceData', {
        type: get(this.prop, 'schema.options.fillWith'),
        returnData: true,
      })
      if (!this.data) return this.prop.removeAll()

      forEach(this.data, item => {
        if (
          !find(this.prop.value, o => {
            return item.id == o.value.id.value
          })
        ) {
          this.prop.addEnd()
          this.prop.value[this.prop.value.length - 1].value.id.value = item.id
        }
      })
      forEach(this.prop.value, (item, key) => {
        if (
          !find(this.data, o => {
            return o.id == item.value.id.value
          })
        ) {
          this.prop.remove(key)
        }
      })
      const selectedItems = this.prop.value
    },
  },
  mounted() {
    this.refresh()
  },
  watch: {},
  extends: Base,
  components: {
    CheckBox,
  },
}
</script>

<style lang="scss" scoped>
.checkbox {
  float: left;
  margin: 0 20px;
}

.checkboxHeadline {
  margin-bottom: 20px;
}
</style>
