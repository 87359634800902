<template>
  <v-container fluid fill-height>
    <v-layout justify-center text-xs-center>
      <v-flex xs10 sm6 md2>
        <form accept-charset="UTF-8" role="form" @submit.stop.prevent="handleSubmit">
          <v-icon style="font-size: 200px;">devices_other</v-icon>
          <p class="headline">Willkommen in der Zoe</p>
          <p class="">Asset-Management für AIDA Cruises</p>
          <v-form>
            <v-text-field id="mail" placeholder="E-Mail" name="username" type="text" v-model="username" required min="1"></v-text-field>
            <v-text-field id="password" @keyup.enter.native="handleSubmit" placeholder="Passwort" name="password" type="password" v-model="password" required min="8" pattern="[\S]+"></v-text-field>
          </v-form>
          <v-alert v-if="errorMessage && formType != 'reset'" :value="true" type="error">
            {{errorMessage}}
          </v-alert>
          <v-btn class="primary" type="submit">Login</v-btn>
        </form>
      </v-flex>
      <v-dialog :value="true" v-if="formType == 'reset'" max-width="350px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">Willkomen in der Zoe</span>
          </v-card-title>
          <v-card-text>Bitte ändere dein Passwort (min 8 Zeichen).
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field id="password" placeholder="neues Passwort" name="passwordReset" type="password" v-model="passwordReset" required min="8" pattern="[\S]+"></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field id="repeatedpassword" placeholder="Passwort wiederholen" name="passwordRepeat" type="password" v-model="passwordRepeat" required min="8" pattern="[\S]+"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-alert v-if="errorMessage" :value="true" type="error">
            {{errorMessage}}
          </v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" @click="submitNewPassword">Passwort ändern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>

export default {
  name: 'Login',
  data() {
    return {
      successMessage: '',
      errorMessage: '',
      username: '',
      password: '',
      passwordReset: '',
      passwordRepeat: '',
      formHeadline: {
        login: 'Login',
        reset: 'Bitte vergeben Sie ein neues Password',
      },
    }
  },
  computed: {
    formType() {
      return this.$store.getters['auth/formType']
    },
    title() {
      return this.formHeadline[this.$store.getters['auth/formType']]
    },
  },
  methods: {
    async handleSubmit() {
      await this.login(this.username, this.password)
    },
    async submitNewPassword() {
      this.errorMessage = null
      if (this.passwordReset === this.passwordRepeat) {
        const result = await this.$store.dispatch('auth/reset', {
          password: this.passwordReset,
        })

        if (result === true) {
          this.$router.push({ name: 'index' })
        } else {
          this.errorMessage = result
        }
      } else {
        this.errorMessage = 'Passwörter sind nicht identisch'
      }
    },
    async login(username, password) {
      const result = await this.$store.dispatch('auth/login', {
        username,
        password,
      })
      if (result === true) {
        this.$router.push({ name: 'index' })
      } else {
        this.errorMessage = result.message
      }
    },
  },
  watch: {},
}
</script>

<style lang="scss" scoped>
#login {
  form {
    width: 90%;
  }
}
</style>
