import root from 'window-or-global'
import { startsWith } from 'lodash-es'
import { fetchAuthSession } from 'aws-amplify/auth'
import store from '../stores'

/**
 * get the current domain name, or (server context) use localhost:3000
 */
export function buildDomain() {
  const stage = getStage()
  if (stage === 'development') {
    return 'http://localhost:3000/'
  } else if (stage === 'test') {
    return 'https://test-cindy.cruise-api.aida.de/'
  } else {
    return 'https://cindy.cruise-api.aida.de/'
  }
}

// cloudfront for asset buckets
export function addCloudFrontDomain(key) {
  const stage = getStage()
  if (
    startsWith(key, 'https://d1ozq1nmb5vv1n.cloudfront.net/') ||
    startsWith(key, 'https://media.aida.de/')
  ) {
    return key
  }

  if (process.env.NODE_ENV !== 'production' || stage !== 'production') {
    return 'https://test-cindy.cruise-api.aida.de/' + key
  } else {
    return 'https://cindy.cruise-api.aida.de/' + key
  }
}

/**
 * build headers using a JWT token
 */
export async function buildHeaders() {
  const headers = new Headers()
  const stage = getStage()

  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json, text/plain, */*')

  if (process.env.NODE_ENV === 'development' || stage === 'development') {
    headers.append('x-api-key', 'nFizYNGpAh10zPMX76aSp7uEWA1O6lTj1MkYfAtb')
  } else if (process.env.NODE_ENV === 'test' || stage === 'test') {
    headers.append('x-api-key', 'nFizYNGpAh10zPMX76aSp7uEWA1O6lTj1MkYfAtb')
  } else {
    headers.append('x-api-key', 'ZPkK8P95GR45Yd7h8Czo72RtAWb1cyP82FBXSUCi')
  }
  let session, token
  try {
    session = await fetchAuthSession()
    token = session.tokens.accessToken
  } catch (e) {
    token = store.getters['auth/loginSession']
  }
  if (token) headers.append('Authorization', token)

  return headers
}

export function buildFileHeaders() {
  const headers = new Headers()
  headers.append('Accept', 'application/json, text/plain, */*')
  headers.append('Content-Type', undefined)

  return headers
}

/**
 * objToQuery transforms a given object into a HTTP-GET parameters string
 */
export function objToQuery(json) {
  const str = Object.keys(json)
    .map(function(k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(json[k])
    })
    .join('&')
  if (str === '') {
    return ''
  } else {
    return `?${str}`
  }
}

/**
 * hasValidHost checks whether the hostname in window.location.host is valid
 * necessary because of cyclomatic complexity limit
 * @returns {boolean}
 */
function hasValidHost() {
  return (
    typeof root !== 'undefined' &&
    root.location &&
    root.location.host &&
    root.location.host.length > 0
  )
}

export function getStage() {
  const url = window.location.href

  if (startsWith(url, 'http://localhost')) {
    return 'development'
  }

  if (
    startsWith(url, 'https://d8k5elcerlvpk.cloudfront.net') ||
    startsWith(url, 'https://test-zoe.cruise-api.aida.de')
  ) {
    return 'test'
  }

  if (
    startsWith(url, 'https://d1sc9rah0fu94l.cloudfront.net') ||
    startsWith(url, 'https://zoe.cruise-api.aida.de')
  ) {
    return 'production'
  }
}
