import moment from 'moment'
import {
  map,
  replace,
  filter,
  forEach,
  get,
  isEqual,
  without,
  includes,
  find,
} from 'lodash-es'
import MarkdownIt from 'markdown-it'
import { addCloudFrontDomain } from './http'
import store from '../stores'

// const valuesWithEnumTitles = ['status', 'category']

function processValueByDefinition(value, definition, key = null) {
  let val = value
  if (typeof val === 'object' && val !== null) {
    val = processObjects(value, definition)
  } else {
    val = processPrimitives(value, definition)
  }

  // if (key && valuesWithEnumTitles.indexOf(key) !== -1) {
  //   definition = resolveReference(definition)
  //   let index = definition.enum.indexOf(value)
  //   val = definition.options.enum_titles[index]
  // }

  return val
}

function processObjects(value, definition) {
  const language = store.getters['settings/formLanguage']
  let val = value

  if (includes(definition.type, 'array')) {
    val = processArray(value, definition)
  } else if (
    isVideoLink(definition) &&
    value.hasOwnProperty(language) &&
    value[language]
  ) {
    val = `<a target="_blank" href="${value[language]}">${value[language]}</a>`
  } else if (value.hasOwnProperty(language)) {
    val = value[language]
  } else if (isDuration(value)) {
    val = buildDurationString(value, definition)
  } else if (isImageUrl(definition) || value.hasOwnProperty('file')) {
    val = buildThumbnailElement(value.file)
  } else if (isPointOnMap(value)) {
    val = buildMapElement(value.longitude, value.latitude)
  } else if (isIconSet(definition) || isImageSet(definition)) {
    val = buildIconSetPreview(value)
  } else if (externalImageUrl(value)) {
    val = externalImageUrl(value)
  }

  return val
}

function processPrimitives(value, definition) {
  let val = value

  if (isImageUrl(definition)) {
    val = buildThumbnailElement(value)
  } else if (isTimestamp(definition)) {
    val = getFormattedTime(value)
  } else if (isLink(definition)) {
    val = `<a target="_blank" href="${value}">${value}</a>`
  } else if (isBoolean(definition)) {
    val = addCheckmark(value)
  }

  return val
}

function prepareForDatabase(data, definition) {
  forEach(definition, (value, key) => {
    if (key == 'properties') {
      forEach(value, (property, propertyName) => {
        data = convertMarkdownToHtml(data, property, propertyName)
        data = convertLocalTimeToUTC(data, property, propertyName)
      })
    }
  })

  return data
}

function prepareForEditor(data, definition) {
  forEach(definition, (value, key) => {
    if (key == 'properties') {
      forEach(value, (property, propertyName) => {
        data = convertUTCToLocalTime(data, property, propertyName)
      })
    }
  })

  return data
}

function convertMarkdownToHtml(data, property, propertyName) {
  if (
    property.hasOwnProperty('options') &&
    property.options.hasOwnProperty('importContentFrom')
  ) {
    data = convertToHtml(data, property, propertyName)
  } else if (
    property.hasOwnProperty('type') &&
    includes(property.type, 'array') &&
    property.hasOwnProperty('items') &&
    property.items.hasOwnProperty('properties')
  ) {
    forEach(property.items.properties, (arrObject, arrObjectName) => {
      if (
        arrObject.hasOwnProperty('options') &&
        arrObject.options.hasOwnProperty('importContentFrom')
      ) {
        forEach(data[propertyName], (mdObject, key) => {
          data[propertyName][key] = convertToHtml(
            data[propertyName][key],
            arrObject,
            arrObjectName
          )
        })
      }
    })
  } else if (
    property.hasOwnProperty('type') &&
    includes(property.type, 'object') &&
    property.hasOwnProperty('properties')
  ) {
    data[propertyName] = convertMarkdownToHtml(
      data[propertyName],
      property,
      propertyName
    )
  }

  return data
}

function convertToHtml(data, property, propertyName) {
  const md = new MarkdownIt()
  const markdownSource = data[property.options.importContentFrom]
  if (markdownSource.hasOwnProperty('de')) {
    forEach(markdownSource, (langText, langKey) => {
      if (langText === null) langText = ''
      data[propertyName][langKey] = md.render(langText)
    })
  } else {
    data[propertyName] = md.render(markdownSource)
  }
  return data
}

function convertLocalTimeToUTC(data, property, propertyName) {
  if (isTimestamp(property)) {
    data = convertToUTC(data, propertyName)
  } else if (
    property.hasOwnProperty('type') &&
    property.type == 'array' &&
    property.hasOwnProperty('items') &&
    property.items.hasOwnProperty('properties')
  ) {
    forEach(property.items.properties, (arrObject, arrObjectName) => {
      if (isTimestamp(arrObject)) {
        forEach(data[propertyName], (mdObject, key) => {
          data[propertyName][key] = convertToUTC(
            data[propertyName][key],
            arrObject,
            arrObjectName
          )
        })
      }
    })
  } else if (
    data[propertyName] &&
    isObjectType(property) &&
    property.hasOwnProperty('properties')
  ) {
    data[propertyName] = convertLocalTimeToUTC(
      data[propertyName],
      property,
      propertyName
    )
  }

  return data
}

function convertToUTC(data, propertyName) {
  data[propertyName] = moment(data[propertyName])
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss')
  return data
}

function convertUTCToLocalTime(data, property, propertyName) {
  if (isTimestamp(property)) {
    data = convertToLocalTime(data, propertyName)
  } else if (
    property.hasOwnProperty('type') &&
    property.type == 'array' &&
    property.hasOwnProperty('items') &&
    property.items.hasOwnProperty('properties')
  ) {
    forEach(property.items.properties, (arrObject, arrObjectName) => {
      if (isTimestamp(arrObject)) {
        forEach(data[propertyName], (mdObject, key) => {
          data[propertyName][key] = convertToLocalTime(
            data[propertyName][key],
            arrObject,
            arrObjectName
          )
        })
      }
    })
  } else if (
    data[propertyName] &&
    isObjectType(property) &&
    property.hasOwnProperty('properties')
  ) {
    data[propertyName] = convertUTCToLocalTime(
      data[propertyName],
      property,
      propertyName
    )
  }

  return data
}

function convertToLocalTime(data, propertyName) {
  data[propertyName] = moment
    .utc(data[propertyName])
    .local()
    .format('YYYY-MM-DDTHH:mm:ss')

  return data
}

function addCheckmark(attribute) {
  return attribute
    ? '<i class="icon material-icons" style="color: green;">check</i>'
    : '<i class="icon material-icons" style="color: LightCoral;">close</i>'
}

function processArray(value, definition) {
  if (value.length > 100) {
    return `too much items to display (${value.length})`
  }

  let val = value.join('<br>')

  if (definition.hasOwnProperty('items') && isImageUrl(definition.items)) {
    val = map(value, function(image) {
      return buildThumbnailElement(image.file)
    }).join('')
  }

  if (definition.hasOwnProperty('items') && isObjectType(definition.items)) {
    val = map(value, function(item) {
      let result = ''
      forEach(['name', 'id', 'ship'], attr => {
        if (item.hasOwnProperty(attr)) {
          result = item[attr]
        }
      })
      return result
    }).join('<br>')
  }

  return val
}

function buildThumbnailElement(link, isIcon = false) {
  if (!link) return null


  // The image already is an embeddable element
  if (link.includes("<a href="))
    return link

  let src = replace(link, 'original.png', '320px.jpg')
  src = replace(src, 'original.jpg', '320px.jpg')
  const width = isIcon ? 'width="40px"' : 'width="160px"'
  const responsive = isIcon ? '' : 'img-responsive'
  return `<a href="${addCloudFrontDomain(
    link
  )}" target="_blank"><img class="${responsive}" ${width} style="margin: 1px; float: left" src="${addCloudFrontDomain(
    src
  )}"/></a>`
}

function buildDurationString(value, definition) {
  let val = ''
  if (definition.properties.start.format === 'time') {
    val = value.start + ' - ' + value.end
  } else if (definition.properties.start.format === 'datetime-local') {
    val =
      moment(value.start).format('DD.MM.YY HH:mm') +
      ' - ' +
      moment(value.end).format('DD.MM.YY HH:mm')
  } else {
    val = `${value.start} - ${value.end}`
  }

  return val
}

function buildMapElement(long, lat) {
  const url = `<img class="img-responsive" src="https://maps.googleapis.com/maps/api/staticmap?zoom=7&size=400x200&maptype=hybrid
  &markers=color:red%7C${lat},${long}
  &key=AIzaSyCDVeb3-63VOhrB5tblzAKizYX_Gah-cbo" alt="Längengrad: ${long} | Breitengrad: ${lat}"></img>`

  return url
}

function buildIconSetPreview(value) {
  const val = map(value, function(image) {
    return buildThumbnailElement(image.file, true)
  }).join('')

  return val
}

function buildStringsForActiveLanguage(langObject) {
  if (typeof langObject !== 'object') return langObject

  const languages = JSON.parse(localStorage.getItem('languages')) || ['de']
  let result = []
  for (var lang of languages) {
    result.push(langObject[lang])
  }

  return result.join(' / ')
}

function itemsAreEqual(newItem, oldItem) {
  if (!oldItem) return false

  const props = without(
    Object.getOwnPropertyNames(newItem),
    'updatedAt',
    'createdAt',
    'id'
  )

  let obj1 = {}
  let obj2 = {}
  for (var attr of props) {
    obj1[attr] = newItem[attr]
    obj2[attr] = oldItem[attr]
  }

  obj1 = cleanObject(obj1)
  obj2 = cleanObject(obj2)
  return isEqual(obj1, obj2)
}

function cleanObject(obj) {
  obj = convertNullValuesToStrings(obj)
  obj = removeLineBreaksInObject(obj)

  return obj
}

function convertNullValuesToStrings(item) {
  return JSON.parse(
    JSON.stringify(item, function(key, value) {
      if (key === '__ob__') return
      return value === null ? '' : value
    })
  )
}

function removeLineBreaksInObject(obj) {
  for (var key in obj) {
    if (typeof obj[key] == 'object' && obj[key] !== null) {
      removeLineBreaksInObject(obj[key])
    } else if (typeof obj[key] == 'string') {
      obj[key] = obj[key].replace(/\n/g, '')
    }
  }
  return obj
}

function isImageUrl(definition) {
  return (
    definition &&
    definition.hasOwnProperty('format') &&
    (definition.format == 'imageUrl' || definition.format == 'sketchUrl')
  )
}

function isPointOnMap(value) {
  return value.hasOwnProperty('latitude') && value.hasOwnProperty('longitude')
}

function isDuration(value) {
  return value.hasOwnProperty('start') && value.hasOwnProperty('end')
}

function isTimestamp(definition) {
  return (
    definition &&
    definition.hasOwnProperty('format') &&
    definition.format == 'datetime-local'
  )
}

function isLink(definition) {
  return (
    definition &&
    definition.hasOwnProperty('format') &&
    definition.format == 'uri'
  )
}

function isVideoLink(definition) {
  return (
    definition &&
    definition.hasOwnProperty('format') &&
    definition.format == 'videoUrl'
  )
}

function isBoolean(definition) {
  return (
    definition &&
    definition.hasOwnProperty('type') &&
    includes(definition.type, 'boolean')
  )
}

function isIconSet(definition) {
  return (
    definition &&
    definition.hasOwnProperty('format') &&
    definition.format == 'iconSet'
  )
}

function isImageSet(definition) {
  return (
    definition &&
    definition.hasOwnProperty('format') &&
    definition.format == 'imageSet'
  )
}

function isObjectType(definition) {
  return (
    definition &&
    definition.hasOwnProperty('type') &&
    includes(definition.type, 'object')
  )
}

function externalImageUrl(value) {
  let url
  let result
  if ((url = get(value, 'styles.aida_medium.url'))) {
    result = `<a href="${url}" target="_blank"><img width="80px" style="margin: 1px; float: left" src="${url}"/></a>`
  }

  return result
}

function getItemName(item) {
  if (!item) return
  item = item.data || item

  let title = null

  if (item.hasOwnProperty('headline')) {
    title = get(item, 'headline.de', item.headline)
  } else if (item.hasOwnProperty('name')) {
    title = get(item, 'name.de', item.name)
  } else if (item.hasOwnProperty('title')) {
    title = get(item, 'title.de', item.title)
  } else if (item.hasOwnProperty('id')) {
    title = item.id
  } else {
    title = 'No Title'
  }

  return title
}

function getFormattedTime(value) {
  return moment
    .utc(value)
    .local()
    .format('YYYY-MM-DD HH:mm')
}

function applyFilterRules(resourceList, resource) {
  if (resource === 'import-log') {
    resourceList = filter(resourceList, item => item.updateCount !== 0)
  }

  return resourceList
}

function diff(object, base) {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value
      }
    })
  }
  return changes(object, base)
}

export {
  processValueByDefinition,
  prepareForDatabase,
  buildStringsForActiveLanguage,
  itemsAreEqual,
  prepareForEditor,
  getItemName,
  applyFilterRules,
}
