
<template>
  <div>
    <router-link :to="'/resources/' + resourceSchema.id">
      <span class="breadcrumb-item">{{ resourceSchema.title }}</span>
    </router-link>
    <h1>{{ itemName }}
      <router-link v-if="item" :to="{ name: 'resourceUpdate', params: { id: item.id }}">
        <i title="Item bearbeiten" class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i>
      </router-link>
      <component :item="item" v-bind:is="previewLinkIcon"></component>
    </h1>
    <div class="overview">
      <div>
        <h2>{{ t('Cabin subcategories') }}</h2>
        <table v-once class="table table-striped">
          <thead>
            <tr>
              <th @click="setOrder('id', 'subcats')">{{ t('Cabin subcategory') }} {{ orderArrow('id') }}</th>
              <th @click="setOrder('main', 'subcats')">{{ t('Cabin category') }} {{ orderArrow('main') }}</th>
              <th @click="setOrder('name', 'subcats')">{{ t('Name') }} {{ orderArrow('name') }}</th>
              <th @click="setOrder('head', 'subcats')">{{ t('Label') }} {{ orderArrow('head') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, id) in cabinSubCategories" :key="id">
              <td>{{ value.id }}</td>
              <td>{{ value.main }}</td>
              <td>{{ processValue(value.name) }}</td>
              <td>{{ processValue(value.head) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  processValueByDefinition,
  buildStringsForActiveLanguage,
  getItemName,
} from '../../helper/value'
import { translate } from '../../helper/translations'
import previewLinkIcon from '../subComponents/previewIcon.vue'

export default {
  props: {
    name: String,
    id: String | Number,
    resource: String,
  },
  data() {
    return {
      showOverview: false,
      order: [],
      previewLinkIcon: 'previewLinkIcon',
    }
  },
  async mounted() {},
  computed: {
    resources() {
      return this.$store.getters['crud/resourceNames']
    },
    resourceSchema() {
      return this.$store.getters['crud/resourceSchema']
    },
    cabinSubCategories() {
      return this.item.cabinCategories
    },
    item() {
      return this.$store.getters['crud/resourceItem'].data
    },
    itemSchema() {
      return this.$store.getters['crud/resourceSchema']
    },
    isCreatingMode() {
      return !this.id
    },
    itemName() {
      return getItemName(this.item)
    },
  },
  methods: {
    processValue(value, definition = null, key = null) {
      if (definition && key) {
        value = processValueByDefinition(value, definition, key)
      }

      return buildStringsForActiveLanguage(value)
    },
    t(key) {
      return translate(key)
    },
    setOrder(attribute, table) {
      const order = this.$store.getters['search/order']
      let direction = 'ASC'

      if (order.attribute === attribute && order.direction === 'ASC')
        direction = 'DESC'

      this.$store.dispatch('search/order', {
        attribute: attribute,
        direction: direction,
      })
    },
    orderArrow(attribute) {
      const order = this.$store.getters['search/order']
      let arrow = ''
      if (order.attribute === attribute) {
        arrow = order.direction === 'DESC' ? '\u2193' : '\u2191'
      }

      //return arrow;
    },
  },
  components: { previewLinkIcon },
}
</script>

<style lang="sass" scoped>
.breadcrumb-item
  color: lightgrey
  font-size: larger
.overview
  margin-top: 10px
.breadcrumb-item
  font-size: larger
h1 i
  color: lightgrey
  font-size: smaller
  bottom: 5px
  left: 10px
  position: relative
h1 i:hover
  color: grey
thead th
  cursor: pointer
</style>
