<!-- this component enables browser-preview of the actual component -->
<template>
  <v-app id="inspire" :class="{
    'show-resources': drawer
  }">
    <v-navigation-drawer class="resource-list" width="230" fixed :clipped="$vuetify.breakpoint.mdAndUp" app v-model="drawer" v-if="showResources && loggedin">
      <v-list dense expand>
        <template v-for="(resourcesGroup, key) in groupedResources">
          <v-list-group :key="key" v-model="folds[key]" :prepend-icon="getIcon(resourcesGroup.id)" append-icon="">
            <v-list-item class="mainResource" slot="activator" >
              <v-list-item-content>
                <v-list-item-title>
                  <span>{{resourcesGroup.name}}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <router-link class="subResource" tag="v-list-item" :to="getTarget(resource)" v-for="(resource, key2)  in resourcesGroup.data" :key="key2">
              <v-list-item-content>
                <v-list-item-title>
                  {{ resource.title }}
                </v-list-item-title>
              </v-list-item-content>
            </router-link>
            <v-divider></v-divider>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="blue darken-3" dark app :clipped-left="$vuetify.breakpoint.mdAndUp" fixed clipped-right>
      <v-app-bar-nav-icon v-if="showResources && loggedin" @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
      <v-toolbar-title class="ml-0 pl-3">
        <router-link tag="span" style="cursor:pointer;" :to="{ name: 'index' }">
          <span>Zoe</span>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span style="cursor:pointer;" v-if="loggedin" @click.stop="drawerSettings = !drawerSettings" color="primary">
        <img class="flag" :src="languageFlag" />
        
        <v-icon>person</v-icon> {{ username }} ({{ role }})
      </span>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Settings v-model="drawerSettings" />

    <v-snackbar :timeout="timeout" :top="true" v-model="snackbar" :color="currentMessageType">
      {{ currentMessage }}
      <v-btn @click.native="snackbar = false" text icon small>
        <v-icon dark>close</v-icon>
      </v-btn>
    </v-snackbar>
    <transition name="fade">
      <div v-if="showPreloader" class="washer" ref="preloader">
        <v-progress-circular class="progress-circular" :size="50" indeterminate color="primary"></v-progress-circular>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { Amplify } from 'aws-amplify'
import Settings from './Settings.vue'
import { fill, merge, cloneDeep, forEach, map } from 'lodash-es'
import { iconMapping, directEntries } from '../config/menu'
export default {
  components: { Settings },
  data: () => ({
    rightPanel: false,
    drawer: true,
    folds: [],
    drawerSettings: false,
    snackbar: false,
    languageFlag: false,
    timeout: 3000,
  }),
  computed: {
    currentMessage() {
      return this.$store.getters['messages/message']
    },
    currentMessageType() {
      return this.$store.getters['messages/type']
    },
    currentMessageShow() {
      return this.$store.getters['messages/show']
    },
    showResources() {
      return this._route.fullPath.toString().startsWith('/resources')
    },
    pathName() {
      return this._route.name
    },
    resources() {
      return this.$store.getters['crud/resourceInfos']
    },
    groupedResources() {
      return map(this.$store.getters['crud/groupedResources'], (data, name) => {
        return { data, name, id: data[0].section }
      })
    },
    navBarMenuClass: function() {
      return this.navBarCollapsed ? 'collapse' : ''
    },
    username() {
      return this.$store.getters['auth/username']
    },
    loggedin() {
      return this.$store.getters['auth/userIsLoggedIn']
    },
    role() {
      return this.$store.getters['auth/role'].toLowerCase()
    },
    showPreloader() {
      return this.$store.getters['messages/preloader']
    },
    formLanguage() {
      return this.$store.getters['settings/formLanguage']
    }
  },
  watch: {
    pathName(value) {
      if (value == 'resources') this.drawer = true
    },
    folds(folds) {
      this.$store.dispatch('settings/saveFolds', cloneDeep(folds))
    },
    currentMessage() {
      this.snackbar = true
    },
    currentMessageShow(value) {
      if (value) {
        this.snackbar = true
      }
    },
    snackbar(value) {
      if (!value) {
        this.$store.dispatch('messages/setShow', false)
      }
    },
    formLanguage(value) {
      if (value !== null) {
        this.languageFlag = `/flags/${value}.png`
      }
    }
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    },
    getIcon(resource) {
      return iconMapping[resource]
    },
    logout() {
      this.$store.dispatch('auth/logout')
    },
    getTarget(resource) {
      const target = {
        name: 'resourceList',
        params: { resource: resource.id },
      }

      if (directEntries[resource.id]) {
        target.name = 'resourceUpdate'
        target.params['id'] = directEntries[resource.id]
      }

      return target
    },
  },
  mounted() {},
  async created() {
    await this.$store.dispatch('crud/importSchemas')
    await this.$store.dispatch('settings/import')
    this.folds = cloneDeep(this.$store.getters['settings/folds'])
  },
}

Amplify.configure({
  Auth: {
    Cognito: {
      // REQUIRED - Amazon Cognito Region
      region: 'eu-central-1',
      // REQUIRED - Amazon Cognito User Pool ID
      userPoolId: 'eu-central-1_yEqRxNecZ',
      // REQUIRED - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: '7edaj23cprhhvr467i26h387ng',
    }
  },
})
</script>

<style lang="scss">
.washer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 10000;

  .progress-circular {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.resource-list .list__group:first-child::before {
  background: none;
}

.dialog-right-transition {
  &-enter,
  &-leave-to {
    transform: translateY(100%) translateX(0%);
  }
}

.flag {
  height: 18px;
  margin-right: 5px;
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0;
  padding: 0px 16px;
}

.v-list-item__icon.v-list-group__header__prepend-icon {
  margin-right: 0 !important;
}

.v-list-group__header.v-list-item.v-list-item--link.theme--light,
.v-list-group__header.v-list-item.v-list-item--active.v-list-item--link.theme--light {
  padding: 0;
}

@media (min-width: 2561px) {
  .card.list-wrapper {
    &.update-open {
      width: 730px;
      overflow: scroll;

      th:not(:first-child):not(:last-child):not(:nth-child(2)) {
        display: none;
      }

      td:not(:first-child):not(:last-child):not(:nth-child(2)) {
        display: none;
      }
    }
  }

  .show-resources {
    .card.list-wrapper {
      &.update-open {
        width: 500px;
        overflow: scroll;
      }
    }
  }

  .update-dialog {
    right: 0;
    left: auto;
    top: 64px;
    width: calc(100% - 730px);
    box-shadow: 0px 3px 3px #aaa;
  }

  .dialog-right-transition {
    &-enter,
    &-leave-to {
      transform: translateX(100%) translateY(0%);
    }
  }
}

.mainResource span {
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
  color: #004461;
}

.datatable__actions {
  padding: 20px 20px 20px 0;
  font-size: taller;
}
</style>
