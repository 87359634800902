<template>
    <div class="editor-container">
      <v-container grid-list-md><AutoForm :wrapClasses="wrapClasses"  :language="language" :data="editorData"></AutoForm></v-container>
    </div>
</template>
<script>
import Default from './Default.vue'
import { includes, merge, cloneDeep, mapValues, forEach, get } from 'lodash-es'

import {
  updateItemFromSchema,
  connectItemAndSchema,
  disconnectItemAndSchema,
} from '../../helper/schema'

export default {
  extends: Default,
  data() {
    return {
      wrapClasses: {},
    }
  },
  computed: {},
  methods: {},
  created() {
    switch (this.resource) {
      case 'i18n-bea-booking':
        this.wrapClasses = {
          wrapperlevel1: 'xs4',
        }
        break
      case 'i18n-bea-icons-content-hub':
      case 'i18n-bea-icons-planning':
      case 'i18n-bea-planning':
        this.wrapClasses = 'xs4'
        break
      default:
        this.wrapClasses = {
          controlsAndInfo: 'xs4',
          booking: 'xs4',
          onboard: 'xs4',
          travel: 'xs4',
          weather: 'xs4',
          bus: 'xs4',
          flight: 'xs4',
          flex: 'xs4',
          none: 'xs4',
          rail: 'xs4',
          other: 'xs4',
          misc: 'xs4',
        }
        break
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
