<template>
  <v-btn icon text v-show="previewLink(item, draftId)" @click="forward(item, draftId)">
    <v-icon>visibility</v-icon>
  </v-btn>
</template>

<script>
import { getPreviewLink } from '../../helper/preview'

export default {
  props: {
    item: Object,
    draftId: String,
  },
  name: 'previewLinkIcon',
  data() {
    return {}
  },
  computed: {
    itemSchema() {
      return this.$store.getters['crud/resourceSchema']
    },
  },
  methods: {
    forward(item, draftId) {
      window.open(this.previewLink(item, draftId), '_blank')
    },
    previewLink(item, draftId) {
      if (item && this.itemSchema) {
        return getPreviewLink(this.itemSchema.id, item, draftId)
      }
    },
    previewLinkTitle(item) {
      let title = 'Frontend Ansicht'
      if (item && item.status) {
        title = 'Vorschau'
      }

      return title
    },
  },
}
</script>

<style lang="sass" scoped>
h1 i
    color: lightgrey
    font-size: smaller
    bottom: 5px
    left: 10px
    position: relative
h1 i:hover
    color: grey
</style>
