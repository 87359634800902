import { indexOf } from 'lodash-es'

function userIsAllowed(userRole, schema) {
  let isAllowed = false
  if (!schema.hasOwnProperty('permissions')) {
    isAllowed = true
  }
  if (
    schema.hasOwnProperty('permissions') &&
    indexOf(schema.permissions.allowed, userRole) !== -1
  ) {
    isAllowed = true
  }

  return isAllowed
}

export { userIsAllowed }
