<template>
  <v-tabs class="tab-wrapper" dark :background-color="draftMode ? 'success' : 'primary'" show-arrows centered>
    <v-tabs-slider color="orange"></v-tabs-slider>
    <v-tab :href="'#tab-allgemein'">Allgemein</v-tab>
    <v-tab :href="'#tab-articles'">Artikel</v-tab>
    <v-tab :href="'#tab-cabinarticles'">Kabinenartikel</v-tab>
    <v-tab :href="'#tab-foodarticles'">Kulinarik</v-tab>
    <v-tab :href="'#tab-wellnessarticles'">Wellness</v-tab>
    <v-tab :href="'#tab-entertainmentarticles'">Entertainment</v-tab>
    <v-tab :href="'#tab-familyarticles'">Familie</v-tab>
    <v-tab :href="'#tab-enviromentarticles'">Umwelt</v-tab>
    <v-tab :href="'#tab-christeningarticles'">Taufe</v-tab>
    <v-tab :href="'#tab-cabins'">Kabinen</v-tab>
    <v-tab :href="'#tab-decks'">Decks</v-tab>
    <v-tab :href="'#tab-downloads'">Downloads</v-tab>
    <v-tab-item id="tab-allgemein">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <v-layout wrap>
              <v-flex md3>
                <InputBox :readonly="!isCreatingMode" :prop="editorData.value.id"/>
              </v-flex>
              <v-flex md3>
                <InputBox :prop="editorData.value.name"/>
              </v-flex>
              <v-flex md3>
                <DateBox :prop="editorData.value.commissioning"/>
              </v-flex>
              <v-flex md3>
                <SelectBox
                  :readonly="!isCreatingMode"
                  title="Status"
                  :prop="editorData.value.status"
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex md4>
                <VideoLinkBox
                  title="Videolink"
                  :prop="editorData.value.videoLink.value[language]"
                />
                <InputBox
                  title="Teaser Überschrift"
                  :prop="editorData.value.teaserHeadline.value[language]"
                />
                <TextBox title="Teasertext" :prop="editorData.value.teaserText.value[language]"/>
              </v-flex>
              <v-flex md4>
                <FileBox
                  :hq="true"
                  title="Teaser Bild"
                  fileType="image"
                  :prop="editorData.value.teaserImage.value.file"
                >
                  <InputBox
                    title="alternative Bildbeschreibung"
                    :prop="editorData.value.teaserImage.value.alt.value[language]"
                  />
                </FileBox>
              </v-flex>
              <v-flex md4>
                <FileBox
                  :hq="true"
                  title="Schiffsbild mit transparenten Hintergrund"
                  fileType="image"
                  :prop="editorData.value.detachedImage.value.file"
                >
                  <InputBox
                    title="alternative Bildbeschreibung"
                    :prop="editorData.value.detachedImage.value.alt.value[language]"
                  />
                </FileBox>
              </v-flex>
            </v-layout>
            <Wrapper :wrapWithCard="true" title="Technische Daten">
              <v-layout wrap>
                <v-flex md3>
                  <InputBox :prop="editorData.value.technicalData.value.length"/>
                </v-flex>
                <v-flex md3>
                  <InputBox :prop="editorData.value.technicalData.value.width"/>
                </v-flex>
                <v-flex md3>
                  <InputBox :prop="editorData.value.technicalData.value.grossTonnage"/>
                </v-flex>
                <v-flex md3>
                  <InputBox :prop="editorData.value.technicalData.value.draft"/>
                </v-flex>
                <v-flex md4>
                  <InputBox :prop="editorData.value.technicalData.value.decksCount"/>
                </v-flex>
                <v-flex md4>
                  <InputBox :prop="editorData.value.technicalData.value.engine"/>
                </v-flex>
                <v-flex md4>
                  <InputBox :prop="editorData.value.technicalData.value.electricPower"/>
                </v-flex>
              </v-layout>
            </Wrapper>
            <Wrapper :wrapWithCard="true" title="Weitere Informationen">
              <TextAreaBox :textOnly="true" :prop="editorData.value.marketingText"/>
            </Wrapper>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
    <v-tab-item id="tab-cabinarticles">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Wrapper :wrapWithCard="true" title="Teaserinformationen">
              <v-layout wrap>
                <v-flex xs12 sm12 md4>
                  <InputBox
                    title="Überschrift"
                    :prop="editorData.value.cabins.value.headline.value[language]"
                  />
                  <InputBox
                    title="Unterüberschrift"
                    :prop="editorData.value.cabins.value.subHeadline.value[language]"
                  />
                  <InputBox
                    title="Teasertext"
                    :prop="editorData.value.cabins.value.teaserText.value[language]"
                  />
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <FileBox
                    title="Teaser Bild"
                    fileType="image"
                    :prop="editorData.value.cabins.value.teaserImage.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.cabins.value.teaserImage.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <VideoLinkBox
                    title="Videolink"
                    :prop="editorData.value.cabins.value.videoLink.value[language]"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <TextBox
                    title="Beschreibung"
                    :prop="editorData.value.cabins.value.description.value[language]"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
            <Array
              :title="'Artikel für ' + editorData.value.cabins.value.headline.value[language].value"
              :prop="editorData.value.cabins.value.contentSections"
            >
              <template slot-scope="slotProps">
                <div
                  slot="header"
                >{{ slotProps.prop.value.contentSectionHeadline.value[language].value }} (Artikel {{ slotProps.index + 1 }})</div>
                <v-layout wrap>
                  <v-flex xs12 sm12 md6>
                    <InputBox
                      title="Überschrift"
                      :prop="slotProps.prop.value.contentSectionHeadline.value[language]"
                    />
                    <TextBox
                      title="Text"
                      :prop="slotProps.prop.value.contentSectionDescription.value[language]"
                    />
                  </v-flex>
                  <v-flex xs12 sm12 md6>
                    <VideoLinkBox
                      title="Videolink"
                      :prop="slotProps.prop.value.videoLink.value[language]"
                    />
                  </v-flex>
                </v-layout>
                <Array title="Bilder" :prop="slotProps.prop.value.contentSectionImage">
                  <template slot-scope="slotProps">
                    <FileBox fileType="image" :prop="slotProps.prop.value.file">
                      <InputBox
                        title="Beschreibung"
                        :prop="slotProps.prop.value.description.value[language]"
                      />
                      <InputBox
                        title="alternative Bildbeschreibung"
                        :prop="slotProps.prop.value.alt.value[language]"
                      />
                    </FileBox>
                  </template>
                </Array>
              </template>
            </Array>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
    <v-tab-item id="tab-foodarticles">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Wrapper :wrapWithCard="true" title="Teaserinformationen">
              <v-layout wrap>
                <v-flex xs12 sm12 md4>
                  <InputBox
                    title="Überschrift"
                    :prop="editorData.value.food.value.headline.value[language]"
                  />
                  <InputBox
                    title="Unterüberschrift"
                    :prop="editorData.value.food.value.subHeadline.value[language]"
                  />
                  <InputBox
                    title="Teasertext"
                    :prop="editorData.value.food.value.teaserText.value[language]"
                  />
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <FileBox
                    title="Teaser Bild"
                    fileType="image"
                    :prop="editorData.value.food.value.teaserImage.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.food.value.teaserImage.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <VideoLinkBox
                    title="Videolink"
                    :prop="editorData.value.food.value.videoLink.value[language]"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <TextBox
                    title="Beschreibung"
                    :prop="editorData.value.food.value.description.value[language]"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
            <Array
              :title="'Artikel für ' + editorData.value.food.value.headline.value[language].value"
              :prop="editorData.value.food.value.contentSections"
            >
              <template slot-scope="slotProps">
                <div
                  slot="header"
                >{{ slotProps.prop.value.contentSectionHeadline.value[language].value }} (Artikel {{ slotProps.index + 1 }})</div>
                <v-layout wrap>
                  <v-flex xs12 sm12 md6>
                    <InputBox
                      title="Überschrift"
                      :prop="slotProps.prop.value.contentSectionHeadline.value[language]"
                    />
                    <TextBox
                      title="Text"
                      :prop="slotProps.prop.value.contentSectionDescription.value[language]"
                    />
                  </v-flex>
                  <v-flex xs12 sm12 md6>
                    <VideoLinkBox
                      title="Videolink"
                      :prop="slotProps.prop.value.videoLink.value[language]"
                    />
                  </v-flex>
                </v-layout>
                <Array title="Bilder" :prop="slotProps.prop.value.contentSectionImage">
                  <template slot-scope="slotProps">
                    <FileBox fileType="image" :prop="slotProps.prop.value.file">
                      <InputBox
                        title="Beschreibung"
                        :prop="slotProps.prop.value.description.value[language]"
                      />
                      <InputBox
                        title="alternative Bildbeschreibung"
                        :prop="slotProps.prop.value.alt.value[language]"
                      />
                    </FileBox>
                  </template>
                </Array>
              </template>
            </Array>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
    <v-tab-item id="tab-wellnessarticles">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Wrapper :wrapWithCard="true" title="Teaserinformationen">
              <v-layout wrap>
                <v-flex xs12 sm12 md4>
                  <InputBox
                    title="Überschrift"
                    :prop="editorData.value.wellness.value.headline.value[language]"
                  />
                  <InputBox
                    title="Unterüberschrift"
                    :prop="editorData.value.wellness.value.subHeadline.value[language]"
                  />
                  <InputBox
                    title="Teasertext"
                    :prop="editorData.value.wellness.value.teaserText.value[language]"
                  />
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <FileBox
                    title="Teaser Bild"
                    fileType="image"
                    :prop="editorData.value.wellness.value.teaserImage.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.wellness.value.teaserImage.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <VideoLinkBox
                    title="Videolink"
                    :prop="editorData.value.wellness.value.videoLink.value[language]"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <TextBox
                    title="Beschreibung"
                    :prop="editorData.value.wellness.value.description.value[language]"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
            <Array
              :title="'Artikel für ' + editorData.value.wellness.value.headline.value[language].value"
              :prop="editorData.value.wellness.value.contentSections"
            >
              <template slot-scope="slotProps">
                <div
                  slot="header"
                >{{ slotProps.prop.value.contentSectionHeadline.value[language].value }} (Artikel {{ slotProps.index + 1 }})</div>
                <v-layout wrap>
                  <v-flex xs12 sm12 md6>
                    <InputBox
                      title="Überschrift"
                      :prop="slotProps.prop.value.contentSectionHeadline.value[language]"
                    />
                    <TextBox
                      title="Text"
                      :prop="slotProps.prop.value.contentSectionDescription.value[language]"
                    />
                  </v-flex>
                  <v-flex xs12 sm12 md6>
                    <VideoLinkBox
                      title="Videolink"
                      :prop="slotProps.prop.value.videoLink.value[language]"
                    />
                  </v-flex>
                </v-layout>
                <Array title="Bilder" :prop="slotProps.prop.value.contentSectionImage">
                  <template slot-scope="slotProps">
                    <FileBox fileType="image" :prop="slotProps.prop.value.file">
                      <InputBox
                        title="Beschreibung"
                        :prop="slotProps.prop.value.description.value[language]"
                      />
                      <InputBox
                        title="alternative Bildbeschreibung"
                        :prop="slotProps.prop.value.alt.value[language]"
                      />
                    </FileBox>
                  </template>
                </Array>
              </template>
            </Array>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
    <v-tab-item id="tab-entertainmentarticles">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Wrapper :wrapWithCard="true" title="Teaserinformationen">
              <v-layout wrap>
                <v-flex xs12 sm12 md4>
                  <InputBox
                    title="Überschrift"
                    :prop="editorData.value.entertainment.value.headline.value[language]"
                  />
                  <InputBox
                    title="Unterüberschrift"
                    :prop="editorData.value.entertainment.value.subHeadline.value[language]"
                  />
                  <InputBox
                    title="Teasertext"
                    :prop="editorData.value.entertainment.value.teaserText.value[language]"
                  />
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <FileBox
                    title="Teaser Bild"
                    fileType="image"
                    :prop="editorData.value.entertainment.value.teaserImage.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.entertainment.value.teaserImage.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <VideoLinkBox
                    title="Videolink"
                    :prop="editorData.value.entertainment.value.videoLink.value[language]"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <TextBox
                    title="Beschreibung"
                    :prop="editorData.value.entertainment.value.description.value[language]"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
            <Array
              :title="'Artikel für ' + editorData.value.entertainment.value.headline.value[language].value"
              :prop="editorData.value.entertainment.value.contentSections"
            >
              <template slot-scope="slotProps">
                <div
                  slot="header"
                >{{ slotProps.prop.value.contentSectionHeadline.value[language].value }} (Artikel {{ slotProps.index + 1 }})</div>
                <v-layout wrap>
                  <v-flex xs12 sm12 md6>
                    <InputBox
                      title="Überschrift"
                      :prop="slotProps.prop.value.contentSectionHeadline.value[language]"
                    />
                    <TextBox
                      title="Text"
                      :prop="slotProps.prop.value.contentSectionDescription.value[language]"
                    />
                  </v-flex>
                  <v-flex xs12 sm12 md6>
                    <VideoLinkBox
                      title="Videolink"
                      :prop="slotProps.prop.value.videoLink.value[language]"
                    />
                  </v-flex>
                </v-layout>
                <Array title="Bilder" :prop="slotProps.prop.value.contentSectionImage">
                  <template slot-scope="slotProps">
                    <FileBox fileType="image" :prop="slotProps.prop.value.file">
                      <InputBox
                        title="Beschreibung"
                        :prop="slotProps.prop.value.description.value[language]"
                      />
                      <InputBox
                        title="alternative Bildbeschreibung"
                        :prop="slotProps.prop.value.alt.value[language]"
                      />
                    </FileBox>
                  </template>
                </Array>
              </template>
            </Array>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
    <v-tab-item id="tab-familyarticles">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Wrapper :wrapWithCard="true" title="Teaserinformationen">
              <v-layout wrap>
                <v-flex xs12 sm12 md4>
                  <InputBox
                    title="Überschrift"
                    :prop="editorData.value.family.value.headline.value[language]"
                  />
                  <InputBox
                    title="Unterüberschrift"
                    :prop="editorData.value.family.value.subHeadline.value[language]"
                  />
                  <InputBox
                    title="Teasertext"
                    :prop="editorData.value.family.value.teaserText.value[language]"
                  />
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <FileBox
                    title="Teaser Bild"
                    fileType="image"
                    :prop="editorData.value.family.value.teaserImage.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.family.value.teaserImage.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <VideoLinkBox
                    title="Videolink"
                    :prop="editorData.value.family.value.videoLink.value[language]"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <TextBox
                    title="Beschreibung"
                    :prop="editorData.value.family.value.description.value[language]"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
            <Array
              :title="'Artikel für ' + editorData.value.family.value.headline.value[language].value"
              :prop="editorData.value.family.value.contentSections"
            >
              <template slot-scope="slotProps">
                <div
                  slot="header"
                >{{ slotProps.prop.value.contentSectionHeadline.value[language].value }} (Artikel {{ slotProps.index + 1 }})</div>
                <v-layout wrap>
                  <v-flex xs12 sm12 md6>
                    <InputBox
                      title="Überschrift"
                      :prop="slotProps.prop.value.contentSectionHeadline.value[language]"
                    />
                    <TextBox
                      title="Text"
                      :prop="slotProps.prop.value.contentSectionDescription.value[language]"
                    />
                  </v-flex>
                  <v-flex xs12 sm12 md6>
                    <VideoLinkBox
                      title="Videolink"
                      :prop="slotProps.prop.value.videoLink.value[language]"
                    />
                  </v-flex>
                </v-layout>
                <Array title="Bilder" :prop="slotProps.prop.value.contentSectionImage">
                  <template slot-scope="slotProps">
                    <FileBox fileType="image" :prop="slotProps.prop.value.file">
                      <InputBox
                        title="Beschreibung"
                        :prop="slotProps.prop.value.description.value[language]"
                      />
                      <InputBox
                        title="alternative Bildbeschreibung"
                        :prop="slotProps.prop.value.alt.value[language]"
                      />
                    </FileBox>
                  </template>
                </Array>
              </template>
            </Array>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
    <v-tab-item id="tab-enviromentarticles">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Wrapper :wrapWithCard="true" title="Teaserinformationen">
              <v-layout wrap>
                <v-flex xs12 sm12 md4>
                  <InputBox
                    title="Überschrift"
                    :prop="editorData.value.enviroment.value.headline.value[language]"
                  />
                  <InputBox
                    title="Unterüberschrift"
                    :prop="editorData.value.enviroment.value.subHeadline.value[language]"
                  />
                  <InputBox
                    title="Teasertext"
                    :prop="editorData.value.enviroment.value.teaserText.value[language]"
                  />
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <FileBox
                    title="Teaser Bild"
                    fileType="image"
                    :prop="editorData.value.enviroment.value.teaserImage.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.enviroment.value.teaserImage.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <VideoLinkBox
                    title="Videolink"
                    :prop="editorData.value.enviroment.value.videoLink.value[language]"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <TextBox
                    title="Beschreibung"
                    :prop="editorData.value.enviroment.value.description.value[language]"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
            <Array
              :title="'Artikel für ' + editorData.value.enviroment.value.headline.value[language].value"
              :prop="editorData.value.enviroment.value.contentSections"
            >
              <template slot-scope="slotProps">
                <div
                  slot="header"
                >{{ slotProps.prop.value.contentSectionHeadline.value[language].value }} (Artikel {{ slotProps.index + 1 }})</div>
                <v-layout wrap>
                  <v-flex xs12 sm12 md6>
                    <InputBox
                      title="Überschrift"
                      :prop="slotProps.prop.value.contentSectionHeadline.value[language]"
                    />
                    <TextBox
                      title="Text"
                      :prop="slotProps.prop.value.contentSectionDescription.value[language]"
                    />
                  </v-flex>
                  <v-flex xs12 sm12 md6>
                    <VideoLinkBox
                      title="Videolink"
                      :prop="slotProps.prop.value.videoLink.value[language]"
                    />
                  </v-flex>
                </v-layout>
                <Array title="Bilder" :prop="slotProps.prop.value.contentSectionImage">
                  <template slot-scope="slotProps">
                    <FileBox fileType="image" :prop="slotProps.prop.value.file">
                      <InputBox
                        title="Beschreibung"
                        :prop="slotProps.prop.value.description.value[language]"
                      />
                      <InputBox
                        title="alternative Bildbeschreibung"
                        :prop="slotProps.prop.value.alt.value[language]"
                      />
                    </FileBox>
                  </template>
                </Array>
              </template>
            </Array>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
    <v-tab-item id="tab-christeningarticles">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Wrapper :wrapWithCard="true" title="Teaserinformationen">
              <v-layout wrap>
                <v-flex xs12 sm12 md4>
                  <InputBox
                    title="Überschrift"
                    :prop="editorData.value.christening.value.headline.value[language]"
                  />
                  <InputBox
                    title="Unterüberschrift"
                    :prop="editorData.value.christening.value.subHeadline.value[language]"
                  />
                  <InputBox
                    title="Teasertext"
                    :prop="editorData.value.christening.value.teaserText.value[language]"
                  />
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <FileBox
                    title="Teaser Bild"
                    fileType="image"
                    :prop="editorData.value.christening.value.teaserImage.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.christening.value.teaserImage.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <VideoLinkBox
                    title="Videolink"
                    :prop="editorData.value.christening.value.videoLink.value[language]"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <TextBox
                    title="Beschreibung"
                    :prop="editorData.value.christening.value.description.value[language]"
                  />
                </v-flex>
              </v-layout>
            </Wrapper>
            <Array
              :title="'Artikel für ' + editorData.value.christening.value.headline.value[language].value"
              :prop="editorData.value.christening.value.contentSections"
            >
              <template slot-scope="slotProps">
                <div
                  slot="header"
                >{{ slotProps.prop.value.contentSectionHeadline.value[language].value }} (Artikel {{ slotProps.index + 1 }})</div>
                <v-layout wrap>
                  <v-flex xs12 sm12 md6>
                    <InputBox
                      title="Überschrift"
                      :prop="slotProps.prop.value.contentSectionHeadline.value[language]"
                    />
                    <TextBox
                      title="Text"
                      :prop="slotProps.prop.value.contentSectionDescription.value[language]"
                    />
                  </v-flex>
                  <v-flex xs12 sm12 md6>
                    <VideoLinkBox
                      title="Videolink"
                      :prop="slotProps.prop.value.videoLink.value[language]"
                    />
                  </v-flex>
                </v-layout>
                <Array title="Bilder" :prop="slotProps.prop.value.contentSectionImage">
                  <template slot-scope="slotProps">
                    <FileBox fileType="image" :prop="slotProps.prop.value.file">
                      <InputBox
                        title="Beschreibung"
                        :prop="slotProps.prop.value.description.value[language]"
                      />
                      <InputBox
                        title="alternative Bildbeschreibung"
                        :prop="slotProps.prop.value.alt.value[language]"
                      />
                    </FileBox>
                  </template>
                </Array>
              </template>
            </Array>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
    <v-tab-item id="tab-articles">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Array title="Artikelkategorien" :prop="editorData.value.articles">
              <template slot-scope="slotProps">
                <div
                  slot="header"
                >{{ slotProps.prop.value.label.value }}{{slotProps.prop.value.category.value ? ` (${slotProps.prop.value.category.value})` : ''}}</div>
                <Wrapper :wrapWithCard="true" title="Teaserinformationen">
                  <v-layout wrap>
                    <v-flex xs12 sm12 md4>
                      <InputBox
                        :title="slotProps.prop.schema.label"
                        :prop="slotProps.prop.value.label"
                      />
                      <InputBox title="Kategorie-Schlüssel" :prop="slotProps.prop.value.category"/>
                      <InputBox
                        title="Überschrift"
                        :prop="slotProps.prop.value.headline.value[language]"
                      />
                      <InputBox
                        title="Unterüberschrift"
                        :prop="slotProps.prop.value.subHeadline.value[language]"
                      />
                      <InputBox
                        title="Teasertext"
                        :prop="slotProps.prop.value.teaserText.value[language]"
                      />
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <FileBox
                        title="Teaser Bild"
                        fileType="image"
                        :prop="slotProps.prop.value.teaserImage.value.file"
                      >
                        <InputBox
                          title="alternative Bildbeschreibung"
                          :prop="slotProps.prop.value.teaserImage.value.alt.value[language]"
                        />
                      </FileBox>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <VideoLinkBox
                        title="Videolink"
                        :prop="slotProps.prop.value.videoLink.value[language]"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md12>
                      <TextBox
                        title="Beschreibung"
                        :prop="slotProps.prop.value.description.value[language]"
                      />
                    </v-flex>
                  </v-layout>
                </Wrapper>
                <Array
                  :title="'Artikel für ' + slotProps.prop.value.headline.value[language].value"
                  :prop="slotProps.prop.value.contentSections"
                >
                  <template slot-scope="slotProps">
                    <div
                      slot="header"
                    >{{ slotProps.prop.value.contentSectionHeadline.value[language].value }} (Artikel {{ slotProps.index + 1 }})</div>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md6>
                        <InputBox
                          title="Überschrift"
                          :prop="slotProps.prop.value.contentSectionHeadline.value[language]"
                        />
                        <TextBox
                          title="Text"
                          :prop="slotProps.prop.value.contentSectionDescription.value[language]"
                        />
                      </v-flex>
                    </v-layout>
                    <Array title="Media" :prop="slotProps.prop.value.contentSectionMedia">
                      <template slot-scope="slotProps">
                        <SelectBox title="Kategorie" :prop="slotProps.prop.value.mediatype"/>
                        <VideoLinkBox
                          v-show="slotProps.prop.value.mediatype.value == 'video'"
                          title="Videolink"
                          :prop="slotProps.prop.value.videoLink.value[language]"
                        />
                        <FileBox
                          v-show="slotProps.prop.value.mediatype.value == 'image'"
                          fileType="image"
                          :prop="slotProps.prop.value.image.value.file"
                        >
                          <InputBox
                            title="Beschreibung"
                            :prop="slotProps.prop.value.image.value.description.value[language]"
                          />
                          <InputBox
                            title="alternative Bildbeschreibung"
                            :prop="slotProps.prop.value.image.value.alt.value[language]"
                          />
                        </FileBox>
                      </template>
                    </Array>

                    <Array
                      title="Bilder (alt - nur für Mira)"
                      :prop="slotProps.prop.value.contentSectionImage"
                    >
                      <template slot-scope="slotProps">
                        <FileBox fileType="image" :prop="slotProps.prop.value.file">
                          <InputBox
                            title="Beschreibung"
                            :prop="slotProps.prop.value.description.value[language]"
                          />
                          <InputBox
                            title="alternative Bildbeschreibung"
                            :prop="slotProps.prop.value.alt.value[language]"
                          />
                        </FileBox>
                      </template>
                    </Array>
                  </template>
                </Array>
                <Array title="Gallerie" :prop="slotProps.prop.value.gallery">
                  <template slot-scope="slotProps">
                    <div slot="header">{{ slotProps.prop.value.label.value }}</div>
                    <v-layout wrap>
                      <v-flex md6>
                        <InputBox md6 title="Label" :prop="slotProps.prop.value.label"/>
                      </v-flex>
                      <v-flex md6>
                        <SelectBox md6 title="Kategorie" :prop="slotProps.prop.value.mediatype"/>
                      </v-flex>
                      <v-flex md12>
                        <VideoLinkBox
                          v-show="slotProps.prop.value.mediatype.value == 'video'"
                          title="Videolink"
                          :prop="slotProps.prop.value.videoLink.value[language]"
                        />
                        <FileBox
                          v-show="slotProps.prop.value.mediatype.value == 'image'"
                          fileType="image"
                          :prop="slotProps.prop.value.image.value.file"
                        >
                          <InputBox
                            title="Beschreibung"
                            :prop="slotProps.prop.value.image.value.description.value[language]"
                          />
                          <InputBox
                            title="alternative Bildbeschreibung"
                            :prop="slotProps.prop.value.image.value.alt.value[language]"
                          />
                        </FileBox>
                      </v-flex>
                    </v-layout>
                  </template>
                </Array>
              </template>
            </Array>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
    <v-tab-item id="tab-cabins">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Array
              interactive="collapse"
              title="Kabinenkategorien"
              :prop="editorData.value.mainCabinCategories"
            >
              <template slot-scope="slotProps">
                <div slot="header">{{ slotProps.prop.value.name.value[language].value }}</div>
                <v-layout wrap>
                  <v-flex xs12 sm12 md4>
                    <SelectBox title="Kategorie" :prop="slotProps.prop.value.id"/>
                    <InputBox
                      title="Kabinenname"
                      :prop="slotProps.prop.value.name.value[language]"
                    />
                    <TextBox
                      title="Kabinenbeschreibung"
                      :prop="slotProps.prop.value.descriptionMarkdown.value[language]"
                    />
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <ImageLinkBox :prop="slotProps.prop.value.image"/>
                  </v-flex>
                  <v-flex xs12 sm12 md4>
                    <Array
                      title="Eigenschaften"
                      layout="small"
                      :prop="slotProps.prop.value.description.value.lines"
                    >
                      <template slot-scope="slotProps">
                        <div slot="header">{{ slotProps.prop.value[language].value }}</div>
                        <InputBox
                          :isSmall="true"
                          :noTitle="true"
                          :prop="slotProps.prop.value[language]"
                        />
                      </template>
                    </Array>
                  </v-flex>
                </v-layout>
                <Array
                  interactive="collapse"
                  title="Unterkategorien"
                  :prop="slotProps.prop.value.cabinCategories"
                >
                  <template slot-scope="slotProps">
                    <div slot="header">{{ slotProps.prop.value.name.value[language].value }}</div>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md4>
                        <SelectBox title="Unterkategorie" :prop="slotProps.prop.value.id"/>
                        <InputBox
                          title="Name für Unterkategorie"
                          :prop="slotProps.prop.value.name.value[language]"
                        />
                        <InputBox
                          title="zusätzliches Label"
                          :prop="slotProps.prop.value.head.value[language]"
                        />
                      </v-flex>
                      <v-flex xs12 sm12 md8>
                        <TextBox
                          title="Beschreibung"
                          :prop="slotProps.prop.value.description.value[language]"
                        />
                      </v-flex>
                    </v-layout>
                  </template>
                </Array>
              </template>
            </Array>
          </v-card-text>
          <Array
              interactive="collapse"
              title="Unterkategorien"
              :prop="editorData.value.cabinCategories"
            >
              <template slot-scope="slotProps">
              <AutoForm wrapClass="xs12" :language="language" :data="slotProps.prop"></AutoForm>
              </template>
            </Array>
        </v-container>
      </v-card>
    </v-tab-item>

    <v-tab-item id="tab-decks">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <Wrapper :wrapWithCard="true" title="Bilder Deckübersicht">
              <v-layout wrap>
                <v-flex xs12 sm4 md2>
                  <FileBox
                    title="blau vorne"
                    fileType="image"
                    :prop="editorData.value.shipSectionImages.value.frontBlue.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.shipSectionImages.value.frontBlue.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm4 md2>
                  <FileBox
                    title="grau vorne"
                    fileType="image"
                    :prop="editorData.value.shipSectionImages.value.frontGrey.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.shipSectionImages.value.frontGrey.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm4 md2>
                  <FileBox
                    title="blau mitte"
                    fileType="image"
                    :prop="editorData.value.shipSectionImages.value.centerBlue.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.shipSectionImages.value.centerBlue.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm4 md2>
                  <FileBox
                    title="grau mitte"
                    fileType="image"
                    :prop="editorData.value.shipSectionImages.value.centerGrey.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.shipSectionImages.value.centerGrey.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm4 md2>
                  <FileBox
                    title="blau hinten"
                    fileType="image"
                    :prop="editorData.value.shipSectionImages.value.backBlue.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.shipSectionImages.value.backBlue.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
                <v-flex xs12 sm4 md2>
                  <FileBox
                    title="grau hinten"
                    fileType="image"
                    :prop="editorData.value.shipSectionImages.value.backGrey.value.file"
                  >
                    <InputBox
                      title="alternative Bildbeschreibung"
                      :prop="editorData.value.shipSectionImages.value.backGrey.value.alt.value[language]"
                    />
                  </FileBox>
                </v-flex>
              </v-layout>
            </Wrapper>
            <Array itemClass="col-md-2" title="Decks" :prop="editorData.value.decks">
              <template slot-scope="slotProps">
                <div slot="header">Deck {{ slotProps.prop.value.deck.value }}</div>
                <FileBox fileType="image" :prop="slotProps.prop.value.deckImage.value.file">
                  <InputBox format="number" :prop="slotProps.prop.value.deck"/>
                  <InputBox
                    title="alternative Bildbeschreibung"
                    :prop="slotProps.prop.value.deckImage.value.alt.value[language]"
                  />
                </FileBox>
              </template>
            </Array>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
    <v-tab-item id="tab-downloads">
      <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
          <v-card-text>
            <v-layout wrap>
              <v-flex md6>
                <FileBox
                  :hq="true"
                  title="Deckspläne"
                  fileType="pdf"
                  :prop="editorData.value.files.value.deckplan.value.file"
                >
                  <InputBox
                    title="alternative Bildbeschreibung"
                    :prop="editorData.value.files.value.deckplan.value.text.value[language]"
                  />
                </FileBox>
              </v-flex>
              <v-flex md6>
                <FileBox
                  :hq="true"
                  title="Broschüre"
                  fileType="pdf"
                  :prop="editorData.value.files.value.prospect.value.file"
                >
                  <InputBox
                    title="alternative Bildbeschreibung"
                    :prop="editorData.value.files.value.prospect.value.text.value[language]"
                  />
                </FileBox>
              </v-flex>
            </v-layout>
            <button style="display: none;" @click="migrate">Migrate</button>
          </v-card-text>
        </v-container>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>
<script>
import Default from './Default.vue'
import { cloneDeep, forEach } from 'lodash-es'

import {
  updateItemFromSchema,
  connectItemAndSchema,
} from '../../helper/schema'

export default {
  extends: Default,
  methods: {
    migrate() {
      const editorData = this.getEditorData()
      forEach(editorData.cabinCategories, cabin => {
        forEach(editorData.mainCabinCategories, mainCabin => {
          if (mainCabin.id == cabin.main) {
            mainCabin.cabinCategories.push(cloneDeep(cabin))
          }
        })
      })

      const categories = [
        'cabins',
        'christening',
        'enviroment',
        'entertainment',
        'family',
        'food',
        'wellness',
      ]
      forEach(categories, category => {
        const data = cloneDeep(editorData[category])
        data.category = category
        editorData.articles.push(data)
      })
      this.editorData = connectItemAndSchema(
        updateItemFromSchema(editorData, this.resourceSchema),
        this.resourceSchema
      )
    },
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>

.v-tab {
  letter-spacing: 0em;
  padding: 0 12px;
  min-width: 40px;
}

.tab-wrapper {
    :deep(.tabs__bar) {
      position: fixed;
      top: 52px;
      z-index: 1000000;
      width: 100%;
      margin-left: -16px;
    }
}
@media (min-width: 1921px) {
  .tab-wrapper {
      :deep(.tabs__bar) {
        position: fixed;
        top: 120px;
        z-index: 1000000;
        width: calc(100% - 730px);
      }
    }
}
</style>
