import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import crud from './crud'
import auth from './auth'
import search from './search'
import settings from './settings'
import messages from './messages'

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  // getters: {},
  // actions: {},
  // mutations: {},
  modules: {
    crud,
    auth,
    search,
    settings,
    messages,
  },
})
