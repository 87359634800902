<template>
  <v-combobox item-value="value" item-text="text" small-chips hide-selected multiple :items="selectList" v-model="valueBuffer" :label="internalTitle" :error-messages="errors"></v-combobox>
</template>
<script>
import { compact, get, find, map, cloneDeep, isObject } from 'lodash-es'
import { getItemName } from '../../../helper/value'
import Base from './Base.vue'
export default {
  components: {},
  extends: Base,
  data() {
    return {
      selectList: [],
      valueBuffer: null,
    }
  },
  props: {
    customList: Array,
  },
  methods: {
    async refresh() {
      await this.setSelectedList()
      this.valueBuffer = this.value.length
        ? compact(
            map(this.value, value => {
              const id = value.value.id.value
              const item = find(
                this.selectList,
                listItem => listItem.value == id
              )
              const text = item ? item.text : id
              return { value: id, text: text }
            })
          )
        : []
    },
    async setSelectedList() {
      if (get(this.prop, 'schema.enum')) {
        this.selectList = map(get(this.prop, 'schema.enum'), (value, index) => {
          return {
            value: value,
            text: this.titles[index] || value,
          }
        })
      } else if (get(this.prop, 'schema.options.relation')) {
        const data = await this.$store.dispatch('crud/importResourceData', {
          type: get(this.prop, 'schema.options.relation'),
          returnData: true,
        })
        if (data) {
          this.selectList = data.map(item => {
            return {
              value: item.id,
              text: getItemName(item),
            }
          })
        }
      } else if (this.customList) {
        const list = await this.customList
        this.selectList = list.map(item => {
          return {
            value: item.value,
            text: item.text,
          }
        })
      }
    },
  },
  mounted() {
    this.refresh()
  },
  watch: {
    customList: {
      handler: function(val, oldVal) {
        this.refresh()
      },
      deep: true,
    },
    valueBuffer(values) {
      this.prop.update(
        compact(
          map(values, value => {
            return isObject(value) ? { id: value.value } : { id: value }
          })
        )
      )
    },
  },
}
</script>

