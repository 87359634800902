<template>
    <v-card flat>
        <v-container grid-list-lg class="customform editor-container">
            <v-card-text>
                <v-layout wrap>
                    <v-flex md4>
                        <InputBox :readonly="!isCreatingMode" :prop="editorData.value.id" />
                    </v-flex>
                    <v-spacer></v-spacer>
                    <!-- <v-flex md1 class="islandCheckbox">
                    </v-flex> -->
                        <CheckBox :prop="editorData.value.isIsland" />
                    <v-flex md3>
                        <InputBox :title="editorData.value.name.schema.title" :prop="editorData.value.name.value[language]" />
                    </v-flex>
                    <v-flex md4>
                        <InputBox :title="editorData.value.cityName.schema.title" :prop="editorData.value.cityName.value[language]" />
                    </v-flex>
                    <v-flex md6>
                        <InputBox :title="editorData.value.teaserListHeader.schema.title" :prop="editorData.value.teaserListHeader.value[language]" />
                    </v-flex>
                    <v-flex md6>
                        <InputBox :readonly="false" :prop="editorData.value.slug" />
                    </v-flex>
                    <v-flex md6>
                        <FileBox :hq="true" title="Teaser Bild" fileType="image" :prop="editorData.value.teaserImage.value.file">
                            <InputBox title="alternative Bildbeschreibung" id="port_teaser_alt" autocomplete="off" :prop="editorData.value.teaserImage.value.alt.value[language]" />
                        </FileBox>
                    </v-flex>
                    <v-flex md6>
                        <VideoLinkBox title="Videolink" :prop="editorData.value.videoLink.value[language]" />
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex md12>
                        <TextAreaBox title="Kartenname (Mjoo)" :prop="editorData.value.mapName" />
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex md6>
                        <TextBox :title="editorData.value.teaserShort.schema.title" :prop="editorData.value.teaserShort.value[language]" />
                    </v-flex>
                    <v-flex md6>
                        <TextBox :title="editorData.value.teaserMiddle.schema.title" :prop="editorData.value.teaserMiddle.value[language]" />
                    </v-flex>
                    <v-flex md12>
                        <TextBox :title="editorData.value.teaserEmotional.schema.title" :prop="editorData.value.teaserEmotional.value[language]" />
                    </v-flex>

                    <v-flex md6>
                        <TextAreaBox :title="editorData.value.climateDescription.schema.title" :prop="editorData.value.climateDescription.value[language]" />
                    </v-flex>
                    <v-flex md6>
                        <TextAreaBox :title="editorData.value.politicsDescription.schema.title" :prop="editorData.value.politicsDescription.value[language]" />
                    </v-flex>
                    <v-flex md6>
                        <TextAreaBox :title="editorData.value.sizeDescription.schema.title" :prop="editorData.value.sizeDescription.value[language]" />
                    </v-flex>
                    <v-flex md6>
                        <TextAreaBox :title="editorData.value.airportDescription.schema.title" :prop="editorData.value.airportDescription.value[language]" />
                    </v-flex>
                    <v-flex md6>
                        <InputBox :prop="editorData.value.timeZone" />
                    </v-flex>
                    <v-flex md6>
                        <SelectBox :prop="editorData.value.countryID" />
                    </v-flex>
                    <v-flex md6>
                        <InputBox :prop="editorData.value.shoreexCode" />
                    </v-flex>
                    <v-flex md6>
                        <InputBox :prop="editorData.value.weatherStation" />
                    </v-flex>
                </v-layout>
                <Wrapper :wrapWithCard="true" title="Koordinaten">
                    <v-layout wrap>
                        <v-flex md6>
                            <InputBox :prop="editorData.value.coordinates.value.latitude" />
                        </v-flex>
                        <v-flex md6>
                            <InputBox :prop="editorData.value.coordinates.value.longitude" />
                        </v-flex>
                    </v-layout>
                </Wrapper>
                <Wrapper :wrapWithCard="true" title="Teaserinformationen">
                    <v-layout wrap>
                        <v-flex md6>
                            <InputBox :prop="editorData.value.marketing.value.headline" />
                        </v-flex>
                        <v-flex md6>
                            <InputBox :prop="editorData.value.marketing.value.headlineShort" />
                        </v-flex>
                        <v-flex md6>
                            <TextAreaBox :prop="editorData.value.marketing.value.teaser" />
                        </v-flex>
                        <v-flex md6>
                            <TextAreaBox :prop="editorData.value.marketing.value.descriptionShort" />
                        </v-flex>
                        <v-flex md12>
                            <TextAreaBox :prop="editorData.value.marketing.value.description" />
                        </v-flex>
                    </v-layout>
                </Wrapper>
                <Wrapper :wrapWithCard="true" title="Highlights">
                    <v-layout wrap>
                        <v-flex md12>
                            <InputBox :prop="editorData.value.cityHighlights.value.headline.value[language]" />
                        </v-flex>
                        <v-flex md12>
                            <Array title :prop="editorData.value.cityHighlights.value.highlights">
                                <template slot-scope="slotProps">
                                    <div slot="header">{{ slotProps.prop.value.headline.value[language].value }}</div>
                                    <v-layout wrap>
                                        <v-flex md12>
                                        <InputBox
                                            :title="slotProps.prop.value.headline.schema.title"
                                            :prop="slotProps.prop.value.headline.value[language]"
                                        />
                                        </v-flex>
                                        <v-flex md12>
                                        <TextBox
                                            :title="slotProps.prop.value.text.schema.title"
                                            :prop="slotProps.prop.value.text.value[language]"
                                        />
                                        </v-flex>
                                        <v-flex md12>
                                        <FileBox
                                            :title="slotProps.prop.value.image.schema.title"
                                            :prop="slotProps.prop.value.image.value.file"
                                        />
                                        </v-flex>
                                        <v-flex md12>
                                        <InputBox
                                            :title="slotProps.prop.value.image.value.alt.schema.title"
                                            :prop="slotProps.prop.value.image.value.alt.value[language]"
                                        />
                                        </v-flex>
                                    </v-layout>
                                </template>
                            </Array>
                        </v-flex>
                    </v-layout>
                </Wrapper>
            </v-card-text>
        </v-container>
    </v-card>
</template>
<script>
import Default from './Default.vue'
import { includes, merge, cloneDeep, mapValues, forEach, get } from 'lodash-es'

import {
  updateItemFromSchema,
  connectItemAndSchema,
  disconnectItemAndSchema,
} from '../../helper/schema'

export default {
  extends: Default,
  methods: {},
  mounted() {},
}
</script>
<style lang="scss" scoped>
</style>