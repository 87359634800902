<template>
  <v-dialog v-model="value" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable content-class="settings-dialog">
    <v-card tile>
      <v-app-bar flat dark color="primary">
        <v-app-bar-title>Einstellungen</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="updateShow">
          <v-icon>close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-list three-line subheader>
          <v-subheader>Allgemein</v-subheader>
          <v-list-item @click="languageDialog = true">
            <v-list-item-content>
              <v-list-item-title>Sprache</v-list-item-title>
              <v-list-item-subtitle>Ändern der Standardsprache in der Assets bearbeitet werden</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openPasswordDialog">
            <v-list-item-content>
              <v-list-item-title>Password</v-list-item-title>
              <v-list-item-subtitle>Ändern des Login Passworts</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-btn color="error" @click="logout">Logout</v-btn>
      </v-card-text>
      <div style="flex: 1 1 auto;"></div>
    </v-card>
    <v-dialog v-model="passwordDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Passwort ändern</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field placeholder="altes Passwort" name="oldPassword" type="password" v-model="oldPassword" required min="8" pattern="[\S]+"></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field placeholder="neues Passwort" name="newPassword" type="password" v-model="newPassword" required min="8" pattern="[\S]+"></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field placeholder="Passwort wiederholen" name="newPasswordRepeat" type="password" v-model="newPasswordRepeat" required min="8" pattern="[\S]+"></v-text-field>
              </v-flex>
              <v-alert v-if="errorMessage" :value="true" type="error">
                {{errorMessage}}
              </v-alert>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.native="passwordDialog = false">Abbrechen</v-btn>
          <v-btn color="primary" type="submit" @click.native="submitNewPassword()">Ändern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="languageDialog" max-width="350px">
      <v-card>
        <v-card-title>
          <span class="headline">Sprache ändern</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-radio-group v-model="formLanguage">
                <v-radio value="de" label="Deutsch"></v-radio>
                <v-radio value="en" label="Englisch"></v-radio>
                <v-radio value="nl" label="Niederländisch"></v-radio>
                <v-radio value="it" label="Italienisch"></v-radio>
              </v-radio-group>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.native="languageDialog = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>
<script>
export default {
  props: ['value'],
  computed:{
    formLanguageStore(){
      return this.$store.getters['settings/formLanguage']
    }
  },
  data() {
    return {
      formLanguage: '',
      showChangePassword: false,
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      errorMessage: '',
      successMessage: '',
      passwordDialog: false,
      languageDialog: false,
    }
  },
  methods: {
    openPasswordDialog() {
      this.oldPassword = ''
      this.newPassword = ''
      this.newPasswordRepeat = ''
      this.passwordDialog = true
    },
    updateShow() {
      this.$emit('input', false)
    },
    changeFormLanguage(val) {
      this.$store.dispatch('settings/saveFormLanguage', val)
    },
    async submitNewPassword() {
      this.errorMessage = null
      if (this.newPassword === this.newPasswordRepeat) {
        const result = await this.$store.dispatch('auth/changePassword', {
          newPassword: this.newPassword,
          oldPassword: this.oldPassword,
        })

        if (result === 'SUCCESS') {
          this.passwordDialog = false
        } else {
          this.errorMessage = result
        }
      } else {
        this.errorMessage = 'Passwörter sind nicht identisch'
      }
    },
    logout() {
      this.$store.dispatch('auth/logout')
    },
  },
  watch: {
    formLanguage(newValue, oldValue) {
      if (!newValue) return
      this.changeFormLanguage(newValue)
    },
    formLanguageStore(value){
      this.formLanguage = value
    }
  },
}
</script>

<style lang="scss" scoped>

.v-sheet.theme--dark.v-toolbar.v-toolbar--flat.v-app-bar.primary {
  flex-grow: 0;
}

.v-btn {
  margin: 6px 8px;
}

@media (min-width: 2561px) {
  .settings-dialog {
    right: 0;
    left: auto;
    top: 64px;
    width: calc(100% - 730px);
    box-shadow: 0px 3px 3px #aaa;
    flex: 0 1 auto;
  }
}
</style>