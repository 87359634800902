// authentification actions from the aws cognito sdk
import { amplifyErrors } from '../helper/translations'
import { has, isString, get } from 'lodash-es'
import {
  signIn,
  signOut,
  confirmSignIn,
  fetchAuthSession,
  updatePassword
} from 'aws-amplify/auth';

const state = {
  user: {
    name: '',
    nextStep: '',
    loginSession: '',
    idToken: null,
    isLoggedIn: false,
    role: '',
  },
}

const getters = {
  user(state) {
    return state.user
  },
  username(state) {
    return state.user.name
  },
  role(state) {
    return state.user.role
  },
  formType(state) {
    let type = 'login'

    if (state.user.nextStep.signInStep == 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED')
      type = 'reset'

    return type
  },
  userIsLoggedIn(state) {
    return state.user.isLoggedIn
  },
  idToken(state) {
    return state.user.idToken
  },
  loginSession(state) {
    return state.loginSession
  },
}

const actions = {
  async login(store, { username, password }) {
    let result
    try {
      result = await signIn({username, password})
    } catch (error) {
      if (isString(error)) {
        return get(amplifyErrors, error, { field: 'general', message: error })
      } else {
        return get(amplifyErrors[error.name], error.message, {
          field: 'general',
          message: error.message,
        })
      }
    }

    if (result.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
      store.commit('SET_USER_NAME', username)
      store.commit('SET_NEXT_STEP', result.nextStep)
    }

    return await store.dispatch('refresh')
  },
  async refresh(store) {
    const session = await fetchAuthSession()
    if (has(session.tokens, 'idToken')) {
      updateUserStore(store, session)
      return true
    }
    store.commit('SET_LOGIN_STATUS', false)
    return false
  },
  async reset(store, { password }) {
    try {
      await confirmSignIn({
        challengeResponse: password,
      })
    } catch (error) {
      return amplifyErrors[error.name][error.message].message
    }

    return true
  },
  async changePassword(store, { newPassword, oldPassword }) {
    let result
    try {
      await updatePassword({oldPassword, newPassword})
      result = 'SUCCESS'
    } catch (error) {
      result = amplifyErrors[error.name][error.message].message
    }
    if (result === 'SUCCESS') {
      store.dispatch('messages/setSuccess', `Passwort erfolgreich geändert`, {
        root: true,
      })
    }

    return result
  },
  async checkPermissions() {
    return getters['role']
  },
  async logout(store) {
    store.commit('SET_LOGIN_STATUS', false)
    await signOut()
    window.location.href = '/login'
  },
}

const mutations = {
  SET_LOGIN_STATUS(state, data) {
    state.user.isLoggedIn = data
  },
  SET_USER_NAME(state, data) {
    state.user.name = data
  },
  SET_NEXT_STEP(state, data) {
    state.user.nextStep = data
  },
  SET_ROLE(state, data) {
    state.user.role = data
  },
  SET_ID_TOKEN(state, data) {
    state.user.idToken = data
  },
}

function updateUserStore(store, session) {
  store.commit('SET_USER_NAME', session.tokens.idToken.payload.email)
  store.commit('SET_ID_TOKEN', session.tokens.idToken)
  store.commit('SET_NEXT_STEP', '')
  store.commit('SET_LOGIN_STATUS', true)
  store.commit(
    'SET_ROLE',
    get(session.tokens, 'idToken.payload.cognito:groups.0', 'no-role')
  )
}

export default { state, getters, actions, mutations, namespaced: true }
