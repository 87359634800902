const state = {
  message: null,
  type: null,
  show: false,
  preloader: false,
}

const getters = {
  message(state) {
    return state.message
  },
  type(state) {
    return state.type
  },
  show(state) {
    return state.show
  },
  preloader(state) {
    return state.preloader
  },
}

const actions = {
  setMessage(context, message) {
    context.commit('SET_SHOW', true)
    context.commit('SET_TYPE', 'primary')
    context.commit('SET_MESSAGE', message)
  },
  setSuccess(context, message) {
    context.commit('SET_SHOW', true)
    context.commit('SET_TYPE', 'success')
    context.commit('SET_MESSAGE', message)
  },
  setWarning(context, message) {
    context.commit('SET_SHOW', true)
    context.commit('SET_TYPE', 'warning')
    context.commit('SET_MESSAGE', message)
  },
  setError(context, message) {
    context.commit('SET_SHOW', true)
    context.commit('SET_TYPE', 'error')
    context.commit('SET_MESSAGE', message)
  },
  setShow(context, show) {
    context.commit('SET_SHOW', show)
  },
  setPreloader(context, preloader) {
    context.commit('SET_PRELOADER', preloader)
  },
}

const mutations = {
  SET_MESSAGE(state, message) {
    state.message = message
  },
  SET_TYPE(state, type) {
    state.type = type
  },
  SET_SHOW(state, show) {
    state.show = show
  },
  SET_PRELOADER(state, preloader) {
    state.preloader = preloader
  },
}

export default { state, getters, actions, mutations, namespaced: true }
