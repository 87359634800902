<template>
  <div class="filebox input-group input-group--dirty input-group--text-field primary--text">
    <label>{{ internalTitle }} <span class="uploadFlag" v-if="file && !isDeleted">{{fileName}}</span></label>
    <div :class="{
      'preview image': isImage, 
      'preview' : !isImage,
      'hasFile': file || preview
    }" @click="openDialog()">
      <img v-if="file && isImage && !preview && !isDeleted" class="preview-image" :src="loader ? '/loading.gif' : file" />
      <img v-if="preview && isImage && !isDeleted" class="preview-image" :src="preview" />
      <v-icon class="edit">{{ isImage ? 'image' : 'attach_file' }}</v-icon>
    </div>
    <v-dialog v-if="!loader" content-class="dialog" v-model="dialogOpen" eager max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ internalTitle }}</span>
          <span class="uploadFlag" v-if="file && !isDeleted">{{fileName}}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-show="prop.hasChildren">
            <slot></slot>
          </div>
          <input v-show="false" type="file" ref="formField" />
          <div class="image-wrapper">
            <img v-if="file && isImage && !preview && !isDeleted" class="preview-image" :src="file" />
            <img v-if="preview && isImage && !isDeleted" class="preview-image" :src="preview" />
          </div>
          <a class="download" v-if="file && !isImage" :href="file" target="_blank">
            <v-icon>cloud_download</v-icon><br>Download</a>
          <div :class="{'drag-stage': true, hover}" ref="dropField" @click="openFileDialog()">
            <img src="/icons/FileIcon.svg" />
            <div class="drag-headline">Dateien hereinziehen</div>
            <div class="browse-headline">oder
              <span>suche in deinen Dateien</span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="closeDialog">
            <v-icon>check</v-icon>
          </v-btn>
          <v-btn v-if="preview || isDeleted" icon @click.native="reset">
            <v-icon>undo</v-icon>
          </v-btn>
          <v-btn v-if="!isDeleted && (preview || file)" icon @click.native="remove">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import root from 'window-or-global'
import { isString, replace } from 'lodash-es'
import Base from './Base.vue'
import { addCloudFrontDomain } from '../../../helper/http'

export default {
  components: {},
  extends: Base,
  props: {
    fileType: {
      type: String,
      default: null,
    },
    hq: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogOpen: false,
      hover: false,
      loader: false,
    }
  },
  computed: {
    type() {
      return this.fileType !== null
        ? this.fileType
        : this.prop.type ? this.prop.type : 'octet-stream'
    },
    preview() {
      return this.prop.preview
    },
    file() {
      return isString(this.value) && this.value ? this.addUrl(this.value) : null
    },
    fileName() {
      if (!this.file) 
        return ""
      else {
        var filePath = this.file.split('/')
        var fileName = filePath.pop()
        return fileName
      }
    },
    isImage() {
      return this.type == 'image'
    },
    isDeleted() {
      return isString(this.value) && this.value.startsWith('DELETED;')
    },
  },
  methods: {
    addUrl() {
      let quality = this.hq ? '-800px.jpg' : '-320px.jpg'
      let thumbnail = replace(this.value, '-original.png', quality)
      thumbnail = replace(thumbnail, '-original.jpg', quality)
      thumbnail = replace(thumbnail, '-original.jpeg', quality)
      return addCloudFrontDomain(thumbnail)
    },
    openFileDialog() {
      this.input.click()
    },
    openDialog() {
      this.dialogOpen = true
    },
    closeDialog() {
      this.dialogOpen = false
    },
    refreshPreview(preview) {
      const mimeType = preview.split(';')[0]
      if (mimeType) {
        this.prop.preview = preview
      } else {
        this.reset()
      }
    },
    reset() {
      this.update(this.prop.origin)
      this.prop.preview = null
      this.input.value = ''
    },
    refresh() {
      this.loader = true
      setTimeout(() => {
        this.loader = false
      }, 10000)
    },
    remove() {
      this.update(this.prop.origin ? 'DELETED;' + this.prop.origin : null)
      this.prop.preview = null
    },
    changeEvent(e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.input.files && this.input.files.length)
        this.changeFile(this.input.files[0])
    },
    changeFile(file) {
      var fr = new FileReader()
      fr.onload = evt => {
        this.refreshPreview(evt.target.result)
        fr = null
      }
      fr.readAsDataURL(file)
      this.update(file)
    },
    dropEvent(e) {
      e.preventDefault()
      this.hover = false
      if (e.dataTransfer.files && e.dataTransfer.files.length) {
        this.changeFile(e.dataTransfer.files[0])
      }
    },
    dragOver(e) {
      e.preventDefault()
      this.hover = true
      return false
    },
    dragEnd(e) {
      e.preventDefault()
      this.hover = false
      return false
    },
  },
  mounted() {
    this.$refs.dropField.addEventListener('dragover', this.dragOver)
    this.$refs.dropField.addEventListener('drop', this.dropEvent)
    root.addEventListener('dragend', this.dragEnd)

    this.input.addEventListener('change', this.changeEvent)
    this.prop.hasChildren = this.$children.length > 0
  },
  created() {
    this.prop.origin = this.value
  },
  beforeDestroy() {
    this.$refs.dropField.removeEventListener('dragover', this.dragOver)
    this.$refs.dropField.removeEventListener('drop', this.dropEvent)
    root.removeEventListener('dragend', this.dragEnd)
    this.input.removeEventListener('change', this.changeEvent)
  },
}
</script>
<style scoped lang="scss">
.filebox {
  padding-bottom: 26px;
}

.download {
    :deep(i) {
      font-size: 100px;
    }
  color: inherit;
  font-weight: bold;
  text-decoration: none;
  display: block;
  text-align: center;
}

.uploadFlag {
  color: #FFF;
  background-color: #2ecc71;
  padding: 2px;
  border-radius: 3px;
  margin: 5px;
}

.preview {
  width: 100%;
  height: 0;
  padding-bottom: 25%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  .edit {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20%;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    font-size: 80px;
  }
}

.preview.image {
  width: 100%;
  height: 0;
  padding-bottom: 200px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  border: 1px dashed rgba(0, 0, 0, 0.3);

  .preview-image {
    width: auto;
    max-width: 100%;
    max-height: 200px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .edit {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    font-size: 50px;
    transition: all 0.5s;
  }
  &.hasFile {
    .edit {
      position: absolute;
      right: 5px;
      top: 5px;
      transform: none;
      left: auto;
      width: 30px;
      height: auto;
      font-size: 30px;
    }
  }
}

.dialog {
  .preview-image {
    position: static;
    width: 100%;
    height: auto;
  }

  .v-card__title {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
  }
}

.drag-stage {
  text-align: center;
  padding: 30px 0;
  cursor: pointer;
  width: 80%;
  margin: 0 auto;
  margin-top: 15px;

  &.hover {
    background: rgba(0, 0, 0, 0.1);
  }

  .drag-headline {
    font-size: 19px;
    color: #b2b2b2;
    letter-spacing: 0;
    text-align: left;
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .browse-headline {
    font-size: 12px;
    color: #b2b2b2;
    letter-spacing: 0;
    text-align: left;
    display: inline-block;

    span {
      color: #3399cc;
      text-decoration: underline;
    }
  }
}
</style>

/* date picker: */
