import {
  cloneDeep,
  forEach,
  concat,
  map,
  groupBy,
  sortBy,
  isObject,
} from 'lodash-es'
import {
  get,
  post,
  put,
  del,
  postFile,
  deleteFile,
  getVisibleProps,
} from '../helper/ajax'
import {
  derefSchema,
  schemaAssetReadingConfigs,
} from '../helper/schema'
import { userIsAllowed } from '../helper/user'
import { getVisibleApiProps } from '../helper/api'
import { sections } from '../config/menu'
import {
  setItemUploadPaths,
  rawFileFieldValue,
  gatherItemFilePathInformation,
} from '../helper/uploads'

const activeCaching = true

const state = {
  schemas: [],
  referencedDefinitions: [],
  dataCache: [],
  currentList: {
    type: null,
    data: [],
  },
  currentItem: {
    type: null,
    data: [],
    draft: null,
  },
}

const getters = {
  resourceInfos(state, getters, rootState) {
    let resources = state.schemas.map(item => {
      if (item.id === "personae") {
        return
      }
      if (userIsAllowed(rootState.auth.user.role, item)) {
        return {
          id: item.id,
          title: item.title,
          section: item.section,
          subSection: item.subSection,
        }
      }
    })
    resources = sortBy(resources, ['title'])
    return resources
  },
  groupedResources(state, getters) {
    const resources = groupBy(getters['resourceInfos'], 'section')

    const filteredSections = {}
    forEach(sections, (title, key) => {
      if (resources[key]) filteredSections[title] = resources[key]
    })

    return filteredSections
  },
  currentResourceList(state) {
    return state.currentList.data
  },
  currentResourceType(state) {
    return state.currentList.type
  },
  referencedDefinitions(state) {
    return state.referencedDefinitions
  },
  resourceSchema(state) {
    const selector = state.currentItem.type
      ? state.currentItem.type
      : state.currentList.type
    if (!selector) return
    const schema = derefSchema(
      cloneDeep(
        state.schemas.find(item => {
          return item.id === selector
        })
      )
    )
    return schema
  },
  resourceItem(state) {
    return state.currentItem
  },
  visibleProperties(state, getters) {
    const properties = []
    let visibleProperties = null
    if (!getters['resourceSchema']) return null
    if ('visibleProperties' in getters['resourceSchema']) {
      visibleProperties = getters['resourceSchema'].visibleProperties
    }

    for (var item in getters['resourceSchema'].properties) {
      if (visibleProperties) {
        if (visibleProperties.indexOf(item) !== -1) {
          properties.push(item)
        }
      } else {
        properties.push(item)
      }
    }

    return properties
  },
  cachedItems(state) {
    return state.dataCache
  },
  schemas(state) {
    return state.schemas
  },
}

const actions = {
  async importSchemas(store) {
    const data = await get('schemas')
    store.commit('IMPORT_SCHEMAS', data)
  },
  async importResourceData(store, type) {
    let returnData = false
    if (isObject(type)) {
      returnData = type.returnData
      type = type.type
    }

    let data = getCachedData(type, store)

    if (!data) {
      data = await getVisibleApiProps(type)
      store.commit('ADD_TO_CACHE', { data, type })
    }

    if (!data) {
      data = await getVisibleProps(type)
      store.commit('ADD_TO_CACHE', { data, type })
    }

    if (returnData) return data
    store.commit('IMPORT_RESOURCE_DATA', { data, type })
  },
  async importItem(store, type) {
    const uri = `${type.resource}/${type.id}`
    const data = await get(uri)
    const draft = await get(`drafts/${type.draftId || uri}`)
    store.commit('IMPORT_CURRENT_ITEM', { data, type: type.resource, draft })
  },
  async removeCurrentItem(store) {
    store.commit('REMOVE_CURRENT_ITEM')
  },
  async removeCurrentList(store) {
    store.commit('REMOVE_CURRENT_LIST')
  },
  async refreshList(store, { type }) {
    store.commit('REMOVE_FROM_CACHE', type)
    const data = await getVisibleProps(type)
    store.commit('ADD_TO_CACHE', { data, type })
    store.commit('IMPORT_RESOURCE_DATA', { data, type })
    return true
  },
  async saveItem(store, { item, type, isNew, isDraft }) {
    try {
      // `clean way`
      const currentSchema = store.getters.resourceSchema

      const itemUploadPaths = setItemUploadPaths(item, currentSchema)

      let data = null

      const itemExist = state.currentList.data.find(existingItem => {
        return item.id === existingItem.id
      })

      // save document
      if (isNew) {
        if (!itemExist) {
          data = await post(type, item, false, isDraft)
        } else {
          return { success: false, error: 'ID wird bereits benutzt' }
        }
      } else {
        if (!itemExist || state.currentItem.data.id === item.id) {
          data = await put(`${type}/` + item.id, item, isDraft)
        } else {
          return { success: false, error: 'ID wird bereits benutzt' }
        }
      }

      if (data.error) {
        return { success: false, error: data.error }
      }
      // upload
      // for each triple, decode base64Data, upload to s3
      let uploads = []
      if (data) {
        forEach(
          schemaAssetReadingConfigs,
          ({ definitionKey, bucketSigningIdentifier }) => {
            uploads = concat(
              uploads,
              map(itemUploadPaths[definitionKey], async info => {
                if (info.file === 'DELETE') {
                  return
                  // return await deleteFile(
                  //   bucketSigningIdentifier,
                  //   info.url.replace('DELETED;', ''),
                  //   true
                  // )
                }

                return await postFile(
                  bucketSigningIdentifier,
                  {
                    itemId: item.id,
                    resource: currentSchema.id,
                    sortingStablePath: info.sortingStablePath,
                  },
                  info,
                  true
                )
              })
            )
          }
        )
        await Promise.all(uploads)
        const updatedItem = await get(`${type}/${data.id}`)
        store.commit('UPDATE_RESOURCE_ITEM', { data: updatedItem, type })
        store.commit('IMPORT_CURRENT_ITEM', { data: updatedItem, type })
        store.commit('REMOVE_FROM_CACHE', type)
      }
      return { success: true, data }
    } catch (e) {
      console.log('FAIL!', e)
      return { success: false, error: e }
      throw e
    }
  },
  async deleteItem(store, { id, type }) {
    try {
      const item = await get(`${type}/${id}`)
      const currentSchema = store.getters.resourceSchema
      const itemDeletePaths = {}
      forEach(
        schemaAssetReadingConfigs,
        ({
          definitionKey,
          bucketDomainKey,
          bucketSigningIdentifier,
          assetType,
        }) => {
          const currentItemUploadPaths = gatherItemFilePathInformation(
            item,
            definitionKey,
            currentSchema
          )
          itemDeletePaths[definitionKey] = currentItemUploadPaths
        }
      )
      const data = await del(`${type}/` + id)
      if (!data || data.errorType) {
        alert('Error during delete of Item')
        throw data
      }

      let deletes = []
      deletes = concat(
        deletes,
        map(itemDeletePaths['sketch'], async info => {
          if (!rawFileFieldValue(info.accessPath, item)) {
            return
          }
          return await deleteFile(
            'sketches',
            rawFileFieldValue(info.accessPath, item).replace('DELETED;', ''),
            true
          )
        })
      )
      deletes = concat(
        deletes,
        map(itemDeletePaths['image'], async info => {
          if (!rawFileFieldValue(info.accessPath, item)) {
            return
          }
          return await deleteFile(
            'images',
            rawFileFieldValue(info.accessPath, item).replace('DELETED;', ''),
            true
          )
        })
      )

      await Promise.all(deletes)

      store.commit('DELETE_ITEM', { id, type })
      return data
    } catch (e) {
      console.log('FAIL!', e)
      throw e
    }
  }
}

const mutations = {
  IMPORT_SCHEMAS(state, data) {
    state.schemas = data
    state.referencedDefinitions = data[0].definitions
  },
  IMPORT_RESOURCE_DATA(state, { type, data }) {
    state.currentList.type = type
    state.currentList.data = data
  },
  ADD_TO_CACHE(state, { type, data }) {
    if (activeCaching) {
      state.dataCache.push({ type, data })
    }
  },
  REMOVE_FROM_CACHE(state, type) {
    const index = state.dataCache.findIndex((element, index, array) => {
      return element.type === type
    })
    state.dataCache.splice(index, 1)
  },
  UPDATE_RESOURCE_ITEM(state, { data, type }) {
    const id = state.currentList.data.findIndex((element, index, array) => {
      return element.id === data.id ? true : false
    })
    if (id >= 0) {
      const clone = cloneDeep(state.currentList.data)
      clone[id] = data
      state.currentList.data = clone
    } else {
      state.currentList.data.push(data)
    }
  },
  DELETE_ITEM(state, { type, id }) {
    const index = state.currentList.data.findIndex((element, index, array) => {
      return element.id === id ? true : false
    })
    state.currentList.data.splice(index, 1)
  },
  IMPORT_CURRENT_ITEM(state, { data, type, draft }) {
    state.currentItem.type = type
    state.currentItem.data = data
    state.currentItem.draft = draft
  },
  REMOVE_CURRENT_ITEM(state) {
    state.currentItem.type = null
    state.currentItem.data = null
    state.currentItem.draft = null
  },
  REMOVE_CURRENT_LIST(state) {
    //state.currentList.type = null
    //state.currentList.data = null
  }
}

function getCachedData(type, store) {
  const cachedItems = store.getters.cachedItems
  let data = cachedItems.find(item => {
    return item.type === type
  })

  if (data) {
    return data.data
  }
}

export default { state, getters, actions, mutations, namespaced: true }
