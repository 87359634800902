import { isArray, isNull, isPlainObject, isString, map } from 'lodash-es'
import store from '../stores'
import {
  schemaToItem,
  derefSchema,
} from './schema'

const editorTranslations = {
  en: {
    button_add_row_title: 'Add {{0}}',
    button_collapse: 'Collapse',
    button_delete_all: 'All',
    button_delete_all_title: 'Delete All',
    button_delete_last: 'Last {{0}}',
    button_delete_last_title: 'Delete Last {{0}}',
    button_delete_row_title: 'Delete {{0}}',
    button_delete_row_title_short: 'Delete',
    button_expand: 'Expand',
    button_move_down_title: 'Move down',
    button_move_up_title: 'Move up',
    error_additionalItems: 'No additional items allowed in this array',
    error_additional_properties:
      'No additional properties allowed, but property {{0}} is set',
    error_anyOf:
      'Value must validate against at least one of the provided schemas',
    error_dependency: 'Must have property {{0}}',
    error_disallow: 'Value must not be of type {{0}}',
    error_disallow_union:
      'Value must not be one of the provided disallowed types',
    error_enum: 'Value must be one of the enumerated values',
    error_maxItems: 'Value must have at most {{0}} items',
    error_maxLength: 'Value must be at most {{0}} characters long',
    error_maxProperties: 'Object must have at most {{0}} properties',
    error_maximum_excl: 'Value must be less than {{0}}',
    error_maximum_incl: 'Value must be at most {{0}}',
    error_minItems: 'Value must have at least {{0}} items',
    error_minLength: 'Value must be at least {{0}} characters long',
    error_minProperties: 'Object must have at least {{0}} properties',
    error_minimum_excl: 'Value must be greater than {{0}}',
    error_minimum_incl: 'Value must be at least {{0}}',
    error_multipleOf: 'Value must be a multiple of {{0}}',
    error_not: 'Value must not validate against the provided schema',
    error_notempty: 'Value required',
    error_notset: 'Property must be set',
    error_oneOf:
      'Value must validate against exactly one of the provided schemas. It currently validates against {{0}} of the schemas.',
    error_pattern: 'Value must match the pattern {{0}}',
    error_required: "Object is missing the required property '{{0}}'",
    error_type: 'Value must be of type {{0}}',
    error_type_union: 'Value must be one of the provided types',
    error_uniqueItems: 'Array must have unique items',
  },
  de: {
    button_add_row_title: '{{0}} hinzufügen',
    button_collapse: 'Verbergen',
    button_delete_all: 'All',
    button_delete_all_title: 'Alle Löschen',
    button_delete_last: 'Last {{0}}',
    button_delete_last_title: 'Delete Last {{0}}',
    button_delete_row_title: 'Lösche {{0}}',
    button_delete_row_title_short: 'Löschen',
    button_expand: 'Anzeigen',
    button_move_down_title: '\u2193',
    button_move_up_title: '\u2191',
    error_additionalItems: 'No additional items allowed in this array',
    error_additional_properties:
      'No additional properties allowed, but property {{0}} is set',
    error_anyOf:
      'Value must validate against at least one of the provided schemas',
    error_dependency: 'Must have property {{0}}',
    error_disallow: 'Value must not be of type {{0}}',
    error_disallow_union:
      'Value must not be one of the provided disallowed types',
    error_enum: 'Value must be one of the enumerated values',
    error_maxItems: 'Value must have at most {{0}} items',
    error_maxLength: 'Value must be at most {{0}} characters long',
    error_maxProperties: 'Object must have at most {{0}} properties',
    error_maximum_excl: 'Value must be less than {{0}}',
    error_maximum_incl: 'Value must be at most {{0}}',
    error_minItems: 'Value must have at least {{0}} items',
    error_minLength: 'Value must be at least {{0}} characters long',
    error_minProperties: 'Object must have at least {{0}} properties',
    error_minimum_excl: 'Value must be greater than {{0}}',
    error_minimum_incl: 'Value must be at least {{0}}',
    error_multipleOf: 'Value must be a multiple of {{0}}',
    error_not: 'Value must not validate against the provided schema',
    error_notempty: 'Value required',
    error_notset: 'Property must be set',
    error_oneOf:
      'Value must validate against exactly one of the provided schemas. It currently validates against {{0}} of the schemas.',
    error_pattern: 'Value must match the pattern {{0}}',
    error_required: "Object is missing the required property '{{0}}'",
    error_type: 'Value must be of type {{0}}',
    error_type_union: 'Value must be one of the provided types',
    error_uniqueItems: 'Array must have unique items',
  },
}

const translations = {
  de: {
    'Cabin categories': 'Kabinenkategorien',
    'Cabin category': 'Kabinenkategorie',
    'Cabin subcategories': 'Kabinenunterkategorien',
    'Cabin subcategory': 'Kabinenunterkategorie',
    'Item changed by other user':
      'Speichern nicht möglich. Konflikt mit anderem User',
  },
}

const amplifyErrors = {
  'Username cannot be empty': {
    field: 'username',
    message: 'Bitte tragen Sie Ihre E-Mail Adresse ein',
  },
  'Password cannot be empty': {
    field: 'password',
    message: 'Bitte wählen Sie ein Passwort',
  },
  InvalidParameterException: {
    'Username should be an email.': {
      field: 'username',
      message: 'Bitte tragen Sie eine valide E-Mail Adresse ein',
    },
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": {
      field: 'password',
      message: 'Bitte wählen Sie ein Passwort mit mindestens 8 Zeichen',
    },
    "1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6": {
      field: 'password',
      message: 'Bitte wählen Sie ein Passwort mit mindestens 8 Zeichen',
    },
    'Password did not conform with policy: Password not long enough': {
      field: 'password',
      message: 'Bitte wählen Sie ein Passwort mit mindestens 8 Zeichen',
    },
    'Password did not conform with policy: Password must have lowercase characters': {
      field: 'password',
      message: 'Ihr Passwort muss mindestens 1 Kleinbuchstaben enthalten',
    },
    'Password did not conform with policy: Password must have uppercase characters': {
      field: 'password',
      message: 'Ihr Passwort muss mindestens 1 Großbuchstaben enthalten',
    },
  },
  InvalidPasswordException: {
    'Password does not conform to policy: Password not long enough': {
      field: 'password',
      message: 'Bitte wählen Sie ein Passwort mit mindestens 8 Zeichen',
    },
  },
  UsernameExistsException: {
    'An account with the given email already exists.': {
      field: 'username',
      message: 'Ein Account mit dieser E-Mail Adresse existiert bereits.',
    },
  },
  UserNotConfirmedException: {
    'User is not confirmed.': {
      field: 'username',
      message:
        'Bitte aktivieren Sie zuerst Ihren Account. Klicken Sie dafür den Link in der E-Mail, die wir Ihnen gesendet haben.',
    },
  },
  NotAuthorizedException: {
    'Incorrect username or password.': {
      field: 'general',
      message: 'E-Mail Adresse oder Passwort falsch.',
    },
  },
  UserNotFoundException: {
    'User does not exist.': {
      field: 'username',
      message: 'E-Mail Adresse oder Passwort falsch.',
    },
  },
  UserAlreadyAuthenticatedException: {
    'There is already a signed in user.': {
      field: 'general',
      message: 'Ein Nutzer ist bereits angemeldet.'
    }
  }
}

function getJsonEditorTranslations() {
  return editorTranslations
}

function translate(key) {
  const lang = localStorage.getItem('language') || 'de'
  return translations[lang][key] || key
}

function flattenLanguages(obj, type) {
  const language = store.getters['settings/formLanguage']
  if (isArray(obj)) {
    return map(obj, item => flattenLanguages(item, type))
  }
  if (!isPlainObject(obj)) {
    return obj
  }

  if (type === 'schema' && obj.properties && obj.properties[language]) {
    delete obj.properties[language]['title']
    delete obj.properties[language]['options']['hidden']
    if (!obj.properties[language]['propertyOrder'] && obj['propertyOrder']) {
      obj.properties[language]['propertyOrder'] = obj['propertyOrder']
      obj.properties[language]['title'] = obj['title']
    }

    return obj.properties[language]
  }

  if (type === 'item' && (isString(obj[language]) || isNull(obj[language]))) {
    return obj[language]
  }

  for (const key of Object.keys(obj)) {
    obj[key] = flattenLanguages(obj[key], type)
  }
  return obj
}

function inflateLanguages(flatItem, defaultItem, schema, schemaDefintions) {
  const language = store.getters['settings/formLanguage']

  if (!defaultItem) {
    defaultItem = ''
  }

  if (!flatItem) {
    flatItem = ''
  }

  // processes arrays
  if (isArray(defaultItem)) {
    if (defaultItem.length >= flatItem.length) {
      return map(flatItem, (item, key) =>
        inflateLanguages(
          flatItem[key],
          defaultItem[key],
          schema,
          schemaDefintions
        )
      )
    } else {
      // create new array item from schema
      return map(flatItem, (item, key) => {
        if (!defaultItem[key]) {
          defaultItem[key] = createItemFromSchema(schema, schemaDefintions)
        }
        return inflateLanguages(
          flatItem[key],
          defaultItem[key],
          schema,
          schemaDefintions
        )
      })
    }
  }

  // process simple strings
  if (isString(defaultItem)) {
    return flatItem
  }

  // process multi language strings
  if (isString(defaultItem[language]) || isNull(defaultItem[language])) {
    defaultItem[language] = flatItem
    return defaultItem
  }

  // process new fields (not existing in defaultItem)
  for (const key of Object.keys(flatItem)) {
    if (!defaultItem[key]) {
      defaultItem[key] = flatItem[key]
    }
  }

  for (const key of Object.keys(defaultItem)) {
    if (
      schema &&
      schema.hasOwnProperty('items') &&
      schema.items.hasOwnProperty('properties')
    ) {
      schema = schema.items.properties
    } else if (schema && schema.hasOwnProperty('properties')) {
      schema = schema.properties
    }
    const schemaPart = schema[key] || schema
    defaultItem[key] = inflateLanguages(
      flatItem[key],
      defaultItem[key],
      schemaPart,
      schemaDefintions
    )
  }

  return defaultItem
}

function createItemFromSchema(schema, schemaDefintions) {
  schema = derefSchema(schema.items, schemaDefintions)
  const item = schemaToItem(schema)

  return item
}

export {
  getJsonEditorTranslations,
  translate,
  flattenLanguages,
  inflateLanguages,
  amplifyErrors,
}
