<template>
    
        <v-tab-item :id="'tab-' + header">
          <slot/>
      </v-tab-item>
</template>

<script>
export default {
  props: {
    name: { required: true },
    title: { required: false },
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    header() {
      return this.title || this.name
    },
    hash() {
      return '#' + this.name
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
