<template>
  <v-textarea no-resize :readonly="readonly" :error-messages="errors" :label="internalTitle" :value="value" ref="formField" @input="update"></v-textarea>
</template>
<script>
import Base from './Base.vue'
export default {
  props: {
    format: {
      type: String,
      default: null,
    },
  },
  methods: {},
  extends: Base,
}
</script>
